import * as React from "react";
import { useState, useEffect } from "react";
import Table from "@mui/joy/Table";
import app from "../../common/sendrequest";
import CommonPopupRender from "../../common/popuprender";
import SearchIcon from "@mui/icons-material/Search";
import { Admin } from "../auth/User.type";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Pagination from "@mui/material/Pagination";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import {
  Typography,
  Sheet,
  Button,
  Modal,
  ModalClose,
  ModalDialog,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Divider,
  Box,
  styled,
  Tooltip,
  IconButton,
  Select,
  Option,
  Chip,
  Switch,
  Link,
  ColorPaletteProp,
  CircularProgress,
} from "@mui/joy";
import { useNavigate } from "react-router-dom";
import { Backdrop, ButtonGroup, Button as MButoon } from "@mui/material";
import axios, { AxiosError } from "axios";
import configs from "../../config";
import { Package } from "../packages/Package.type";
import { convertDate, missingFill } from "../../common/expression";
import { DisplayDate } from "../component/DisplayDate";
import { Setting } from "../setting/Setting.type";
import { Customer } from "../customer/Customer.type";
type ServerError = { message: string };

export default function ListFamilyDomain() {
  const sessiondata = JSON.parse(
    sessionStorage.getItem("package_searchname_next") ||
      '{"searchName":"","currentpage":1,"status":"all","publicStatus":"all","packageType":"all","package_id":"all"}'
  );
  const [rows, setRow] = useState<Customer[]>([]);
  const [searchClick, setsearchClick] = useState(false);
  const [packageType, setPackageType] = useState(sessiondata!.packageType);
  const [list_type, setListType] = useState<Setting[]>([]);
  const [popup, setPopUp] = useState<JSX.Element>(<></>);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [package_id, setPackages] = useState<string>(sessiondata!.package_id);
  const [list_package, setListPack] = useState<Package[]>([]);
  const [currentpage, setCurrentPage] = useState(sessiondata!.currentpage);
  const [searchName, setName] = useState<string>(sessiondata!.searchName);
  const [searchNameRef, setNameRef] = useState(sessiondata!.searchName);
  const [status, setStatus] = useState<string>(sessiondata!.status);
  const [publicStatus, setPublicStatus] = useState<string>(
    sessiondata!.publicStatus
  );
  const [page, setPage] = useState(0);
  const [initPopup, setInitPopup] = useState({
    openFlag: false,
    type: "",
    content: <></>,
    onPass: () => {},
    onCancel: null,
    setOpenFlag: (flag: boolean) =>
      setInitPopup({ ...initPopup, openFlag: flag }),
  });

  let navigate = useNavigate();

  const PopupRender = ({ children }: any) => {
    return (
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog
          aria-labelledby="basic-modal-dialog-title"
          aria-describedby="basic-modal-dialog-description"
          sx={{ width: "20vw" }}
          size="md"
        >
          <ModalClose />
          {children}
        </ModalDialog>
      </Modal>
    );
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };
  const handleError = (err: any) => {
    if (axios.isAxiosError(err)) {
      const serverError = err as AxiosError<ServerError>;
      if (serverError && serverError.response) {
        setInitPopup({
          ...initPopup,
          openFlag: true,
          type: "fail",
          content: <>{serverError.response.data.message}</>,
        });
      }
    }
  };
  const [tempSearch, setTempSearch] = useState({
    searchName,
    currentpage,
    status,
    package_id,
  });

  const handleSearch = () => {
    setTempSearch({ searchName, currentpage, status, package_id });
    setCurrentPage(1);
    setsearchClick(!searchClick);
  };

  useEffect(() => {
    getData();
    sessionStorage.setItem(
      "package_searchname",
      JSON.stringify({ searchName, currentpage, status, package_id })
    );
    sessionStorage.removeItem("package_searchname_next");
    sessionStorage.removeItem("pending_searchname_next");
  }, [searchClick, currentpage]);

  useEffect(() => {
    getSetting();
  }, []);

  const getSetting = () => {
    app
      .post(configs.api + "package/list_all")
      .then((response) => setListPack(response.data.original))
      .catch((err) => setListPack([]));
  };
  const update_handler = (domain: any) => {
    try {
      app
        .post(configs.customer + "domain-update", {
          domain,
        })
        .then((data: any) => {
          setLoading(false);
          setInitPopup({
            ...initPopup,
            openFlag: true,
            type: "success",
            content: (
              <>
                Cập nhật trạng thái domain thành công. Vui lòng chờ trong vòng
                10 phút để hoàn tất quá trình tạo tên miền
              </>
            ),
            onPass: () => {
              getData();
            },
          });
        })
        .catch((err: Error) => {
          setLoading(false);
          handleError(err);
        });
    } catch (err) {
      setLoading(false);
      setPage(0);
      setRow([]);
    }
  };
  const handleAddDomain = (row: Customer) => {
    //domain-update
    try {
      setInitPopup({
        ...initPopup,
        type: "confirmed",
        openFlag: true,
        content: (
          <p>
            Bạn có muốn kích hoạt domain{" "}
            <a href={row.domain as any} target="_blank">
              {row.domain}
            </a>{" "}
            cho dòng họ {row.family.family_name}-{row.family_alias}{" "}
          </p>
        ),
        onPass: () => {
          setLoading(true);
          update_handler(row.domain);
        },
      });
    } catch (err) {
      setPage(0);
      setRow([]);
    }
  };

  const getData = () => {
    try {
      setLoading(true);
      app
        .post(configs.customer + "domain-list", {
          searchstring: tempSearch.searchName,
          status: tempSearch.status,
          package_id: tempSearch.package_id,
          rowsPerpage: 10,
          currentPage: currentpage - 1,
        })
        .then((data: any) => {
          setLoading(false);
          setPage(data.data.total);
          setRow(data.data.original);
        })
        .catch((err: Error) => handleError(err));
    } catch (err) {
      setPage(0);
      setRow([]);
    }
  };

  return (
    <Box
      sx={{
        marginBottom: "auto",
        marginLeft: "auto",
        marginRight: { xl: "auto", xs: "auto" },
        width: { xl: "85%", md: "90%", xs: "auto" },
      }}
    >
      <div
        className="coverbox"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "flex-start",
          // margin: "auto",
        }}
      >
        <Typography
          level="h4"
          component="h1"
          sx={{ alignSelf: "center", padding: "2vh" }}
        >
          Danh sách Domain
        </Typography>
        <Box
          className="RootBoxs"
          sx={{
            display: "flex",
            // padding: "12px",
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div className="FindBox" style={{ display: "flex" }}>
            <FormControl sx={{ margin: "3px", width: "100%" }} size="md">
              <FormLabel>
                Từ khóa tìm kiếm
                <Tooltip
                  title="Nhập domain, mã định danh dòng họ, tên dòng họ"
                  placement="right"
                  size="sm"
                >
                  <InfoOutlinedIcon fontSize="small" />
                </Tooltip>
              </FormLabel>
              <Input
                placeholder="Nhập nội dung tìm kiếm"
                defaultValue={searchNameRef}
                sx={{ minWidth: 350 }}
                size="sm"
                onChange={(e) => {
                  setName((e.target as HTMLInputElement).value.toLowerCase());
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setName((e.target as HTMLInputElement).value.toLowerCase());
                    setTempSearch({
                      ...tempSearch,
                      searchName: (
                        e.target as HTMLInputElement
                      ).value.toLowerCase(),
                    });
                    setCurrentPage(1);
                    setsearchClick(!searchClick);
                  }
                }}
              />
            </FormControl>
            <FormControl sx={{ margin: "3px", width: "100%" }} size="md">
              <FormLabel>Gói dịch vụ</FormLabel>
              <Select
                name="package"
                size="sm"
                value={package_id}
                sx={{ minWidth: 200 }}
                onChange={(e: any, newValue) => {
                  setPackages(newValue || package_id);
                }}
              >
                <Option key="0" value="all">
                  Tất cả
                </Option>
                {list_package.map((x) => {
                  return (
                    <Option key={`${x._id}`} value={x._id}>
                      {x.package_name}
                    </Option>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl sx={{ margin: "3px", width: "100%" }} size="md">
              <FormLabel>Trạng thái</FormLabel>
              <Select
                name="package"
                size="sm"
                value={status}
                sx={{ minWidth: 140 }}
                onChange={(e: any, newValue) => {
                  setStatus(newValue || status);
                }}
              >
                <Option key="0" value="all">
                  Tất cả
                </Option>
                <Option key="1" value="not-active">
                  Chưa kích hoạt
                </Option>
                <Option key="2" value="using">
                  Đang sử dụng
                </Option>
                <Option key="3" value="near-expired">
                  Sắp hết hạn
                </Option>
                <Option key="4" value="expired">
                  Đã hết hạn
                </Option>
                <Option key="5" value="clear-data">
                  Sắp xóa dữ liệu
                </Option>
              </Select>
            </FormControl>
            <Box sx={{ margin: "3px", width: "100%" }}>
              <div style={{ height: "25px" }}></div>
              <Button
                size="sm"
                sx={{
                  height: "2.3vh",
                  width: {
                    xs: "100%",
                    lg: "120px",
                  },
                }}
                startDecorator={<SearchIcon />}
                onClick={() => handleSearch()}
              >
                Tìm kiếm
              </Button>
            </Box>
          </div>
          <div className="ButtonDiv" style={{ display: "flex" }}>
            {/* <FormControl sx={{ margin: "5px", width: "8vw" }} size="sm">
              <div style={{ height: "2.3vh" }} />
              <Button
                size="sm"
                style={{ width: "7vw" }}
                color="success"
                disabled={page <= 0}
                startDecorator={<DownloadIcon />}
                onClick={() => exportExcel()}
              >
                Xuất Excel
              </Button>
            </FormControl> */}
            {/* <FormControl sx={{ margin: "5px" }} size="sm">
              <div style={{ height: "2.3vh" }} />
              <Button
                size="sm"
                // style={{ width: "7vw" }}
                startDecorator={<AddIcon />}
                onClick={() => navigate("/package/addnew")}
              >
                Thêm mới
              </Button>
            </FormControl> */}
          </div>
        </Box>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
          }}
        >
          <Typography
            sx={{
              textAlign: "end",
              pr: "10px",
            }}
          >
            Tổng số bản ghi : {page}
          </Typography>
        </div>
        <Sheet
          className="OrderTableContainer"
          variant="outlined"
          sx={{
            width: "100%",
            borderRadius: "md",
            flex: 1,
            overflow: "auto",
            minHeight: "63vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          {rows.length > 0 ? (
            <div className="DivTable" style={{ width: "100%", height: "70vh" }}>
              <Table
                aria-labelledby="tableTitle"
                stickyHeader
                hoverRow
                borderAxis="bothBetween"
                sx={{
                  "& tr > *:last-child": {
                    position: "sticky",
                    right: 0,
                    bgcolor: "var(--TableCell-headBackground)",
                  },
                  "& tr > *:first-of-type": { textAlign: "center" },
                  "& tr > *:nth-of-type(7)": { textAlign: "center" },
                  "& tr > *:nth-of-type(3)": { textAlign: "center" },
                  "& tr > *:nth-of-type(6)": { textAlign: "center" },
                  "& th": {
                    color: "#096BDE",
                  },
                }}
                style={{ tableLayout: "auto" }}
              >
                <thead>
                  <tr>
                    <th style={{ width: "4%", textAlign: "center" }}>STT</th>
                    <th style={{ width: "15%", textAlign: "center" }}>
                      Domain
                    </th>
                    <th style={{ width: "8%", textAlign: "center" }}>
                      Mã định danh
                    </th>
                    <th style={{ width: "15%", textAlign: "center" }}>
                      Dòng họ
                    </th>
                    <th style={{ width: "15%", textAlign: "center" }}>
                      Gói dịch vụ
                    </th>
                    <th style={{ width: "8%", textAlign: "center" }}>
                      Ngày hết hạn
                    </th>
                    <th style={{ width: "4%", textAlign: "center" }}>
                      Trạng thái
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {rows.map((row: Customer, index: number) => (
                    <tr key={index}>
                      <td>{(currentpage - 1) * 10 + index + 1}</td>
                      <td>
                        {
                          // <Link
                          // href={row!.domain}
                          // component={'a'}
                          // onClick={() => {
                          //     window.open(
                          //       row!.domain as unknown as string,
                          //       "_blank",
                          //       "noopener,noreferrer"
                          //     );
                          //   }}
                          // >{row!.domain}</Link>
                          <a href={row!.domain as any} target="_blank">
                            {row!.domain}
                          </a>
                        }
                      </td>
                      <td>{row!.family_alias}</td>
                      <td>{row!.family!.family_name}</td>
                      <td>{(row as any)!.package!.package_name}</td>
                      <td>
                        {
                          <DisplayDate
                            value={(row!.family!.package_expiration_date
                              ? row!.family!.package_expiration_date
                              : ""
                            ).toString()}
                          />
                        }
                      </td>
                      <td>
                        {/* {statusData.map((x) => {
                          if ((row as any)!.status) {
                            return (
                              <Chip
                                variant="solid"
                                size="md"
                                color={x.color}
                                key={x.key}
                              >
                                {x.value}
                              </Chip>
                            );
                          }
                          return;
                        })} */}
                        {(row as any)!.status ? (
                          <Chip variant="solid" size="md" color="success">
                            Đã kích hoạt
                          </Chip>
                        ) : (
                          <Chip
                            variant="solid"
                            size="md"
                            color="neutral"
                            onClick={(e) => handleAddDomain(row)}
                          >
                            Chưa kích hoạt
                          </Chip>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <div
              style={{
                width: "100%",
                textAlign: "center",
                margin: "auto",
                height: "10vh",
              }}
            >
              {" "}
              <Typography level="body-lg">
                Không có kết quả phù hợp !
              </Typography>{" "}
            </div>
          )}
          <PopupRender>{popup}</PopupRender>
          <CommonPopupRender {...initPopup} />
        </Sheet>
        <Box sx={{ marginTop: "10px", display: { md: "block", xs: "none" } }}>
          <Pagination
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "auto",
            }}
            showFirstButton
            showLastButton
            count={page > 10 ? Math.ceil(page / 10) : 1}
            size="medium"
            color="primary"
            page={currentpage}
            onChange={handleChangePage}
          />
        </Box>
        <Box
          sx={{
            display: { xs: "flex", md: "none" },
            margin: "auto",
          }}
        >
          <IconButton
            size="sm"
            color="neutral"
            variant="plain"
            disabled={currentpage === 1}
            onClick={() => {
              setCurrentPage(1);
              setsearchClick(!searchClick);
            }}
            sx={{ bgcolor: "background.surface" }}
          >
            <FirstPageIcon />
          </IconButton>
          <IconButton
            size="sm"
            color="neutral"
            variant="plain"
            disabled={currentpage === 1}
            onClick={() => {
              setCurrentPage(currentpage - 1);
              setsearchClick(!searchClick);
            }}
            sx={{ bgcolor: "background.surface" }}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
          <Typography textAlign="center" sx={{ minWidth: 80 }}>
            {`Trang ${currentpage}`}
          </Typography>
          <IconButton
            size="sm"
            color="neutral"
            variant="plain"
            disabled={currentpage == Math.ceil(page / 10) || rows.length == 0}
            onClick={() => {
              setCurrentPage(currentpage + 1);
              setsearchClick(!searchClick);
            }}
            sx={{ bgcolor: "background.surface" }}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
          <IconButton
            size="sm"
            color="neutral"
            variant="plain"
            disabled={currentpage == Math.ceil(page / 10) || rows.length == 0}
            onClick={() => {
              setCurrentPage(Math.ceil(page / 10));
              setsearchClick(!searchClick);
            }}
            sx={{ bgcolor: "background.surface" }}
          >
            <LastPageIcon />
          </IconButton>
        </Box>
        <Backdrop
          sx={{
            background: "rgba(245, 245, 245, 0.6)",
            zIndex: 10000,
            backdropFilter: "blur(5px)",
          }}
          open={loading}
          transitionDuration={{
            appear: 1,
            exit: 1,
          }}
        >
          <CircularProgress thickness={4} size="lg" variant="plain" />
        </Backdrop>
      </div>
    </Box>
  );
}
