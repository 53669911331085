import { useState, useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import app from "../../common/sendrequest";
import * as React from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Typography,
  Card,
  Select,
  Option,
  IconButton,
  Box,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
  FormHelperText,
  Autocomplete,
  Chip,
  ChipDelete,
  Divider,
} from "@mui/joy";
import { useNavigate, useParams } from "react-router-dom";
import { isEmpty, isLetter } from "../../common/expression";
import { validatePhone } from "../../common/expression";
import { validateEmail } from "../../common/expression";
import configs from "../../config";
import './DetailUser.css';
import axios, { AxiosError } from "axios";
import CommonPopupRender from "../../common/popuprender";
import { createTheme, useMediaQuery } from "@mui/material";
import DatePicker from "react-datepicker";
import CalendarTodaySharpIcon from '@mui/icons-material/CalendarTodaySharp';
import { registerLocale } from "react-datepicker";
import { vi } from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css";
const initialValidate = { invalid: false, errors: "" };
type ServerError = { message: string };

export default function InforDetailUser() {
  const [state, setState] = React.useState({
    full_name: "",
    email: "",
    birth_day: "",
    gender: 0,
    phone: "",
  });
  const [birth_day, setBirthday] = React.useState<Date>();
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 375,
        md: 900,
        lg: 1280,
        xl: 1536,
      },
    },
  });
  const laptopUI = useMediaQuery(theme.breakpoints.up("lg"));
  const [validateName, setvalidateName] = useState(initialValidate);
  const [validateEmails, setvalidateEmail] = useState(initialValidate);
  const [validatePhones, setvalidatePhone] = useState(initialValidate);
  const [initPopup, setInitPopup] = useState({
    openFlag: false,
    type: "",
    content: "",
    onPass: () => {},
    onCancel: null,
    setOpenFlag: (flag: boolean) =>
      setInitPopup({ ...initPopup, openFlag: flag }),
  });

  let navigate = useNavigate();
  let { id } = useParams();

  useEffect(() => {
    getData();
    sessionStorage.setItem(
      "user_searchname_next",
      sessionStorage.getItem("user_searchname") || "{}"
    );
  }, []);

  const getData = async () => {
    await app
      .get(configs.api + "auth/user/" + id)
      .then((data: any) => {
        setState({
          full_name: data.data.full_name ? data.data.full_name : "",
          email: data.data.email ? data.data.email : "",
          phone: data.data.phone ? data.data.phone : "",
          birth_day: data.data.birth_day
            ? new Date(data.data.birth_day.toString()).toLocaleDateString(
                "en-CA"
              )
            : "",
          gender: data.data.gender ? data.data.gender : 0,
        });
        if(data.data.birth_day)
        {
          setBirthday(new Date(data.data.birth_day))
        }
      })
      .catch((err) => {
        handleError(err);
      });
  };
  const handleError = (err: any) => {
    if (axios.isAxiosError(err)) {
      const serverError = err as AxiosError<ServerError>;
      if (serverError && serverError.response) {
        setInitPopup({
          ...initPopup,
          openFlag: true,
          type: "fail",
          content:  serverError.response.data.message,
        });
      }
    }
  };
  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (
      state.full_name == "" ||
      validateName.invalid
    ) {
      return;
    }

    if(validateEmails.invalid && state.email)
    {
      return;
    }

    if(validatePhones.invalid && state.phone)
    {
      return;
    }
    setInitPopup({
      ...initPopup,
      type: "confirmed",
      openFlag: true,
      content: "Bạn có chắc muốn thay đổi thông tin tài khoản này không ?",
      onPass: () =>
        app
          .post(`${configs.auth}updateuser`, {
            id: id,
            full_name: state.full_name,
            email: state.email,
            phone: state.phone,
            birth_day: birth_day,
            gender: state.gender,
          })
          .then(() => {
            setInitPopup({
              ...initPopup,
              openFlag: true,
              type: "success",
              content: "Thay đổi thông tin thành công!",
              onPass: () => {
                navigate('/user')
                getData();
              },
            });
          })
          .catch((err) => {
            handleError(err);
          }),
    });
  }

  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setState({ ...state, [name]: value });
  };

  const handleDateChange = (date: any) => {
    setBirthday(date)
  };

  const onBlurEmail = (e: any) => {
    if (!validateEmail(e.target.value) && !isEmpty(e.target.value)) {
      setvalidateEmail({
        invalid: true,
        errors: "Email sai định dạng",
      });
    } else setvalidateEmail({ invalid: false, errors: "" });
  };

  const onBlurNameHandler = (e: any) => {
    if (isEmpty(e.target.value)) {
      setvalidateName({
        invalid: true,
        errors: "Họ và tên người dùng không được để trống",
      });
    } else if (!isLetter(e.target.value)) {
      setvalidateName({
        invalid: true,
        errors: "Họ và tên người không có số",
      });
    } else if (e.target.value.length > 250) {
      setvalidateName({
        invalid: true,
        errors: "Tên không được vượt qua 250 kí tự",
      });
    } else {
      setvalidateName({ invalid: false, errors: "" });
    }
  };

  const onBlurPhone = (e: any) => {
    if (!validatePhone(e.target.value) && !isEmpty(e.target.value)) {
      setvalidatePhone({
        invalid: true,
        errors: "Số điện thoại sai định dạng",
      });
    } else setvalidatePhone({ invalid: false, errors: "" });
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginBottom: "auto",
      }}
    >
      <div style={{ width: "90%", marginLeft: laptopUI ? "4%" : "1%" }}>
        <div>
          <Typography
            level="h3"
            component="h1"
            sx={{ alignSelf: "center", textAlign: "center" , padding : "2vh"}}
          >
            Thông tin người dùng
          </Typography>
          <Card
            variant="outlined"
            sx={{
              marginLeft: "auto",
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <form
              onSubmit={onSubmit}
              style={{ width: "100%", maxWidth: "800px" }}
            >
              <Stack
                spacing={2}
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <FormControl sx={{ width: "100%" }} required>
                  <FormLabel>Họ và tên</FormLabel>
                  <Input
                    required
                    className="InputForm"
                    name="full_name"
                    type="text"
                    error={validateName.invalid}
                    sx={{ width: "100%" }}
                    onChange={handleChange}
                    value={state.full_name}
                    onBlur={onBlurNameHandler}
                  />
                  {validateName.invalid ? (
                    <FormHelperText sx={{ color: "red" }}>
                      {validateName.errors}
                    </FormHelperText>
                  ) : (
                    <></>
                  )}
                </FormControl>
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel>Email</FormLabel>
                  <Input
                    className="InputForm"
                    name="email"
                    type="text"
                    sx={{ width: "100%" }}
                    onChange={handleChange}
                    // error={validateEmails.invalid}
                    value={state.email}
                    // onBlur={onBlurEmail}
                  />
                  {validateEmails.invalid ? (
                    <FormHelperText sx={{ color: "red" }}>
                      {validateEmails.errors}
                    </FormHelperText>
                  ) : (
                    <></>
                  )}
                </FormControl>
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel>Số điện thoại</FormLabel>
                  <Input
                    className="InputForm"
                    name="phone"
                    type="text"
                    sx={{ width: "100%" }}
                    onChange={handleChange}
                    // error={validatePhones.invalid}
                    value={state.phone}
                    // onBlur={onBlurPhone}
                  />
                  {validatePhones.invalid ? (
                    <FormHelperText sx={{ color: "red" }}>
                      {validatePhones.errors}
                    </FormHelperText>
                  ) : (
                    <></>
                  )}
                </FormControl>
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel>Ngày sinh</FormLabel>
                  {/* <Input
                    className="InputForm"
                    name="birth_day"
                    type="date"
                    sx={{ width: "100%", pt: "8px" }}
                    onChange={handleChange}
                    value={state.birth_day}
                  /> */}
                  <DatePicker
                    onChange={handleDateChange}
                    selected={birth_day}
                    customInput={
                      <Input
                        className="InputForm"
                        name="birth_day"
                        sx={{ width: "100%" }}
                        endDecorator={
                          <CalendarTodaySharpIcon fontSize="small" />
                        }
                      />
                    }
                    locale={vi}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="dd/mm/yyyy"
                  />
                </FormControl>
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel>Giới tính</FormLabel>
                  <Select
                    className="InputForm"
                    name="genders"
                    sx={{ width: "100%" }}
                    value={state.gender.toString()}
                    onChange={(e: any, newValue) => {
                      setState({ ...state, gender: Number(newValue) });
                    }}
                  >
                    <Option value="1"> Nam</Option>
                    <Option value="2"> Nữ </Option>
                  </Select>
                </FormControl>
                <CommonPopupRender {...initPopup} />
                <Box sx={{ display: "flex" }}>
                  <Button
                    onClick={() => {
                      navigate("/user");
                    }}
                    variant="outlined"
                    color="primary"
                    sx={{
                      width: "150px",
                      background: "#DDF1FF",
                      marginRight: "15px",
                    }}
                  >
                    Quay lại
                  </Button>
                  <Button sx={{ width: "150px" }} type="submit">
                    Cập nhật
                  </Button>
                </Box>
              </Stack>
            </form>
          </Card>
        </div>
      </div>
    </Box>
  );
}
