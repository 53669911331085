import { Navigate } from 'react-router-dom';
import * as React from 'react';

const CheckRoute = ({ children } : any) => {
    let data = JSON.parse(localStorage.getItem("user") || "{}");
    
    if (data == undefined) {
        return <Navigate to="/login" />;
    }
    else {
        if( data.role == "root")
        {
            const not_allow_path = ['customer','login',"order/pending",'domain-list'];  
            const checked_flag = not_allow_path.some(x=>window.location.href.includes(x));
            if(checked_flag)
            return <Navigate to="/user" />;
        }
    
        if(data.role == "admin")
        {
            const not_allow_path = ['package','/user','login','email-setting','infor-setting','time-setting','domain-setting',];
            const checked_flag = not_allow_path.some(x=>window.location.href.includes(x));
            if(checked_flag)
            return <Navigate to="/order/pending" />;
        }

    }
    return children;
};

export default CheckRoute;