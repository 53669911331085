import {
  Button,
  Typography,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  Box,
  Option,
  Stack,
  Card,
  Textarea,
} from "@mui/joy";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import configs from "../../config";
import {
  isEmpty,
  convertViToEn,
} from "../../common/expression";
import './DetailPackage.css';
import app from "../../common/sendrequest";
import { useEffect, useRef } from "react";
import axios, { AxiosError } from "axios";
import { NumericFormatCustom } from "../../common/currencyrender";
import CommonPopupRender from "../../common/popuprender";
import { createTheme, useMediaQuery } from "@mui/material";
const initialValidate = { invalid: false, errors: "" };
type ServerError = { message: string };

export default function AddPackage() {
  let navigate = useNavigate();
  sessionStorage.removeItem('pending_searchname_next');
  const nameForm = useRef(null);
  const [list_type, setListType] = React.useState([{
    "type_key": "",
    "type_name": ""
}])
  const [state, setState] = React.useState({
    package_name : "",
    package_description : "",
    is_active : true,
    is_public : true,
    price: 0,
    cycle_day: undefined,
    product_package_alias:'',
    package_limit: {
      limit_member:0,
      allow_upload_file:true,
      limit_storage_mb:0,
      limit_users:0,
    },
    package_type: '',
  });
  const [initPopup, setInitPopup] = React.useState({
    openFlag: false,
    type: "",
    content: "",
    onPass: () => {},
    onCancel: () => {},
    setOpenFlag: (flag: boolean) =>
      setInitPopup({ ...initPopup, openFlag: flag }),
  });
  const [validateName, setvalidateName] = React.useState(initialValidate);
  const [validatePrice, setvalidatePrice] = React.useState(initialValidate);
  const [validateAlias, setvalidateAlias] = React.useState(initialValidate);
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 375,
        md: 900,
        lg: 1280,
        xl: 1536,
      },
    },
  });
  const laptopUI = useMediaQuery(theme.breakpoints.up('lg'));

  const handleError = (err: any) => {
    if (axios.isAxiosError(err)) {
      const serverError = err as AxiosError<ServerError>;
      if (serverError && serverError.response) {
        setInitPopup({
          ...initPopup,
          openFlag: true,
          type: "fail",
          content:  serverError.response.data.message,
        });
      }
    }
  };
  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setState({ ...state, [name]: name != 'product_package_alias'? value : convertViToEn(value.toUpperCase())});
  };

  const handleChildChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setState({ ...state, package_limit: {...state.package_limit, [name]: value} });
  };

  const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (
      state.package_name == "" ||
      validateName.invalid
    ) {
      return;
    }
    if (
      state.package_name == "" ||
      validateName.invalid
    ) {
      return;
    }
    let nagitive_check = [];
    if (state.cycle_day) {
      if (state.cycle_day < 0) {
        nagitive_check.push("Chu kì gia hạn");
      }
    }

    if (state.price) {
      if (state.price < 0) {
        nagitive_check.push("Giá gói");
      }
    }

    if (state.package_limit.limit_member) {
      if (state.package_limit.limit_member < 0) {
        nagitive_check.push("Số thành viên");
      }
    }

    if (state.package_limit.limit_users) {
      if (state.package_limit.limit_users < 0) {
        nagitive_check.push("Số người dùng");
      }
    }

    if (state.package_limit.limit_storage_mb) {
      if (state.package_limit.limit_storage_mb < 0) {
        nagitive_check.push("Dung lượng tối đa");
      }
    }

    if(nagitive_check.length > 0)
    {
      setInitPopup({
        ...initPopup,
        openFlag: true,
        type: "fail",
        content: `${nagitive_check.join(" ,")} không được nhập số âm` ,
      });
      return;
    }

    await app
      .post(configs.api + "package/newpackage", {
        package_name: state.package_name.trim(),
        package_description: state.package_description,
        product_package_alias: state.product_package_alias,
        cycle_day: state.cycle_day,
        is_active: state.is_active,
        is_public: state.is_public,
        price: state.price,
        package_limit: state.package_limit,
        package_type: state.package_type
      })
      .then(async (data: any) => {
        setInitPopup({
          ...initPopup,
          openFlag: true,
          type: "success",
          content: "Thêm mới thành công",
          onPass: () => {
            navigate("/package");
          },
          onCancel: () => {
            navigate("/package");
          },
        });
      })
      .catch((err) => handleError(err));
      
  };

  const onBlurNameHandler = (e: any) => {
    if (isEmpty(e.target.value)) {
      setvalidateName({
        invalid: true,
        errors: "Tên gói dịch vụ không được để trống",
      });
    } else if (e.target.value.length > 250) {
      setvalidateName({
        invalid: true,
        errors: "Tên gói dịch vụ không được vượt qua 250 kí tự",
      });
    } else {
      setvalidateName({ invalid: false, errors: "" });
    }
  };

  const onBlurPriceHandler = (e: any) => {
    if (isEmpty((e.target as HTMLInputElement).value)) {
      setvalidatePrice({
        invalid: true,
        errors: "Giá gói dịch vụ không được để trống",
      });
    }else {
      setvalidatePrice({ invalid: false, errors: "" });
    }
  };

  const onValidPackageName = (e : any) => {
    if (isEmpty((e.target as HTMLInputElement).value)) {
      setvalidateName({
        invalid: true,
        errors: "Tên gói dịch vụ không được để trống",
      });
    }
    (e.target as HTMLInputElement).setCustomValidity(" ");
  }

  const onInputAlias = (e :any) => {
    (e.target as HTMLInputElement).setCustomValidity("");
    setvalidateAlias({ invalid: false, errors: "" });
  }

  const onValidAlias = (e : any) => {
    if (isEmpty((e.target as HTMLInputElement).value)) {
      setvalidateAlias({
        invalid: true,
        errors: "Mã gói dịch vụ không được để trống",
      });
    }
    (e.target as HTMLInputElement).setCustomValidity(" ");
  }

  const onInputPackageName = (e :any) => {
    (e.target as HTMLInputElement).setCustomValidity("");
    setvalidateName({ invalid: false, errors: "" });
  }

  const onValidPrice = (e : any) => {
    if (isEmpty((e.target as HTMLInputElement).value)) {
      setvalidatePrice({
        invalid: true,
        errors: "Giá gói dịch vụ không được để trống",
      });
    }
    (e.target as HTMLInputElement).setCustomValidity(" ");
  }

  const onKeyPressPrice = (event :any) => {
    if (event.key == "-") {
      event.preventDefault();
      (event.target as HTMLInputElement).value = "0";
    }else{
      (event.target as HTMLInputElement).setCustomValidity("");
      setvalidatePrice({ invalid: false, errors: "" });
    }
  }
  const onKeyPressDay = (event :any) => {
    if (event.key == "-") {
      event.preventDefault();
      const name = event.target.name;
      setState({ ...state, package_limit: {...state.package_limit, [name]: "0"} });
    }
    
  }


  const positiveNumValidate = {
    input: {
      min: 0,
    },
  }

  useEffect(() => {
    getSetting();
  }, []);
 
  const getSetting = () =>{
    app
    .post(`${configs.api}setting/list`)
    .then((data: any) => {
      if (data.data) {
        const emaildata = data.data.find((x: any) => x.key_setting == "product_package_type");
        setListType(emaildata.value_setting);
        setState({...state, package_type: emaildata.value_setting[0] ? emaildata.value_setting[0].type_key : "" })
      }
    })
    .catch((err) => {
      console.log(err);
      setListType([{
        "type_key": "",
        "type_name": ""
      }]);
    });
  }

  sessionStorage.setItem('package_searchname_next',sessionStorage.getItem('package_searchname')||"{}");

  return (
    <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginBottom : "auto"
        }}
      >

    <div style={{ width : "90%",marginLeft :(laptopUI ? "4%" : "1%") }}>
      <div>
        <Typography level="h4" component="h1" sx={{ alignSelf: "center", textAlign: 'center' , padding : "2vh"}}>
          Thông tin gói dịch vụ mới
        </Typography>
        </div>
        <div>
        <Card
            variant="outlined"
            sx={{ marginLeft : "auto" , width: "100%", display: "flex", alignItems: "center" }}
        >
          <form ref={nameForm} onSubmit={onSubmitHandler} style={{ width: '100%', maxWidth : "800px"}}>
            <Stack
              spacing={2}
              sx={{
                width: '100%',
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <FormControl sx={{ width: '100%' }} required>
                <FormLabel>Tên gói dịch vụ</FormLabel>
                <Input
                  name="package_name"
                  type="text"
                  className="InputForm"
                  error={validateName.invalid}
                  sx={{ width: "100%" }}
                  onChange={handleChange}
                  value={state.package_name}
                  onBlur={onBlurNameHandler}
                  onInvalid={onValidPackageName}
                  onInput={onInputPackageName}
                />
                {validateName.invalid ? (
                  <FormHelperText sx={{ color: "red" }}>
                    {validateName.errors}
                  </FormHelperText>
                ) : (
                  <></>
                )}
              </FormControl>
              <Box sx={{display:{ xs: "block", md: "grid" }, width: '100%', gridTemplateColumns : "64% 34%", columnGap: "2%"}}>
                <FormControl sx={{ width: '100%' }} required>
                  <FormLabel>Mã gói dịch vụ</FormLabel>
                  <Input
                    name="product_package_alias"
                    className="InputForm"
                    sx={{ width: "100%" }}
                    slotProps={
                      {
                        input: {
                          maxLength:20,
                        }
                      }
                    }
                    error={validateAlias.invalid}
                    // onKeyUp={onKeyUpAlias}
                    onInvalid={onValidAlias}
                    onInput={onInputAlias}
                    onChange={handleChange}
                    value={state.product_package_alias}
                  />
                  {validateAlias.invalid ? (
                    <FormHelperText sx={{ color: "red" }}>
                      {validateAlias.errors}
                    </FormHelperText>
                  ) : (
                    <></>
                  )}
                </FormControl>
                <FormControl sx={{ width: '100%' }}>
                  <FormLabel>Loại gói</FormLabel>
                  <Select
                    className="InputForm"
                    name="package_type"
                    sx={{ width: "100%" }}
                    value={state.package_type}
                    onChange={(e: any, newValue) => {
                      setState({ ...state, package_type: newValue || list_type[0].type_key});
                    }}
                  >
                    {list_type.map(x=>(<Option key={x.type_key} value={x.type_key}>{x.type_name}</Option>))}
                  </Select>
                </FormControl>
              </Box>
              <FormControl sx={{ width: '100%' }}>
                <FormLabel>Mô tả gói dịch vụ</FormLabel>
                <Textarea
                  minRows={4}
                  maxRows={4}
                  name="package_description"
                  className="InputForm"
                  sx={{ width: "100%" }}
                  onChange={handleChange}
                  value={state.package_description}
                />
              </FormControl>
              <Box sx={{display:{ xs: "block", md: "grid" }, width: '100%', gridTemplateColumns : "34% 64%", columnGap: "2%"}}>
              <FormControl sx={{ width: '100%' }}>
                <FormLabel>Chu kì gia hạn</FormLabel>
                <Input
                  name="cycle_day"
                  className="InputForm"
                  type="number"
                  sx={{ width: "100%" }}
                  onKeyPress={onKeyPressDay}
                  slotProps={positiveNumValidate}
                  onChange={handleChange}
                  value={state.cycle_day!=undefined ?state.cycle_day:""}
                />
              </FormControl>
              <FormControl sx={{ width: '100%' }}>
                <FormLabel>Trạng thái hoạt động</FormLabel>
                <Select
                  className="InputForm"
                  name="status"
                  sx={{ width: "100%" }}
                  value={state.is_active.toString()}
                  onChange={(e: any, newValue) => {
                    const value = (newValue == "true")?true:false;
                    setState({ ...state, is_active: value });
                  }}
                >
                  <Option value="true"> Đang hoạt động</Option>
                  <Option value="false"> Tạm dừng </Option>
                </Select>
              </FormControl>
              </Box>
              <FormControl sx={{ width: '100%' }} required>
                <FormLabel>Giá gói dịch vụ</FormLabel>
                <Input
                  name="price"
                  className="InputForm"
                  error={validatePrice.invalid}
                  sx={{ width: "100%" }}
                  onChange={handleChange}
                  onKeyPress={onKeyPressPrice}
                  onBlur={onBlurPriceHandler}
                  onInvalid={onValidPrice}
                  value={state.price}
                  endDecorator={'VND'}
                  slotProps={{
                    input: {
                      component : NumericFormatCustom as any
                    },
                  }}
                />
                {validatePrice.invalid ? (
                  <FormHelperText sx={{ color: "red" }}>
                    {validatePrice.errors}
                  </FormHelperText>
                ) : (
                  <></>
                )}
              </FormControl>
              <Box sx={{display:{ xs: "block", md: "grid" }, width: '100%', gridTemplateColumns : "40% 30% 20%", columnGap: "1.5%"}}>
              <FormControl sx={{ width: '100%' }}>
                <FormLabel>Số thành viên</FormLabel>
                <Input
                  name="limit_member"
                  className="InputForm"
                  type="number"
                  sx={{ width: "100%" }}
                  onKeyPress={onKeyPressDay}
                  slotProps={positiveNumValidate}
                  onChange={handleChildChange}
                  value={state.package_limit.limit_member}
                />
              </FormControl>
              <FormControl sx={{ width: '100%' }}>
                <FormLabel>Cho phép upload file</FormLabel>
                <Select
                  className="InputForm"
                  name="allow_upload_file"
                  sx={{ width: "100%" }}
                  value={state.package_limit.allow_upload_file.toString()}
                  onChange={(e: any, newValue) => {
                    const value = (newValue == "true")?true:false;
                    setState({ ...state, package_limit: {...state.package_limit, allow_upload_file: value} });
                  }}
                >
                  <Option value="true"> Có </Option>
                  <Option value="false"> Không </Option>
                </Select>
              </FormControl>
              <FormControl sx={{ width: '100%' }}>
                <FormLabel>Công khai</FormLabel>
                <Select
                  className="InputForm"
                  name="allow_upload_file"
                  sx={{ width: "100%" }}
                  value={state.is_public.toString()}
                  onChange={(e: any, newValue) => {
                    const value = (newValue == "true")?true:false;
                    setState({ ...state, is_public: value });
                  }}
                >
                  <Option value="true"> Có </Option>
                  <Option value="false"> Không </Option>
                </Select>
              </FormControl>
              </Box>
              <Box sx={{display:{ xs: "block", md: "grid" }, width: '100%', gridTemplateColumns : "64% 34%", columnGap: "2%"}}>
              <FormControl sx={{ width: '100%' }}>
                <FormLabel>Số người dùng</FormLabel>
                <Input
                  name="limit_users"
                  className="InputForm"
                  type="number"
                  sx={{ width: "100%" }}
                  slotProps={positiveNumValidate}
                  onKeyPress={onKeyPressDay}
                  onChange={handleChildChange}
                  value={state.package_limit.limit_users}
                />
              </FormControl>
              <FormControl sx={{ width: '100%' }}>
                <FormLabel>Dung lượng tối đa</FormLabel>
                <Input
                  name="limit_storage_mb"
                  className="InputForm"
                  type="number"
                  sx={{ width: "100%" }}
                  slotProps={positiveNumValidate}
                  onKeyPress={onKeyPressDay}
                  endDecorator={"MB"}
                  onChange={handleChildChange}
                  value={state.package_limit.limit_storage_mb}
                />
              </FormControl>
              </Box>
              <Box sx={{ display: 'flex' }}>
                <Button 
                  onClick={() => {
                      navigate("/package");
                    }} 
                  variant="outlined"
                  color="primary"
                  sx={{ width: "150px", background : "#DDF1FF" , marginRight: '15px'}}>
                    Quay lại
                </Button>
                <Button sx={{ width: "150px" }} type="submit">
                  Tạo mới
                </Button>
                
              </Box>
              <CommonPopupRender {...initPopup} />
            </Stack>
          </form>
        </Card>

      </div>
    </div>
      </Box>
  );
}
