import moment from "moment";
import * as React from 'react';

export const DisplayDate = ({ value = '', defaultValue = 'Chưa rõ' }) => {
    if (!value) {
        return <>{defaultValue}</>;
    }

    try {
        const momentDate = moment(new Date(value)).utcOffset('+07');
        return <>{momentDate.format("DD/MM/YYYY")}<br/>{momentDate.format("H:mm")}</>;
    }
    catch (e) {
        return <>{defaultValue}</>
    }
}