import * as React from "react";
import { useState, useEffect } from "react";
import Table from "@mui/joy/Table";
import app from "../../common/sendrequest";
import CommonPopupRender from "../../common/popuprender";
import SearchIcon from "@mui/icons-material/Search";
import { Admin } from "../auth/User.type";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Pagination from "@mui/material/Pagination";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import {
  Typography,
  Sheet,
  Button,
  Modal,
  ModalClose,
  ModalDialog,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Divider,
  Box,
  styled,
  Tooltip,
  IconButton,
  Select,
  Option,
  Chip,
  Switch,
} from "@mui/joy";
import { useNavigate } from "react-router-dom";
import { ButtonGroup, Button as MButoon } from "@mui/material";
import axios, { AxiosError } from "axios";
import configs from "../../config";
import './ListAllPackage.css';
import { Package } from "./Package.type";
import { convertDate } from "../../common/expression";
import { DisplayDate } from "../component/DisplayDate";
import { Setting } from "../setting/Setting.type";
type ServerError = { message: string };

export default function ListPackage() {

  const sessiondata = JSON.parse(sessionStorage.getItem('package_searchname_next') || '{"searchName":"","currentpage":1,"activeStatus":"true","publicStatus":"all","packageType":"all"}');
  const [rows, setRow] = useState<Package[]>([]);
  const [searchClick, setsearchClick] = useState(false);
  const [packageType, setPackageType] = useState(sessiondata!.packageType);
  const [list_type, setListType] = useState<Setting[]>([]);
  const [popup, setPopUp] = useState<JSX.Element>(<></>);
  const [open, setOpen] = useState<boolean>(false);
  const [currentpage, setCurrentPage] = useState(sessiondata!.currentpage);
  const [searchName, setName] = useState(sessiondata!.searchName);
  const [searchNameRef, setNameRef] = useState(sessiondata!.searchName);
  const [activeStatus, setActiveStatus] = useState<string>(sessiondata!.activeStatus);
  const [publicStatus, setPublicStatus] = useState<string>(sessiondata!.publicStatus);
  const [page, setPage] = useState(0);
  const [initPopup, setInitPopup] = useState({
    openFlag: false,
    type: "",
    content: "",
    onPass: () => {},
    onCancel: null,
    setOpenFlag: (flag: boolean) =>
      setInitPopup({ ...initPopup, openFlag: flag }),
  });

  let navigate = useNavigate();

  const PopupRender = ({ children }: any) => {
    return (
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog
          aria-labelledby="basic-modal-dialog-title"
          aria-describedby="basic-modal-dialog-description"
          sx={{ width: "20vw" }}
          size="md"
        >
          <ModalClose />
          {children}
        </ModalDialog>
      </Modal>
    );
  };
  
  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };
  const handleError = (err: any) => {
    if (axios.isAxiosError(err)) {
      const serverError = err as AxiosError<ServerError>;
      if (serverError && serverError.response) {
        setInitPopup({
          ...initPopup,
          openFlag: true,
          type: "fail",
          content:  serverError.response.data.message,
        });
      }
    }
  };
  const [tempSearch, setTempSearch] = useState({
    searchName, currentpage, activeStatus, publicStatus, packageType
  })
  
  const handleSearch = ()=>{
    setTempSearch({searchName, currentpage, activeStatus, publicStatus, packageType}); 
    setCurrentPage(1); 
    setsearchClick(!searchClick);
  }
  useEffect(() => {
    getSetting();
  }, []);

  useEffect(() => {
    getData();
    sessionStorage.setItem('package_searchname',JSON.stringify({searchName, currentpage, activeStatus, publicStatus, packageType}));
    sessionStorage.removeItem('package_searchname_next');
    sessionStorage.removeItem('pending_searchname_next');
  }, [searchClick, currentpage]);

  
 
  const getSetting = () =>{
    try {
      app
        .post(`${configs.api}setting/list`)
        .then((data: any) => {
          if (data.data) {
            const emaildata = data.data.find((x: any) => x.key_setting == "product_package_type");
            setListType(emaildata.value_setting);
          }else{
            setListType([]);
          }
        })
        .catch((err) => {
          console.log(err);
          setListType([]);
        });
    } catch (err) {
      setPage(0);
      setRow([]);
    }
  }
  const getData = () => {
    try {
      app
        .post(configs.api + "package/list_all", {
          searchstring: tempSearch.searchName,
          is_active: tempSearch.activeStatus,
          is_public: tempSearch.publicStatus,
          package_type: tempSearch.packageType,
          rowsPerpage: 10,
          currentPage: currentpage - 1,
        })
        .then((data: any) => {
          setPage(data.data.total);
          setRow(data.data.original);
        })
        .catch((err: Error) => handleError(err));
    } catch (err) {
      setPage(0);
      setRow([]);
    }
  };

  return (
    <Box
      sx={{
        marginBottom: "auto",
        marginLeft:"auto",
        marginRight:{xl:"auto", xs:"auto"},
        width: {xl:"85%", md:"90%", xs:"auto"}
      }}
    >
      <div
        className="coverbox"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "flex-start",
          // margin: "auto",
        }}
      >
        <Typography
          level="h4"
          component="h1"
          sx={{ alignSelf: "center", padding: "2vh" }}
        >
          Danh sách gói dịch vụ
        </Typography>
        <Box
          className="RootBoxs"
          sx={{
            display: "flex",
            // padding: "12px",
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div className="FindBox" style={{ display: "flex"}}>
            <FormControl sx={{ margin: "3px", width: "100%" }} size="md">
              <FormLabel>
                Từ khóa tìm kiếm
                <Tooltip
                  title="Nhập tên gói dịch vụ"
                  placement="right"
                  size="sm"
                >
                  <InfoOutlinedIcon fontSize="small" />
                </Tooltip>
              </FormLabel>
              <Input
                placeholder="Nhập nội dung tìm kiếm"
                value={searchName}
                sx={{ minWidth: 350 }}
                size="sm"
                onChange={(e) => {
                  setName((e.target as HTMLInputElement).value);
                }}
                onKeyDown={(e) => {
                  if(e.key === "Enter")
                  {
                    setName((e.target as HTMLInputElement).value);
                    setTempSearch({...tempSearch,searchName:(e.target as HTMLInputElement).value})
                    setCurrentPage(1);
                    setsearchClick(!searchClick);
                  }
                }}
              />
            </FormControl>
            <FormControl sx={{ margin: "3px", width: "100%" }} size="md">
              <FormLabel>Trạng thái dịch vụ</FormLabel>
              <Select
                name="status"
                size="sm"
                value={activeStatus}
                sx={{ minWidth: 150 }}
                onChange={(e: any, newValue: any) => {
                  setActiveStatus(newValue);
                }}
              >
                <Option value="all">Tất cả</Option>
                <Option value="true">Đang hoạt động</Option>
                <Option value="false">Đã tạm dừng</Option>
              </Select>
            </FormControl>
            <FormControl sx={{ margin: "3px", width: "100%" }} size="md">
              <FormLabel>Trạng thái công khai</FormLabel>
              <Select
                name="status"
                size="sm"
                value={publicStatus}
                sx={{ minWidth: 150 }}
                onChange={(e: any, newValue: any) => {
                  setPublicStatus(newValue);
                }}
              >
                <Option value="all">Tất cả</Option>
                <Option value="true">Có công khai</Option>
                <Option value="false">Không công khai</Option>
              </Select>
            </FormControl>
            <FormControl sx={{ margin: "3px", width: "100%" }} size="md">
              <FormLabel>Loại gói</FormLabel>
              <Select
                name="type"
                size="sm"
                value={packageType}
                sx={{ minWidth: 150 }}
                onChange={(e: any, newValue: any) => {
                  setPackageType(newValue||packageType);
                }}
              >
                <Option value="all">Tất cả</Option>
                {list_type.map(x=>(<Option key={x.type_key} value={x.type_key}>{x.type_name}</Option>))}
              </Select>
            </FormControl>
            <Box sx={{ margin: "3px", width: "100%" }}>
              <div style={{ height: "25px" }}></div>
              <Box
                sx={{
                  height: "2.3vh",
                  display: "flex",
                  width: {
                    xs: "100%",
                    lg: "170px",
                  },
                }}
              >
                <Button
                  size="sm"
                  startDecorator={<SearchIcon />}
                  onClick={() => handleSearch()}
                  sx={{
                    width: '90%',
                    marginRight: '5%'
                  }}
                >
                  Tìm kiếm
                </Button>
                <Tooltip title="Làm mới">
                  <IconButton
                    size="sm"
                    variant="outlined"
                    sx={{
                      height: "2.3vh",
                      width: '5%',
                    }}
                    onClick={() => {
                      setName('');
                      setActiveStatus('all');
                      setPublicStatus('all');
                      setPackageType('all');
                      setTempSearch({...tempSearch, searchName:'', packageType:'all', publicStatus:'all', activeStatus:'all'});
                      setCurrentPage(1);
                      setsearchClick(!searchClick);
                    }}
                  >
                    <RestartAltIcon color="primary" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </div>
          <div className="ButtonDiv" style={{ display: "flex" }}>
            {/* <FormControl sx={{ margin: "5px", width: "8vw" }} size="sm">
              <div style={{ height: "2.3vh" }} />
              <Button
                size="sm"
                style={{ width: "7vw" }}
                color="success"
                disabled={page <= 0}
                startDecorator={<DownloadIcon />}
                onClick={() => exportExcel()}
              >
                Xuất Excel
              </Button>
            </FormControl> */}
            <FormControl sx={{ margin: "5px" }} size="sm">
              <div style={{ height: "2.3vh" }} />
              <Button
                size="sm"
                // style={{ width: "7vw" }}
                startDecorator={<AddIcon />}
                onClick={() => navigate("/package/addnew")}
              >
                Thêm mới
              </Button>
            </FormControl>
          </div>
        </Box>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
          }}
        >
          <Typography
            sx={{
              textAlign: "end",
              pr: "10px",
            }}
          >
            Tổng số bản ghi : {page}
          </Typography>
        </div>
        <Sheet
          className="OrderTableContainer"
          variant="outlined"
          sx={{
            width: "100%",
            borderRadius: "md",
            flex: 1,
            overflow: "auto",
            minHeight: "63vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          {rows.length > 0 ? (
            <div className="DivTable" style={{ width: "100%", height: "70vh" }}>
              <Table
                aria-labelledby="tableTitle"
                stickyHeader
                hoverRow
                borderAxis="bothBetween"
                sx={{
                  "& tr > *:last-child": {
                    position: "sticky",
                    right: 0,
                    bgcolor: "var(--TableCell-headBackground)",
                  },
                  "& tr > *:first-of-type": { textAlign: "center" },
                  "& tr > *:nth-of-type(6)": { textAlign: "right" },
                  "& tr > *:nth-of-type(7)": { textAlign: "right" },
                  "& tr > *:nth-of-type(8)": { textAlign: "right" },
                  "& tr > *:nth-of-type(9)": { textAlign: "right" },
                  "& tr > *:nth-of-type(10)": { textAlign: "right" },
                  "& tr > *:nth-of-type(11)": { textAlign: "center" },
                  "& tr > *:nth-of-type(12)": { textAlign: "center" },
                  "& tr > *:nth-of-type(13)": { textAlign: "center" },
                  "& tr > *:nth-of-type(14)": { textAlign: "center" },
                  "& tr > *:nth-of-type(15)": { textAlign: "center" },
                  "& th": {
                    color: "#096BDE",
                  },
                }}
                style={{ tableLayout: "auto" }}
              >
                <thead>
                  <tr>
                    <th style={{ width: "4%", textAlign: "center" }}>STT</th>
                    <th style={{ width: "7%", textAlign: "center" }}>Mã gói dịch vụ</th>
                    <th style={{ width: "15%", textAlign: "center" }}>
                      Gói dịch vụ
                    </th>
                    <th style={{ width: "15%", textAlign: "center" }}>
                      Mô tả gói
                    </th>
                    <th style={{ width: "15%", textAlign: "center" }}>
                      Loại gói
                    </th>
                    <th style={{ width: "10%", textAlign: "center" }}>
                      Chu kì GH
                    </th>
                    <th style={{ width: "10%", textAlign: "center" }}>
                      Giá dịch vụ
                    </th>
                    <th style={{ width: "10%", textAlign: "center" }}>
                      Thành viên
                    </th>
                    <th style={{ width: "10%", textAlign: "center" }}>
                      Người dùng
                    </th>
                    <th style={{ width: "15%", textAlign: "center" }}>
                      Dung lượng tối đa
                    </th>
                    <th style={{ width: "15%", textAlign: "center" }}>
                      Upload file
                    </th>
                    <th style={{ width: "15%", textAlign: "center" }}>
                      Trạng thái
                    </th>
                    <th style={{ width: "15%", textAlign: "center" }}>
                      Công khai
                    </th>
                    <th style={{ width: "10%", textAlign: "center" }}>
                      Ngày tạo
                    </th>
                    <th style={{ width: "10%", textAlign: "center" }}>
                      Ngày cập nhật
                    </th>
                    <th style={{ width: "10%", textAlign: "center" }}></th>
                  </tr>
                </thead>
                <tbody>
                  {rows.map((row: Package, index: number) => (
                    <tr key={row._id}>
                      <td>{(currentpage - 1) * 10 + index + 1}</td>
                      <td 
                      style={{
                        wordWrap: "break-word",
                        maxWidth: "20px",
                      }}
                      >{row.product_package_alias}</td>
                      <td>{row.package_name}</td>
                      {row.package_description ? (
                        <Tooltip
                          title={row!.package_description}
                          arrow
                          color="warning"
                          sx={{ maxWidth: "300px" }}
                        >
                          <td
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              maxWidth: "20px",
                            }}
                          >
                            {row!.package_description}
                          </td>
                        </Tooltip>
                      ) : (
                        <td>
                          <i>Không rõ</i>
                        </td>
                      )}
                      <td>{row!.package_type && list_type.length > 0 ? list_type!.find(x=>x.type_key == row.package_type)!.type_name : ""}</td>
                      <td>{row!.cycle_day ? row!.cycle_day : <i>Không rõ</i>}</td>
                      <td>
                        {row.price
                          .toLocaleString("vi-VN", {
                            style: "currency",
                            currency: "VND",
                          })
                          .replace("₫", "VND")}
                      </td>
                      <td>
                        {row!.package_limit!.limit_member
                          ? row!.package_limit!.limit_member
                          : "Không có"}
                      </td>
                      <td>
                        {row!.package_limit!.limit_users
                          ? row!.package_limit!.limit_users
                          : "Không có"}
                      </td>
                      <td>
                        {row!.package_limit!.limit_storage_mb
                          ? row!.package_limit!.limit_storage_mb + " MB"
                          : "Không có"}
                      </td>
                      <td>
                        {row!.package_limit!.allow_upload_file ? (
                          <Switch
                            checked
                            variant="solid"
                            size="md"
                            color="primary"
                          />
                        ) : (
                          <Switch
                            checked={false}
                            variant="solid"
                            size="md"
                            color="neutral"
                          />
                        )}
                      </td>
                      <td>
                        {row!.is_active ? (
                          <Switch
                            checked
                            variant="solid"
                            size="md"
                            color="primary"
                          />
                        ) : (
                          <Switch
                            checked={false}
                            variant="solid"
                            size="md"
                            color="neutral"
                          />
                        )}
                      </td>
                      <td>
                        {row!.is_public ? (
                          <Switch
                            checked
                            variant="solid"
                            size="md"
                            color="primary"
                          />
                        ) : (
                          <Switch
                            checked={false}
                            variant="solid"
                            size="md"
                            color="neutral"
                          />
                        )}
                      </td>
                      <td>
                        <DisplayDate
                          value={
                            row.created_at
                              ? row.created_at.toString()
                              : undefined
                          }
                        />
                      </td>
                      <td>
                        <DisplayDate
                          value={
                            row.update_at ? row.update_at.toString() : undefined
                          }
                        />
                      </td>
                      <td>
                        <ButtonGroup variant="text" size="medium">
                          <Tooltip title="Chỉnh sửa thông tin">
                            <MButoon
                              onClick={() => navigate(`/package/${row._id}`)}
                            >
                              <ModeEditIcon fontSize="small" />
                            </MButoon>
                          </Tooltip>

                          {/* <Tooltip title="Xóa gói dịch vụ">
                            <MButoon onClick={() => deleteCustomer(row._id)}>
                              <DeleteIcon fontSize="small" />
                            </MButoon>
                          </Tooltip> */}
                        </ButtonGroup>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <div
              style={{
                width: "100%",
                textAlign: "center",
                margin: "auto",
                height: "10vh",
              }}
            >
              {" "}
              <Typography level="body-lg">
                Không có kết quả phù hợp !
              </Typography>{" "}
            </div>
          )}
          <PopupRender>{popup}</PopupRender>
          <CommonPopupRender {...initPopup} />
        </Sheet>
        <Box sx={{ marginTop: "10px", display: { md: "block", xs: "none" } }}>
          <Pagination
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "auto",
            }}
            showFirstButton
            showLastButton
            count={page > 10 ? Math.ceil(page / 10) : 1}
            size="medium"
            color="primary"
            page={currentpage}
            onChange={handleChangePage}
          />
        </Box>
        <Box
          sx={{
            display: { xs: "flex", md: "none" },
            margin: "auto",
          }}
        >
          <IconButton
            size="sm"
            color="neutral"
            variant="plain"
            disabled={currentpage === 1}
            onClick={() => {setCurrentPage(1); setsearchClick(!searchClick);}}
            sx={{ bgcolor: "background.surface" }}
          >
            <FirstPageIcon />
          </IconButton>
          <IconButton
            size="sm"
            color="neutral"
            variant="plain"
            disabled={currentpage === 1}
            onClick={() => {setCurrentPage(currentpage - 1); setsearchClick(!searchClick);}}
            sx={{ bgcolor: "background.surface" }}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
          <Typography textAlign="center" sx={{ minWidth: 80 }}>
            {`Trang ${currentpage}`}
          </Typography>
          <IconButton
            size="sm"
            color="neutral"
            variant="plain"
            disabled={currentpage == Math.ceil(page / 10) || rows.length == 0}
            onClick={() => {setCurrentPage(currentpage + 1); setsearchClick(!searchClick);}}
            sx={{ bgcolor: "background.surface" }}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
          <IconButton
            size="sm"
            color="neutral"
            variant="plain"
            disabled={currentpage == Math.ceil(page / 10) || rows.length == 0}
            onClick={() => {setCurrentPage(Math.ceil(page / 10)); setsearchClick(!searchClick);}}
            sx={{ bgcolor: "background.surface" }}
          >
            <LastPageIcon />
          </IconButton>
        </Box>
      </div>
    </Box>
  );
}