// const HOST_API_URL = 'http://192.168.1.159:6060/api/';
// const HOST_API_URL = "http://localhost:6060/api/";
const HOST_API_URL = '/api/';
// const HOST_API_URL = 'https://quanly.giaphaso.com/api/';
const HOST_WEB_URL = "/";

const configs = {
  api: HOST_API_URL,
  auth: `${HOST_API_URL}auth/`,
  customer: `${HOST_API_URL}customer/`,
  package: `${HOST_API_URL}package/`,
  web: HOST_WEB_URL,
  default_pwd: "gi@phas0",
  customer_pwd: "gi@phas0",
  // reCaptchaKey: "6Le9QTklAAAAAOL2vYzxH1s3LpwNxYF80Yk6gKXF", // localhost
  reCaptchaKey: '6Ld5jzklAAAAAJZzWBkVlaGX83W-jZuREIsrjjo4', // release
  refresh_timeout: 30000,
  trial_domain: ["https://dungthu.giaphaso.com"],
  payment: [
    {
      account_name: "Công ty CP phát triển Giải pháp số Đại Việt",
      account_number: "1025907293",
      account_address: "Ngân hàng Vietcombank – Sở giao dịch Hà Nội",
      bank_key: "VIETCOMBANK",
    },
    // {
    //   account_name: 'Trịnh Văn Việt',
    //   account_number: '12810000379241',
    //   account_address: 'Ngân hàng BIDV - Chi nhánh Chương Dương',
    //   bank_key: 'BIDV',
    // },
    // {
    //   account_name: 'Hoàng Thị Hằng',
    //   account_number: '397966866666',
    //   account_address: 'Ngân hàng Quân Đội (MB) - Chi nhánh Hà Nội',
    //   bank_key: 'MBBANK',
    // },
  ],
};

export default configs;
