import { Navigate } from "react-router-dom";
import * as React from 'react';
import app from "../common/sendrequest";
import configs from "../config";
const Passcheck = () =>{
    const dataUser = app.post(configs.api + "auth/infor_user");

    if (!dataUser) {
        return <Navigate to="/login" />;
    }else{
        return <Navigate to="/order/pending" />;
    }
    
}

export default Passcheck ;
