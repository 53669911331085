import {
  Button,
  Typography,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  Box,
  Option,
  Stack,
  Card,
} from "@mui/joy";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import configs from "../../config";
import {
  isLetter,
  isEmpty,
  validateEmail,
  validatePhone,
  validateUserName,
} from "../../common/expression";
import './DetailUser.css';
import app from "../../common/sendrequest";
import { validatePassWord } from "../../common/expression";
import { useRef } from "react";
import axios, { AxiosError } from "axios";
import CommonPopupRender from "../../common/popuprender";
import { createTheme, useMediaQuery } from "@mui/material";
import DatePicker from "react-datepicker";
import CalendarTodaySharpIcon from '@mui/icons-material/CalendarTodaySharp';
import { registerLocale } from "react-datepicker";
import { vi } from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css";
const initialValidate = { invalid: false, errors: "" };
type ServerError = { message: string };

export default function AddUser() {
  let navigate = useNavigate();
  const nameForm = useRef(null);
  const [state, setState] = React.useState({
    user_name: "",
    full_name: "",
    password: configs.default_pwd,
    email: "",
    birth_day: "",
    gender: 0,
    phone: "",
  });
  const [initPopup, setInitPopup] = React.useState({
    openFlag: false,
    type: "",
    content: "",
    onPass: () => {},
    onCancel: () => {},
    setOpenFlag: (flag: boolean) =>
      setInitPopup({ ...initPopup, openFlag: flag }),
  });
  const [validateName, setvalidateName] = React.useState(initialValidate);
  const [validatePwd, setvalidatePwd] = React.useState(initialValidate);
  const [validateEmails, setvalidateEmail] = React.useState(initialValidate);
  const [birth_day, setBirthday] = React.useState<Date>();
  const [validatePhones, setvalidatePhone] = React.useState(initialValidate);
  const [validateuserName, setvalidateUserName] =
    React.useState(initialValidate);
  const [showPwd, setShowPwd] = React.useState(false);
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 375,
        md: 900,
        lg: 1280,
        xl: 1536,
      },
    },
  });
  const laptopUI = useMediaQuery(theme.breakpoints.up('lg'));

  const handleDateChange = (date: any) => {
    setBirthday(date)
  };
  const handleError = (err: any) => {
    if (axios.isAxiosError(err)) {
      const serverError = err as AxiosError<ServerError>;
      if (serverError && serverError.response) {
        setInitPopup({
          ...initPopup,
          openFlag: true,
          type: "fail",
          content:  serverError.response.data.message,
        });
      }
    }
  };
  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setState({ ...state, [name]: value });
  };

  const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (
      state.full_name == "" ||
      state.password == "" ||
      state.user_name == "" ||
      validateName.invalid ||
      validateuserName.invalid ||
      validatePwd.invalid 
    ) {
      return;
    }

    if(validateEmails.invalid && state.email)
    {
      return;
    }

    if(validatePhones.invalid && state.phone)
    {
      return;
    }

    await app
      .post(configs.auth + "adduser", {
        user_name: state.user_name,
        pwd: state.password,
        full_name: state.full_name,
        role: "admin",
        email: state.email,
        phone: state.phone,
        birth_day: birth_day,
        gender: state.gender,
      })
      .then(async (data: any) => {
        setInitPopup({
          ...initPopup,
          openFlag: true,
          type: "success",
          content: "Thêm mới thành công",
          onPass: () => {
            navigate("/user");
          },
        });
      })
      .catch((err) => {
        if (axios.isAxiosError(err)) {
          const serverError = err as AxiosError<ServerError>;
          if (serverError && serverError.response) {
            setInitPopup({
              ...initPopup,
              openFlag: true,
              type: "fail",
              content:  serverError.response.data.message,
            });
          }
        }
      });
      
  };

  const onBlurNameHandler = (e: any) => {
    if (isEmpty(e.target.value)) {
      setvalidateName({
        invalid: true,
        errors: "Họ và tên không được để trống",
      });
    } else if (!isLetter(e.target.value)) {
      setvalidateName({
        invalid: true,
        errors: "Họ và tên không có số",
      });
    } else if (e.target.value.length > 250) {
      setvalidateName({
        invalid: true,
        errors: "Tên không được vượt qua 250 kí tự",
      });
    } else {
      setvalidateName({ invalid: false, errors: "" });
    }
  };

  const onBlurPwd = (e: any) => {
    if (isEmpty(e.target.value)) {
      setvalidatePwd({
        invalid: true,
        errors: "Mật khẩu không được để trống",
      });
    } else if (!validatePassWord(e.target.value)) {
      setvalidatePwd({
        invalid: true,
        errors: "Mật khẩu sai định dạng",
      });
    } else setvalidatePwd({ invalid: false, errors: "" });
  };

  const onBlurEmail = (e: any) => {
    if (!validateEmail(e.target.value) && !isEmpty(e.target.value)) {
      setvalidateEmail({
        invalid: true,
        errors: "Email sai định dạng",
      });
    } else setvalidateEmail({ invalid: false, errors: "" });
  };
  const onBlurUserNameHandler = (e: any) => {
    if (!validateUserName(e.target.value)) {
      setvalidateUserName({
        invalid: true,
        errors: "Tên tài khoản sai định dạng",
      });
    } else setvalidateUserName({ invalid: false, errors: "" });
  };

  const onBlurPhone = (e: any) => {
    if (!validatePhone(e.target.value) && !isEmpty(e.target.value)) {
      setvalidatePhone({
        invalid: true,
        errors: "Số điện thoại sai định dạng",
      });
    } else setvalidatePhone({ invalid: false, errors: "" });
  };

  const onValidFPwd = (e:any) => {
    (e.target as HTMLInputElement).setCustomValidity(" ");
    if (isEmpty((e.target as HTMLInputElement).value)) {
      setvalidatePwd({
        invalid: true,
        errors: "Mật khẩu không được để trống",
      });
    }
  }
  
  const onInputPwd = (e: any) => {
    (e.target as HTMLInputElement).setCustomValidity("");
    setvalidatePwd({
      invalid: false,
      errors: "",
    });
  };

  const onValidFullName = (e : any) => {
    if (isEmpty((e.target as HTMLInputElement).value)) {
      setvalidateName({
        invalid: true,
        errors: "Họ và tên không được để trống",
      });
    }
    (e.target as HTMLInputElement).setCustomValidity(" ");
  }

  const onInputFullName = (e :any) => {
    (e.target as HTMLInputElement).setCustomValidity("");
    setvalidateName({ invalid: false, errors: "" });
  }

  const onValidUserName = (e : any) => {
    (e.target as HTMLInputElement).setCustomValidity(" ");
    if (isEmpty((e.target as HTMLInputElement).value)) {
      setvalidateUserName({
        invalid: true,
        errors: "Tên Tài khoản không được để trống",
      });
    }
  }

  const onInputUserName = (e :any) => {
    (e.target as HTMLInputElement).setCustomValidity("");
    setvalidateUserName({ invalid: false, errors: "" });
  }

  sessionStorage.setItem('user_searchname_next',sessionStorage.getItem('user_searchname')||"{}");

  return (
    <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginBottom : "auto"
        }}
      >

    <div style={{ width : "90%",marginLeft :(laptopUI ? "4%" : "1%") }}>
      <div>
        <Typography level="h4" component="h1" sx={{ alignSelf: "center", textAlign: 'center' , padding : "2vh"}}>
          Thông tin người dùng mới
        </Typography>
        </div>
        <div>
        <Card
            variant="outlined"
            sx={{ marginLeft : "auto" , width: "100%", display: "flex", alignItems: "center" }}
        >
          <form ref={nameForm} onSubmit={onSubmitHandler} style={{ width: '100%', maxWidth : "800px"}}>
            <Stack
              spacing={2}
              sx={{
                width: '100%',
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <FormControl sx={{ width: '100%' }} required>
                <FormLabel>Họ và tên</FormLabel>
                <Input
                  name="full_name"
                  type="text"
                  className="InputForm"
                  error={validateName.invalid}
                  sx={{ width: "100%" }}
                  onChange={handleChange}
                  value={state.full_name}
                  onBlur={onBlurNameHandler}
                  onInvalid={onValidFullName}
                  onInput={onInputFullName}
                />
                {validateName.invalid ? (
                  <FormHelperText sx={{ color: "red" }}>
                    {validateName.errors}
                  </FormHelperText>
                ) : (
                  <></>
                )}
              </FormControl>
              <FormControl sx={{ width: '100%' }} required>
                <FormLabel>Tài khoản</FormLabel>
                <Input
                  name="user_name"
                  type="text"
                  className="InputForm"
                  error={validateuserName.invalid}
                  sx={{ width: "100%" }}
                  onChange={handleChange}
                  value={state.user_name}
                  onBlur={onBlurUserNameHandler}
                  onInvalid={onValidUserName}
                  onInput={onInputUserName}
                />
                {validateuserName.invalid ? (
                  <FormHelperText sx={{ color: "red" }}>
                    {validateuserName.errors}
                  </FormHelperText>
                ) : (
                  <></>
                )}
              </FormControl>
              <FormControl sx={{ width: '100%' }} required>
                <FormLabel>Mật khẩu</FormLabel>
                <Input
                  name="password"
                  className="InputForm"
                  type={showPwd ? "text" : "password"}
                  sx={{ width: "100%" }}
                  onInvalid={onValidFPwd}
                  onInput={onInputPwd}
                  onChange={handleChange}
                  error={validatePwd.invalid}
                  endDecorator={
                    <VisibilityIcon
                      style={{ cursor: "pointer" }}
                      color={showPwd ? "inherit" : "disabled"}
                      onClick={() => setShowPwd(!showPwd)}
                    />
                  }
                  value={state.password}
                  onBlur={onBlurPwd}
                />
                {validatePwd.invalid ? (
                  <FormHelperText sx={{ color: "red" }}>
                    {validatePwd.errors}
                  </FormHelperText>
                ) : (
                  <></>
                )}
              </FormControl>
              <FormControl sx={{ width: '100%' }}>
                <FormLabel>Email</FormLabel>
                <Input
                  name="email"
                  className="InputForm"
                  type="text"
                  sx={{ width: "100%" }}
                  onChange={handleChange}
                  error={validateEmails.invalid}
                  value={state.email}
                  onBlur={onBlurEmail}
                />
                {validateEmails.invalid ? (
                  <FormHelperText sx={{ color: "red" }}>
                    {validateEmails.errors}
                  </FormHelperText>
                ) : (
                  <></>
                )}
              </FormControl>
              <FormControl sx={{ width: '100%' }}>
                <FormLabel>Số điện thoại</FormLabel>
                <Input
                  name="phone"
                  className="InputForm"
                  type="text"
                  sx={{ width: "100%" }}
                  onChange={handleChange}
                  error={validatePhones.invalid}
                  value={state.phone}
                  onBlur={onBlurPhone}
                />
                {validatePhones.invalid ? (
                  <FormHelperText sx={{ color: "red" }}>
                    {validatePhones.errors}
                  </FormHelperText>
                ) : (
                  <></>
                )}
              </FormControl>
              <FormControl sx={{ width: '100%' }}>
                <FormLabel>Ngày sinh</FormLabel>
                {/* <Input
                className="InputForm"
                  name="birth_day"
                  type="date"
                  sx={{ width: "100%", pt: "8px" }}
                  onChange={handleChange}
                  value={state.birth_day}
                /> */}
                <DatePicker
                    onChange={handleDateChange}
                    selected={birth_day}
                    customInput={
                      <Input
                        className="InputForm"
                        name="birth_day"
                        sx={{ width: "100%" }}
                        endDecorator={
                          <CalendarTodaySharpIcon fontSize="small" />
                        }
                      />
                    }
                    locale={vi}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="dd/mm/yyyy"
                  />
              </FormControl>
              <FormControl sx={{ width: '100%' }}>
                <FormLabel>Giới tính</FormLabel>
                <Select
                className="InputForm"
                  name="gender"
                  sx={{ width: "100%" }}
                  value={state.gender.toString()}
                  onChange={(e: any, newValue) => {
                    setState({ ...state, gender: Number(newValue) });
                  }}
                >
                  <Option value="1"> Nam</Option>
                  <Option value="2"> Nữ </Option>
                </Select>
              </FormControl>

              <Box sx={{ display: 'flex' }}>
                <Button 
                  onClick={() => {
                      navigate("/user");
                    }} 
                  variant="outlined"
                  color="primary"
                  sx={{ width: "150px", background : "#DDF1FF" , marginRight: '15px'}}>
                    Quay lại
                </Button>
                <Button sx={{ width: "150px" }} type="submit">
                  Tạo mới
                </Button>
                
              </Box>
              <CommonPopupRender {...initPopup} />
            </Stack>
          </form>
        </Card>

      </div>
    </div>
      </Box>
  );
}
