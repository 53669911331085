import { useState, useEffect, useRef } from "react";
import app from "../../common/sendrequest";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffSharpIcon from "@mui/icons-material/VisibilityOffSharp";
import * as React from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Typography,
  Box,
  FormHelperText,
  Stack,
  Option,
  Select,
  Modal,
  ModalClose,
  ModalDialog,
  ModalOverflow,
  CircularProgress,
} from "@mui/joy";
import { isEmpty, validatePassWord } from "../../common/expression";
import configs from "../../config";
import axios, { AxiosError } from "axios";
import CommonPopupRender from "../../common/popuprender";
import { validateEmail } from "../../common/expression";
import { Backdrop, createTheme, useMediaQuery } from "@mui/material";
const initialValidate = { invalid: false, errors: "" };
type ServerError = { message: string };
const initState = {
  email: "",
  password: "",
  emailsetting_id: "",
  host: "",
  port: undefined,
  from_name: "",
  secure: false,
  cc: "",
  from: "",
};
const template_config = [
  {key: "create_tag", content: "MAIL01 - Đăng ký tài khoản mới"},
  {key: "register_pass", content: "MAIL02 - Xác nhận đăng ký dịch vụ thành công"},
  {key: "register_fail", content: "MAIL03 - Hủy đơn đăng ký dịch vụ"},
  {key: "reset_tag", content: "MAIL04 - Khôi phục mật khẩu"},
  {key: "update_confirm", content: "MAIL05 - Thay đổi gói dịch vụ"},
  {key: "update_fail", content: "MAIL06 - Thay đổi gói dịch vụ không thành công"},
  {key: "update_pass", content: "MAIL07 - Thay đổi gói dịch vụ thành công"},
]
export default function InforSetting() {
  const [validateEmails, setvalidateEmail] = useState(initialValidate);
  const [state, setState] = React.useState(initState);
  const [showPwd, setShowPwd] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [template, setTemplate] = React.useState('create_tag');
  const [validatePwd, setvalidatePwd] = React.useState(initialValidate);
  const [validateHost, setvalidateHost] = useState(initialValidate);
  const [validatePort, setvalidatePort] = useState(initialValidate);
  const [validateCC, setvalidateCC] = useState(initialValidate);
  const [validateFrom, setvalidateFrom] = useState(initialValidate);
  const [initPopup, setInitPopup] = useState({
    openFlag: false,
    type: "",
    content: "",
    onPass: () => {},
    onCancel: null,
    setOpenFlag: (flag: boolean) =>
      setInitPopup({ ...initPopup, openFlag: flag }),
  });
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 375,
        md: 900,
        lg: 1280,
        xl: 1536,
      },
    },
  });
  const laptopUI = useMediaQuery(theme.breakpoints.up('lg'));
  
  useEffect(() => {
    getData();
  }, []);
  const getValueSetting = (arr: any, key: string, defaultValue = '')=> {
    if (arr && arr.length > 0) {
      return arr!.find((x: any) => x.type_key == key)!.type_name || defaultValue;
    }
    return defaultValue;
  }
  const getData = async () => {
    await app
      .post(`${configs.api}setting/listall`)
      .then((data: any) => {
        if (data.data) {
          const emaildata = data.data.find((x: any) => x.key_setting == "email");
          setState({
            email: getValueSetting(emaildata.value_setting,"account"),
            password: Buffer.from(getValueSetting(emaildata.value_setting,"password").trim().substring(3), 'base64').toString('ascii'),
            emailsetting_id: emaildata._id ? emaildata._id : "",
            port: getValueSetting(emaildata.value_setting,"port"),
            host: getValueSetting(emaildata.value_setting,"host"),
            from_name: getValueSetting(emaildata.value_setting,"from_name"),
            secure: getValueSetting(emaildata.value_setting,"secure"),
            from: getValueSetting(emaildata.value_setting,"from"),
            cc: getValueSetting(emaildata.value_setting ,"cc"),
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setState(initState);
      });
  };

  const handleError = (err: any) => {
    if (axios.isAxiosError(err)) {
      const serverError = err as AxiosError<ServerError>;
      if (serverError && serverError.response) {
        setInitPopup({
          ...initPopup,
          openFlag: true,
          type: "fail",
          content:  serverError.response.data.message,
        });
      }
    }
  };

  const onBlurPwd = (e: any) => {
    if (isEmpty(e.target.value)) {
      setvalidatePwd({
        invalid: true,
        errors: "Mật khẩu không được để trống",
      });
    } else if (!validatePassWord(e.target.value)) {
      setvalidatePwd({
        invalid: true,
        errors: "Mật khẩu sai định dạng",
      });
    } else setvalidatePwd({ invalid: false, errors: "" });
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (validateEmails.invalid && state.email == "") {
      return;
    }

    if (validatePwd.invalid && state.password == "") {
      return;
    }

    setInitPopup({
      ...initPopup,
      type: "confirmed",
      openFlag: true,
      content: "Bạn có chắc muốn thay đổi cấu hình email không ?",
      onPass: () =>
        app
          .post(`${configs.api}setting/update/${state.emailsetting_id}`, {
            key_setting: 'email',
            value_setting: [
              { type_key: "account", type_name: state.email },
              { type_key: "password", type_name: state.password },
              { type_key: "port", type_name: state.port },
              { type_key: "host", type_name: state.host },
              { type_key: "secure", type_name: state.secure },
              { type_key: "from", type_name: state.from },
              { type_key: "cc", type_name: state.cc },
              { type_key: "from_name", type_name: state.from_name },
            ],
          })
          .then(() => {
            setInitPopup({
              ...initPopup,
              openFlag: true,
              type: "success",
              content: "Thay đổi thông tin thành công!",
            });
            getData();
          })
          .catch((err) => {
            handleError(err);
          }),
    });
  };

  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setState({ ...state, [name]: value });
  };

  const onBlurEmpty = (e: any) => {
    if (isEmpty(e.target.value)) {
      if (e.target.name == "host")
        setvalidateHost({
          invalid: true,
          errors: "Tên miền máy chủ không để trống",
        });
      
      if (e.target.name == "port")
        setvalidatePort({
          invalid: true,
          errors: "Cổng không để trống",
        });
      
    }else{
      if (e.target.name == "host")
        setvalidateHost({ invalid: false, errors: "" });
      
      if (e.target.name == "port")
        setvalidatePort({ invalid: false, errors: "" });
      
    }
  }

  const onBlurAccount = (e:any)=>{
        if(isEmpty(e.target.value))
        {
          setvalidateEmail({
            invalid: true,
            errors: "Tài khoản không để trống",
          });
        }else{
          setvalidateEmail({ invalid: false, errors: "" });
        }
  }

  const onBlurEmail = (e: any) => {
    if (!validateEmail(e.target.value) && !isEmpty(e.target.value)) {

      if (e.target.name == "from")
        setvalidateFrom({
          invalid: true,
          errors: "Email sai định dạng",
        });

      if (e.target.name == "cc")
        setvalidateCC({
          invalid: true,
          errors: "Email sai định dạng",
        });
    } else {

      if (e.target.name == "from")
        setvalidateFrom({ invalid: false, errors: "" });

      if (e.target.name == "cc")
        setvalidateCC({ invalid: false, errors: "" });
    }
  };

  const onOpenPreview = () =>{
    setLoading(true);
    app
          .post(`${configs.api}setting/email_send`, {
            template : template,
            [template_config.find(x=>x.key == template)!.key] : true,
            email: state.email,
          })
          .then((data:any) => {
            setLoading(false);
            setInitPopup({
              ...initPopup,
              openFlag: true,
              type: "success",
              content: `Đã gửi email test đến địa chỉ ${state.email}!`,
            });
          })
          .catch((err) => {
            setLoading(false);
            handleError(err);
          })
  }

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginBottom: "auto",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "90%",
          marginLeft: laptopUI ? "5%" : "3%",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Typography
          level="h3"
          component="h1"
          sx={{ alignSelf: "center", padding: "2vh" }}
        >
          Cấu hình Email
        </Typography>
        <Box
          sx={(theme) => ({
            width: "100%",
            maxWidth: "1200px",
            borderRadius: "lg",
            boxShadow: "sm",
            overflow: "auto",
            border: `1px solid ${theme.vars.palette.divider}`,
            margin: "auto",
            padding: "2% 2%",
          })}
        >
          <form onSubmit={onSubmit} style={{ width: "80%", margin: "auto" }}>
            <Stack
              spacing={2}
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <FormControl sx={{ width: "100%" }} required>
                <FormLabel>Máy chủ</FormLabel>
                <div>
                  <Input
                    name="host"
                    type="text"
                    error={validateHost.invalid}
                    sx={{ width: "100%" }}
                    onChange={handleChange}
                    slotProps={{
                      input: {
                        maxLength: 250,
                      },
                    }}
                    value={state.host}
                    onBlur={onBlurEmpty}
                  />
                  {validateHost.invalid ? (
                    <FormHelperText sx={{ color: "red" }}>
                      {validateHost.errors}
                    </FormHelperText>
                  ) : (
                    <></>
                  )}
                </div>
              </FormControl>
              <FormControl sx={{ width: "100%" }} required>
                <FormLabel>Cổng</FormLabel>
                <div>
                  <Input
                    name="port"
                    type="number"
                    error={validatePort.invalid}
                    sx={{ width: "100%" }}
                    onChange={handleChange}
                    slotProps={{
                      input: {
                        maxLength: 250,
                      },
                    }}
                    value={state.port != undefined ? state.port : ""}
                    onBlur={onBlurEmpty}
                  />
                  {validatePort.invalid ? (
                    <FormHelperText sx={{ color: "red" }}>
                      {validatePort.errors}
                    </FormHelperText>
                  ) : (
                    <></>
                  )}
                </div>
              </FormControl>
              <FormControl sx={{ width: "100%" }} required>
                <FormLabel>Bảo mật</FormLabel>
                <div>
                  <Select
                    name="secure"
                    value={state.secure.toString()}
                    sx={{ minWidth: 200 }}
                    onChange={(e: any, newValue: any) => {
                      setState({
                        ...state,
                        secure: newValue == "true" ? true : false,
                      });
                    }}
                  >
                    <Option value="true">Bật</Option>
                    <Option value="false">Tắt</Option>
                  </Select>
                </div>
              </FormControl>
              <FormControl sx={{ width: "100%" }} required>
                <FormLabel>Tài khoản</FormLabel>
                <div>
                  <Input
                    name="email"
                    type="text"
                    error={validateEmails.invalid}
                    sx={{ width: "100%" }}
                    onChange={handleChange}
                    slotProps={{
                      input: {
                        maxLength: 50,
                      },
                    }}
                    value={state.email}
                    onBlur={onBlurAccount}
                  />
                  {validateEmails.invalid ? (
                    <FormHelperText sx={{ color: "red" }}>
                      {validateEmails.errors}
                    </FormHelperText>
                  ) : (
                    <></>
                  )}
                </div>
              </FormControl>
              <FormControl sx={{ width: "100%" }} required>
                <FormLabel sx={{ alignSelf: "flex-start" }}>Mật khẩu</FormLabel>
                <div>
                  <Input
                    name="password"
                    type={showPwd ? "text" : "password"}
                    sx={{ width: "100%" }}
                    value={state.password}
                    onBlur={onBlurPwd}
                    onChange={handleChange}
                    error={validatePwd.invalid}
                    endDecorator={
                      showPwd ? (
                        <VisibilityIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => setShowPwd(!showPwd)}
                        />
                      ) : (
                        <VisibilityOffSharpIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => setShowPwd(!showPwd)}
                        />
                      )
                    }
                  />
                  {validatePwd.invalid ? (
                    <FormHelperText sx={{ color: "red" }}>
                      {validatePwd.errors}
                    </FormHelperText>
                  ) : (
                    <></>
                  )}
                </div>
              </FormControl>
              <FormControl sx={{ width: "100%" }}>
                <FormLabel>Tên hiển thị Email</FormLabel>
                <div>
                  <Input
                    name="from_name"
                    type="text"
                    sx={{ width: "100%" }}
                    onChange={handleChange}
                    slotProps={{
                      input: {
                        maxLength: 250,
                      },
                    }}
                    value={state.from_name}
                  />
                </div>
              </FormControl>
              <FormControl sx={{ width: "100%" }}>
                <FormLabel>Địa chỉ Email gửi đi</FormLabel>
                <div>
                  <Input
                    name="from"
                    type="text"
                    error={validateFrom.invalid}
                    sx={{ width: "100%" }}
                    onChange={handleChange}
                    slotProps={{
                      input: {
                        maxLength: 250,
                      },
                    }}
                    value={state.from}
                    onBlur={onBlurEmail}
                  />
                  {validateFrom.invalid ? (
                    <FormHelperText sx={{ color: "red" }}>
                      {validateFrom.errors}
                    </FormHelperText>
                  ) : (
                    <></>
                  )}
                </div>
              </FormControl>
              <FormControl sx={{ width: "100%" }}>
                <FormLabel>Địa chỉ Email gửi kèm (cc)</FormLabel>
                <div>
                  <Input
                    name="cc"
                    type="text"
                    error={validateCC.invalid}
                    sx={{ width: "100%" }}
                    onChange={handleChange}
                    slotProps={{
                      input: {
                        maxLength: 250,
                      },
                    }}
                    value={state.cc}
                    onBlur={onBlurEmail}
                  />
                  {validateCC.invalid ? (
                    <FormHelperText sx={{ color: "red" }}>
                      {validateCC.errors}
                    </FormHelperText>
                  ) : (
                    <></>
                  )}
                </div>
              </FormControl>
              <FormControl sx={{ width: "100%" }}>
                <FormLabel>Email Template (Dành cho Test email)</FormLabel>
                <div>
                  <Select
                    value={template}
                    sx={{ minWidth: 200 }}
                    onChange={(e: any, newValue: any) => {
                      setTemplate(newValue)
                    }}
                  >
                    {template_config.map(x=>(<Option key={x.key} value={x.key}>{x.content}</Option>))}
                  </Select>
                </div>
              </FormControl>
              <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                <Button sx={{ width: "100px",marginRight: "15px" }} type="submit">
                  Cập nhật
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{
                    width: "150px",
                    background: "#DDF1FF",
                  }}
                  onClick={onOpenPreview}
                >
                  Test email
                </Button>
              </Box>
            </Stack>
          </form>
        </Box>

        <CommonPopupRender {...initPopup} />
        <Backdrop
          sx={{
            background: "rgba(245, 245, 245, 0.6)",
            zIndex: 10000,
            backdropFilter: "blur(5px)",
          }}
          open={loading}
          transitionDuration={{
            appear: 1,
            exit: 1,
          }}
        >
          <CircularProgress thickness={4} size="lg" variant="plain" />
        </Backdrop>
      </div>
    </Box>
  );
}
