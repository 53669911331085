import * as React from "react";
import { useState, useEffect } from "react";
import Table from "@mui/joy/Table";
import app from "../../common/sendrequest";
import CommonPopupRender from "../../common/popuprender";
import SearchIcon from "@mui/icons-material/Search";
import { Admin } from "../auth/User.type";
import LockResetIcon from "@mui/icons-material/LockReset";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Pagination from "@mui/material/Pagination";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Typography,
  Sheet,
  Button,
  Modal,
  ModalClose,
  ModalDialog,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Divider,
  Box,
  styled,
  Tooltip,
  IconButton,
} from "@mui/joy";
import { useNavigate } from "react-router-dom";
import { ButtonGroup, Button as MButoon } from "@mui/material";
import axios, { AxiosError } from "axios";
import configs from "../../config";
import './ListAllUser.css';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { convertDate } from "../../common/expression";
import { DisplayDate } from "../component/DisplayDate";
type ServerError = { message: string };

export default function ListUser() {
  

  const sessiondata = JSON.parse(sessionStorage.getItem('user_searchname_next') || '{"searchName":"","currentpage":1}')
  const [rows, setRow] = useState<Admin[]>([]);
  const [popup, setPopUp] = useState<JSX.Element>(<></>);
  const [open, setOpen] = useState<boolean>(false);
  const [searchClick, setsearchClick] = useState(false);
  const [currentpage, setCurrentPage] = useState(sessiondata!.currentpage);
  const [searchName, setName] = useState(sessiondata!.searchName);
  const [searchNameRef, setNameRef] = useState(sessiondata!.searchName);
  const [page, setPage] = useState(0);
  const [initPopup, setInitPopup] = useState({
    openFlag: false,
    type: "",
    content: "",
    onPass: () => {},
    onCancel: null,
    setOpenFlag: (flag: boolean) =>
      setInitPopup({ ...initPopup, openFlag: flag }),
  });

  let navigate = useNavigate();

  const PopupRender = ({ children }: any) => {
    return (
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog
          aria-labelledby="basic-modal-dialog-title"
          aria-describedby="basic-modal-dialog-description"
          sx={{ width: "20vw" }}
          size="md"
        >
          <ModalClose />
          {children}
        </ModalDialog>
      </Modal>
    );
  };
  
  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };
  const handleError = (err: any) => {
    if (axios.isAxiosError(err)) {
      const serverError = err as AxiosError<ServerError>;
      if (serverError && serverError.response) {
        setInitPopup({
          ...initPopup,
          openFlag: true,
          type: "fail",
          content:  serverError.response.data.message,
        });
      }
    }
  };
  const deleteCustomer = (id: string) => {
    setInitPopup({
      ...initPopup,
      type: "confirmed",
      openFlag: true,
      content: "Bạn có chắc muốn xóa tài khoản này không ?",
      onPass: () =>
        app
          .post(configs.api + "auth/delete/" + id)
          .then(() => {
            setInitPopup({
              ...initPopup,
              openFlag: true,
              type: "success",
              content: "Xóa tài khoản thành công!",
            });
            getData();
          })
          .catch((err) => {
            handleError(err);
          }),
    });
  };

  const handleResetPwd = async (event : any, id : any) =>
  {
    event.preventDefault();
    const passwordOnChange = (
      event.currentTarget.elements.namedItem(
        "new_password"
      ) as HTMLInputElement
    ).value;
    await app
      .post(configs.api + "auth/reset_userpassword", {
        id,
        new_password: passwordOnChange,
      })
      .then(async (data: any) => {
        if (data.data) {
          setInitPopup({
            ...initPopup,
            openFlag: true,
            type: "success",
            content: "Thay đổi mật khẩu thành công",
            onPass : ()=>{navigate('/user');}
          });
        }
      })
      .catch((err) => {
        handleError(err);
      });
  }
  const resetPwd = (id: string) => {
    setOpen(true);
    setPopUp(
      <>
        <Typography component="h3" id="basic-modal-dialog-title">
          Thay đổi mật khẩu tài khoản
        </Typography>
        <Divider />
        <form
          onSubmit={(event: React.FormEvent<HTMLFormElement>) => handleResetPwd(event,id)}
        >
          <Stack spacing={2}>
            <FormControl>
              <FormLabel>Mật khẩu mới</FormLabel>
              <Input
                autoFocus
                required
                name="new_password"
                defaultValue= {configs.default_pwd}
              />
            </FormControl>

            <Button type="submit">Xác nhận</Button>
          </Stack>
        </form>
      </>
    );
  };

  
  useEffect(() => {
    getData();
    sessionStorage.setItem('user_searchname',JSON.stringify({searchName,currentpage}));
    sessionStorage.removeItem('user_searchname_next');
  }, [searchClick, currentpage]);

 
  const getData = () => {
    try {
      app
        .post(configs.api + "auth/alluser", {
          searchstring: searchName,
          rowsPerpage: 10,
          currentPage: currentpage - 1,
        })
        .then((data: any) => {
          setPage(data.data.total);
          setRow(data.data.original);
        })
        .catch((err: Error) => console.log(err));
    } catch (err) {
      setPage(0);
      setRow([]);
    }
  };


  return (
    <Box
      sx={{
        marginBottom: "auto",
        marginLeft:"auto",
        marginRight:{xl:"3vw", xs:"auto"},
        width: "95%"
      }}
    >
      <div
        className="coverboxs"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "flex-start",
          margin: "auto",
        }}
      >
      <Typography level="h3" component="h1" sx={{ alignSelf: "center", padding : "2vh" }}>
        Danh sách người dùng
      </Typography>
      <Box
      className = "RootBoxs"
      sx={{
        display: "flex",
        // padding: "12px",
        width: "100%",
        flexDirection: {xs: "column", lg: "row" },
        justifyContent: "space-between",
      }}
      >
          <div className="FindBox" style={{ display: "flex" }}>
          <FormControl sx={{ margin: "3px", width: "100%" }} size="md">
              <FormLabel>Từ khóa tìm kiếm 
                <Tooltip
                  title="Họ tên, Số điện thoại, Email..."
                  placement="right"
                  size="sm"
                >
                  <InfoOutlinedIcon fontSize="small" />
                </Tooltip></FormLabel>
              <Input
                placeholder="Nhập nội dung tìm kiếm"
                defaultValue={searchNameRef}
                sx={{ minWidth: 350 }}
                size="sm"
                onChange={(e) => {
                  setName((e.target as HTMLInputElement).value);
                }}
                onKeyDown={(e) => {
                  if(e.key === "Enter")
                  {
                    setName((e.target as HTMLInputElement).value);
                    setCurrentPage(1);
                    setsearchClick(!searchClick);
                  }
                }}
                startDecorator={<SearchIcon />}
              />
            </FormControl>
            <Box sx={{ margin: "3px", width: "100%" }}>
              <div style={{ height:"25px"}}></div>
              <Button size="sm" sx={{
                height: "2.3vh",
                width:{
                  xs: "100%",
                  md: "120px"
                }
              }} startDecorator={<SearchIcon />} onClick={()=>setsearchClick(!searchClick)}>Tìm kiếm</Button>
            </Box>
          </div>
          <div className="ButtonDiv" style={{ display: "flex" }}>
            {/* <FormControl sx={{ margin: "5px", width: "8vw" }} size="sm">
              <div style={{ height: "2.3vh" }} />
              <Button
                size="sm"
                style={{ width: "7vw" }}
                color="success"
                disabled={page <= 0}
                startDecorator={<DownloadIcon />}
                onClick={() => exportExcel()}
              >
                Xuất Excel
              </Button>
            </FormControl> */}
            <FormControl sx={{ margin: "5px" }} size="sm">
              <div style={{ height: "2.3vh" }} />
              <Button
                size="sm"
                // style={{ width: "7vw" }}
                startDecorator={<AddIcon />}
                onClick={() => navigate("/user/addnew")}
              >
                Thêm mới
              </Button>
            </FormControl>
          </div>
      </Box>
      <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
          }}
        >
          <Typography
            sx={{
              textAlign: "end",
              pr: "10px",
            }}
          >
            Tổng số bản ghi : {page}
          </Typography>
        </div>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          width: "100%",
          borderRadius: "md",
          flex: 1,
          overflow: "auto",
          minHeight: "63vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        
        {rows.length > 0 ? (
            <div className="DivTable" style={{ width : "100%" ,height : "70vh"}}>
              <Table
                aria-labelledby="tableTitle"
                stickyHeader
                hoverRow
                borderAxis="bothBetween"
                sx={{
                  '& tr > *:last-child': {
                    position: 'sticky',
                    right: 0,
                    bgcolor: 'var(--TableCell-headBackground)',
                  },
                  "& tr > *:first-of-type": { textAlign: "center" },
                  "& tr > *:nth-of-type(5)": { textAlign: "center" },
                  "& tr > *:nth-of-type(6)": { textAlign: "center" },
                  "& th": {
                    color: "#096BDE",
                  },
                }}
                style={{ tableLayout: "auto" }}
              >
                <thead>
                  <tr>
                    <th style={{ width: "4%", textAlign: "center"}}>STT</th>
                    <th style={{ width: "40%",textAlign: "center"}}>Họ và tên</th>
                    <th style={{ width: "20%",textAlign: "center"}}>Tài khoản</th>
                    <th style={{ width: "30%",textAlign: "center" }}>Email</th>
                    <th style={{ width: "20%", textAlign: "center"}}>Điện thoại</th>
                    <th style={{ width: "10%", textAlign: "center"}}>Ngày đăng ký</th>
                    <th style={{ width: "10%", textAlign: "center"}}></th>
                  </tr>
                </thead>
                <tbody>
                  {rows.map((row: Admin, index: number) => (
                    <tr key={row._id}>
                      <td>{(currentpage - 1) * 10 + index + 1}</td>
                      <td>{row.full_name ? row.full_name : <i>Không rõ</i>}</td>
                      <td>{row.user_name}</td>
                      <td>{row.email ? row.email : <i>Không rõ</i>}</td>
                      <td>{row.phone ? row.phone : <i>Không rõ</i>}</td>
                      <td>
                        <DisplayDate value={row.create_at?row.create_at.toString():undefined} />
                      </td>
                      <td>
                        <ButtonGroup variant="text" size="medium">
                          <Tooltip title="Khôi phục mật khẩu">
                            <MButoon onClick={() => resetPwd(row._id)}>
                              <LockResetIcon fontSize="small" />
                            </MButoon>
                          </Tooltip>

                          <Tooltip title="Chỉnh sửa thông tin">
                            <MButoon
                              onClick={() =>
                                navigate(
                                  `/user/${row._id}`
                                )
                              }
                            >
                              <ModeEditIcon fontSize="small" />
                            </MButoon>
                          </Tooltip>

                          <Tooltip title="Xóa tài khoản">
                            <MButoon onClick={() => deleteCustomer(row._id)}>
                              <DeleteIcon fontSize="small" />
                            </MButoon>
                          </Tooltip>
                        </ButtonGroup>
                        
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            
        ) : (
          <div style={{ width : "100%", textAlign: "center", margin : "auto", height: "10vh" }}>
            <Typography level="body-lg">
            Không có kết quả phù hợp !
            </Typography>
          </div>
        )}
        <PopupRender>{popup}</PopupRender>
        <CommonPopupRender {...initPopup} />
      </Sheet>
      <Box sx={{ marginTop: "10px", display: { md: "block", xs: "none" } }}>
          <Pagination
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "auto",
            }}
            showFirstButton
            showLastButton
            count={page > 10 ? Math.ceil(page / 10) : 1}
            size="medium"
            color="primary"
            page={currentpage}
            onChange={handleChangePage}
          />
        </Box>
        <Box
          sx={{
            display: { xs: "flex", md: "none" },
            margin: "auto",
          }}
        >
          <IconButton
            size="sm"
            color="neutral"
            variant="plain"
            disabled={currentpage === 1}
            onClick={() => {setCurrentPage(1); setsearchClick(!searchClick);}}
            sx={{ bgcolor: "background.surface" }}
          >
            <FirstPageIcon />
          </IconButton>
          <IconButton
            size="sm"
            color="neutral"
            variant="plain"
            disabled={currentpage === 1}
            onClick={() => {setCurrentPage(currentpage - 1); setsearchClick(!searchClick);}}
            sx={{ bgcolor: "background.surface" }}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
          <Typography textAlign="center" sx={{ minWidth: 80 }}>
            {`Trang ${currentpage}`}
          </Typography>
          <IconButton
            size="sm"
            color="neutral"
            variant="plain"
            disabled={currentpage == Math.ceil(page / 10) || rows.length == 0}
            onClick={() => {setCurrentPage(currentpage + 1); setsearchClick(!searchClick);}}
            sx={{ bgcolor: "background.surface" }}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
          <IconButton
            size="sm"
            color="neutral"
            variant="plain"
            disabled={currentpage == Math.ceil(page / 10) || rows.length == 0}
            onClick={() => {setCurrentPage(Math.ceil(page / 10)); setsearchClick(!searchClick);}}
            sx={{ bgcolor: "background.surface" }}
          >
            <LastPageIcon />
          </IconButton>
        </Box>
    </div>
      </Box>
  );
}