import {
  Button,
  Typography,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  Option,
  Stack,
  Modal,
  ModalClose,
  ModalDialog,
  IconButton,
  Checkbox,
  Card,
  Box,
  ChipDelete,
  CircularProgress,
  Textarea,
  Radio,
  RadioGroup,
  Chip,
  Link,
} from "@mui/joy";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import VisibilityOffSharpIcon from "@mui/icons-material/VisibilityOffSharp";
import ClearIcon from "@mui/icons-material/Clear";
import VisibilityIcon from "@mui/icons-material/Visibility";
import configs from "../../config";
import "./DetailCustomer.css";
import {
  isLetter,
  isEmpty,
  validateEmail,
  validatePhone,
  validateURL,
  validateHost,
} from "../../common/expression";
import { Package } from "../packages/Package.type";
import app from "../../common/sendrequest";
import { validatePassWord } from "../../common/expression";
import { useEffect, useRef, useState } from "react";
import axios, { AxiosError } from "axios";
import CommonPopupRender from "../../common/popuprender";
import { Backdrop, createTheme, useMediaQuery } from "@mui/material";
import DatePicker from "react-datepicker";
import CalendarTodaySharpIcon from "@mui/icons-material/CalendarTodaySharp";
import { registerLocale } from "react-datepicker";
import { vi } from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css";
const initialValidate = { invalid: false, errors: "" };
type ServerError = { message: string };

const initState = {
  full_name: "",
  family_name: "",
  password: configs.customer_pwd,
  email: "",
  birth_day: undefined,
  gender: 0,
  phone: "",
  package_id: "",
  domain: [] as string[],
};
export default function AddCustomer() {
  let navigate = useNavigate();
  const nameForm = useRef(null);
  const [state, setState] = useState(initState);
  const [initPopup, setInitPopup] = useState({
    openFlag: false,
    type: "",
    content: "",
    onPass: () => {},
    onCancel: () => {},
    setOpenFlag: (flag: boolean) =>
      setInitPopup({ ...initPopup, openFlag: flag }),
  });
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 375,
        md: 900,
        lg: 1280,
        xl: 1536,
      },
    },
  });
  const laptopUI = useMediaQuery(theme.breakpoints.up("lg"));
  const [validateName, setvalidateName] = useState(initialValidate);
  const [pickdomain, setPickDomain] = useState("1");
  const [domaintype, setDomainType] = useState<string | null> ("1");
  const [loading, setLoading] = useState(false);
  const [currentdomain, setCurrentDomain] = useState("");
  const [validateFamilyname, setvalidateFamilyname] = useState(initialValidate);
  const [list_add_domain, setListAddDomain] = useState<string[]>([]);
  const [validateDomain, setvalidateDomain] = useState(initialValidate);
  const [validatePwd, setvalidatePwd] = useState(initialValidate);
  const [validateEmails, setvalidateEmail] = useState(initialValidate);
  const [validatePhones, setvalidatePhone] = useState(initialValidate);
  const [showPwd, setShowPwd] = useState(false);
  const [list_package, setListPack] = useState<Package[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [layout, setLayout] = useState(false);
  const [popup, setPopUp] = useState<JSX.Element>(<></>);

  const packageList = async () => {
    app
      .post(configs.api + "package/list_all")
      .then((response) => {
        setListPack(response.data.original);
        setState({
          ...state,
          ["package_id"]: response.data.original[0]._id,
          // domain: configs.trial_domain,
        });
      })
      .catch((err) => {
        setListPack([]);
        setState(initState);
      });
  };

  useEffect(() => {
    sessionStorage.setItem(
      "cus_searchname_next",
      sessionStorage.getItem("cus_searchname") || "{}"
    );
    sessionStorage.removeItem("pending_searchname_next");
    packageList();
  }, []);

  const handleError = (err: any) => {
    if (axios.isAxiosError(err)) {
      const serverError = err as AxiosError<ServerError>;
      if (serverError && serverError.response) {
        setLoading(false);
        setInitPopup({
          ...initPopup,
          openFlag: true,
          type: "fail",
          content: serverError.response.data.message,
        });
      }
    }
  };

  const PopupRender = ({ children }: any) => {
    return (
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          setLayout(false);
        }}
      >
        <ModalDialog
          aria-labelledby="basic-modal-dialog-title"
          aria-describedby="basic-modal-dialog-description"
          // sx={{ width: "35vw", minWidth: "400px" }}
          size="md"
          layout={layout ? "fullscreen" : "center"}
        >
          <ModalClose />
          {children}
        </ModalDialog>
      </Modal>
    );
  };

  const handleChipdelete = (option: string) => {
    if(option.includes('giaphaso.com'))
    {
      const delete_data = option.replace('.giaphaso.com','').replace('https://','');
      if(delete_data){
        if(list_add_domain.includes(delete_data)){
          setListAddDomain(list_add_domain.filter(x => x != delete_data));
        }
      }
    }
    setState({
      ...state,
      domain: state.domain.filter((entry) => entry !== option),
    });
  };

  const handleChipChange = (event: any, index: number) => {
    const value = event.target.value;
    if (validateURL(value.trim()) && value != "") {
      setState({
        ...state,
        domain: state.domain.map((x, i) => {
          if (i == index) {
            x = value;
          }
          return x;
        }),
      });
    }
  };

  const ChipRender = ({option,index} : {option : any, index: any}) => {
    const [label, setLabel] = useState(option);
    return (
      <Chip
        // level='body-md'
        size="md"
        startDecorator={
          <Link
            sx={{zIndex: 100,}}
            onClick={() => window.open(label, "_blank", "noopener,noreferrer")}
          >
          {label}
          </Link>
        }
        
        sx={{
          pl: 1,
          py: 0.5,
          zIndex: 100,
          maxWidth: "inherit",
          my: "5px",
          borderRadius: "100px",
          justifyContent: "space-between",
          '.MuiChip-startDecorator': {
            pointerEvents: 'auto'
          }
        }}
        variant="soft"
        color="primary"
        endDecorator={
          <ChipDelete
            sx={{ height: "30px", width: "30px" }}
            onDelete={() => handleChipdelete(option)}
          />
        }
      >
        
      </Chip>
    );
  };

  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setState({ ...state, [name]: value });
  };

  const onAddDomain = () => {
    if (
      currentdomain.length > 0 &&
      !state.domain.some((x) => x == currentdomain.trim())
    ) {
      if (!validateURL(currentdomain.trim()) && domaintype != '1') {
        setvalidateDomain({
          invalid: true,
          errors: "Đường dẫn không đúng định dạng",
        });
      } else if (!validateHost(currentdomain.trim()) && domaintype == '1') {
        setvalidateDomain({
          invalid: true,
          errors: "Đường dẫn không đúng định dạng",
        });
      }else {
        setvalidateDomain({
          invalid: false,
          errors: "",
        });
        let new_domain = currentdomain.trim();
        if(domaintype == '1')
        {
          setListAddDomain(list_add_domain.concat(currentdomain.trim()));
          new_domain = `https://${currentdomain.trim()}.giaphaso.com`
        }
        setState({
          ...state,
          domain: Array.from(new Set(state.domain.concat(new_domain))),
        });
        setCurrentDomain("");
      }
    } else {
      setvalidateDomain({
        invalid: true,
        errors: "Đường dẫn trống hoặc đã xuất hiện trong danh sách",
      });
    }
  };

  function PackageChange() {
    const initialValidate = { invalid: false, errors: "" };
    let cycleday = 0;
    let package_cycleday = 0;
    let trial_id: any = null;
    if (list_package.find((x) => x._id == state.package_id)) {
      cycleday =
        list_package.find((x) => x._id == state.package_id)!.cycle_day || 0;
      package_cycleday =
        list_package.find((x) => x._id == state.package_id)!.cycle_day || 0;
    }
    const [validatePercent, setvalidatePercent] = useState(initialValidate);
    const [checked, setChecked] = useState(false);
    const [price, setPrice] = useState(
      list_package.find((x) => x._id == state.package_id)!.price || 0
    );
    const [sale_percent_value, setSalePercent] = useState(0);
    const [note, setNote] = useState("");
    const total = (price * (100 - sale_percent_value)) / 100;
    if (
      list_package.find((x) => x.price == 0 && x.is_public == true) &&
      total > 0
    ) {
      trial_id = list_package.find(
        (x) => x.price == 0 && x.is_public == true
      )!._id;
    }

    const Invalid = (e: any) => {
      if (
        Number((e.target as HTMLInputElement).value) < 0 ||
        Number((e.target as HTMLInputElement).value) > 100 || isNaN(Number((e.target as HTMLInputElement).value))
      ) {
        setvalidatePercent({
          errors: "Vui lòng nhập chiết khấu từ 0 - 100%.",
          invalid: true,
        });
        (e.target as HTMLInputElement).setCustomValidity(" ");
      } else {
        setvalidatePercent(initialValidate);
        (e.target as HTMLInputElement).setCustomValidity("");
      }
    };

    const onBlurPercentChange = (e: any) => {
      if (Number((e.target as HTMLInputElement).value) < 0 || isNaN(Number((e.target as HTMLInputElement).value))) {
        setvalidatePercent({
          errors: "Vui lòng nhập chiết khấu từ 0 - 100%.",
          invalid: true,
        });
      } else {
        setvalidatePercent(initialValidate);
      }
    };

    const onInput = (e: any) => {
      if (Number((e.target as HTMLInputElement).value) < 0 || isNaN(Number((e.target as HTMLInputElement).value))|| (e.target as HTMLInputElement).value == '') {
        (e.target as HTMLInputElement).value = "0";
        (e.target as HTMLInputElement).setCustomValidity(" ");
      } else if (Number((e.target as HTMLInputElement).value) > 100) {
        (e.target as HTMLInputElement).value = "100";
        (e.target as HTMLInputElement).setCustomValidity(" ");
      } else {
        (e.target as HTMLInputElement).value = (e.target as HTMLInputElement).value.replace(/^0+/, '');
        (e.target as HTMLInputElement).setCustomValidity("");
        setvalidatePercent(initialValidate);
      }
    };

    const handleSalePercentChange = (event: any) => {
      const value = event.target.value;
      setSalePercent(value);
    };
    const handleChangeNote = (event: any) => {
      const value = event.target.value;
      setNote(value);
    };
    const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
      if (
        !checked &&
        list_package.find((x) => x.price == 0 && x.is_public == true) &&
        total > 0
      ) {
        cycleday =
          list_package.find((x) => x.price == 0 && x.is_public == true)!
            .cycle_day || 0;
      }
      let domain = [] as string[];
      if(pickdomain == '1')
      {
        domain = configs.trial_domain;
      }

      if(pickdomain == '2')
      {
        domain = state.domain;
      }
      event.preventDefault();
      setLoading(true);
      await app
        .post(configs.customer + "newcustomer", {
          family_name: state.family_name,
          pwd: state.password,
          full_name: state.full_name,
          email: state.email,
          phone: state.phone,
          birth_day: state.birth_day,
          gender: state.gender,
          package_id: state.package_id,
          status: checked ? "COMPLETED" : "PENDING",
          package_name: list_package.find((x) => x._id == state.package_id)!
            .package_name,
          package_cycleday: package_cycleday,
          cycleday,
          trial_id,
          price: total,
          sale_percent_value,
          total_money_before_sale: price,
          domain: domain,
          list_add_domain: pickdomain == '1' ? [] : list_add_domain,
        })
        .then(async (data: any) => {
          setLoading(false);
          setOpen(false);
          setInitPopup({
            ...initPopup,
            openFlag: true,
            type: "success",
            content: "Thêm mới khách hàng thành công",
            onPass: () => {
              navigate("/customer");
            },
          });
        })
        .catch((err) => {
          console.log(err);
          setOpen(false);
          handleError(err);
        });
    };
    return (
      <form onSubmit={onSubmitHandler} style={{ minWidth: "400px" }}>
        <Typography component="h3" id="basic-modal-dialog-title">
          Xác nhận tạo mới dòng họ
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FormControl sx={{ width: "100%" }} required>
            <FormLabel>Số tiền</FormLabel>
            <Input
              name="price"
              className="InputForm"
              value={price
                .toLocaleString("vi-VN", {
                  style: "currency",
                  currency: "VND",
                })
                .replace("₫", "")}
              endDecorator={"VND"}
              slotProps={{
                input: {
                  readOnly: true,
                  min: 0,
                },
              }}
              sx={{ width: "100%" }}
            />
          </FormControl>
          <FormControl sx={{ width: "100%" }} required>
            <FormLabel>Chiết khấu</FormLabel>
            <Input
              name="sale_percent_value"
              className="InputForm"
              type="number"
              value={sale_percent_value}
              error={validatePercent.invalid}
              onInvalid={Invalid}
              onBlur={onBlurPercentChange}
              onInput={onInput}
              endDecorator={"%"}
              slotProps={{
                input: {
                  max: 100,
                  min: 0,
                },
              }}
              onChange={handleSalePercentChange}
              sx={{ width: "100%" }}
            />
            {validatePercent.invalid ? (
              <FormHelperText sx={{ color: "red" }}>
                {validatePercent.errors}
              </FormHelperText>
            ) : (
              <></>
            )}
          </FormControl>
          <FormControl sx={{ width: "100%" }} required>
            <FormLabel>Tổng tiền</FormLabel>
            <Input
              name="sale_percent_value"
              className="InputForm"
              value={total
                .toLocaleString("vi-VN", {
                  style: "currency",
                  currency: "VND",
                })
                .replace("₫", "")}
              endDecorator={"VND"}
              slotProps={{
                input: {
                  readOnly: true,
                },
              }}
              onChange={onBlurPercentChange}
              sx={{ width: "100%" }}
            />
          </FormControl>
          <FormControl sx={{ marginBottom: "10px", width: "100%" }}>
            <FormLabel>Ghi chú:</FormLabel>
            <Textarea
              name="note"
              value={note}
              minRows={4}
              maxRows={4}
              onChange={handleChangeNote}
            />
          </FormControl>
          <FormControl sx={{ marginBottom: "10px", width: "100%" }}>
            {total > 0 ? (
              <Checkbox
                size="md"
                label="Tự động hoàn thành đơn hàng"
                onChange={(e) => setChecked(e.target.checked)}
              />
            ) : (
              <></>
            )}
          </FormControl>
          <Button type="submit">Xác nhận</Button>
        </div>
      </form>
    );
  }
  const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let cycleday = 0;
    let price = 0;
    let trial_id = "";
    let domain = [] as string[];

    if (list_package.find((x) => x._id == state.package_id)) {
      cycleday =
        list_package.find((x) => x._id == state.package_id)!.cycle_day || 0;
    }

    if (list_package.find((x) => x._id == state.package_id)) {
      price = list_package.find((x) => x._id == state.package_id)!.price || 0;
    }

    if (list_package.find((x) => x.price == 0 && x.is_public == true)) {
      trial_id = list_package.find(
        (x) => x.price == 0 && x.is_public == true
      )!._id;
    }

    if (
      validateName.invalid ||
      validateEmails.invalid ||
      validateFamilyname.invalid ||
      validatePwd.invalid ||
      validatePhones.invalid
    ) {
      return;
    }

    if (
      state.full_name == "" ||
      state.family_name == "" ||
      state.password == "" ||
      state.email == "" ||
      state.phone == ""
    ) {
      return;
    }

    if (!list_package.some((x) => x._id == state.package_id)) {
      setInitPopup({
        ...initPopup,
        openFlag: true,
        type: "fail",
        content: "Vui lòng chọn gói dịch vụ chính",
      });
      return;
    }

    if (state.domain.length == 0 && pickdomain == '2') {
      setInitPopup({
        ...initPopup,
        openFlag: true,
        type: "fail",
        content: "Vui lòng thêm ít nhất một domain",
      });
      return;
    }

    if(pickdomain == '1')
    {
      domain = configs.trial_domain;
    }

    if(pickdomain == '2')
    {
      domain = state.domain;
    }

    // setLoading(true);
    if (price > 0) {
      setOpen(true);
      setPopUp(<PackageChange />);
      return;
    }
    await app
      .post(configs.customer + "newcustomer", {
        family_name: state.family_name,
        pwd: state.password,
        full_name: state.full_name,
        email: state.email,
        phone: state.phone,
        birth_day: state.birth_day,
        gender: state.gender,
        package_id: state.package_id,
        list_add_domain,
        trial_id,
        package_name: list_package.find((x) => x._id == state.package_id)!
          .package_name,
        cycleday,
        price,
        domain: domain,
      })
      .then(async (data: any) => {
        setLoading(false);
        setInitPopup({
          ...initPopup,
          openFlag: true,
          type: "success",
          content: "Thêm mới khách hàng thành công",
          onPass: () => {
            navigate("/customer");
          },
        });
      })
      .catch((err) => {
        console.log(err);
        handleError(err);
      });
  };

  const onBlurNameHandler = (e: any) => {
    if (isEmpty(e.target.value)) {
      setvalidateName({
        invalid: true,
        errors: "Họ và tên không được để trống",
      });
    } else {
      setvalidateName({ invalid: false, errors: "" });
    }
  };

  const onBlurFamilyName = (e: any) => {
    if (isEmpty(e.target.value)) {
      setvalidateFamilyname({
        invalid: true,
        errors: "Tên dòng họ không được để trống",
      });
    } else {
      setvalidateFamilyname({ invalid: false, errors: "" });
    }
  };

  const onBlurPwd = (e: any) => {
    if (isEmpty(e.target.value)) {
      setvalidatePwd({
        invalid: true,
        errors: "Mật khẩu không được để trống",
      });
    } else if (!validatePassWord(e.target.value)) {
      setvalidatePwd({
        invalid: true,
        errors: "Mật khẩu sai định dạng",
      });
    } else setvalidatePwd({ invalid: false, errors: "" });
  };

  const onBlurEmail = (e: any) => {
    if (isEmpty(e.target.value)) {
      setvalidateEmail({
        invalid: true,
        errors: "Email không được để trống",
      });
    } else if (!validateEmail(e.target.value)) {
      setvalidateEmail({
        invalid: true,
        errors: "Email sai định dạng",
      });
    } else setvalidateEmail({ invalid: false, errors: "" });
  };

  const onBlurPhone = (e: any) => {
    if (isEmpty(e.target.value)) {
      setvalidatePhone({
        invalid: true,
        errors: "Số điện thoại không được để trống",
      });
    } else if (!validatePhone(e.target.value)) {
      setvalidatePhone({
        invalid: true,
        errors: "Số điện thoại sai định dạng",
      });
    } else setvalidatePhone({ invalid: false, errors: "" });
  };

  const onValidFamilyName = (e: any) => {
    (e.target as HTMLInputElement).setCustomValidity(" ");
    if (isEmpty((e.target as HTMLInputElement).value)) {
      setvalidateFamilyname({
        invalid: true,
        errors: "Tên dòng họ không được để trống",
      });
    }
  };

  const onInputFamilyName = (e: any) => {
    (e.target as HTMLInputElement).setCustomValidity("");
    setvalidateFamilyname({ invalid: false, errors: "" });
  };

  const onValidFPwd = (e: any) => {
    (e.target as HTMLInputElement).setCustomValidity(" ");
    if (isEmpty((e.target as HTMLInputElement).value)) {
      setvalidatePwd({
        invalid: true,
        errors: "Mật khẩu không được để trống",
      });
    }
  };

  const onInputPwd = (e: any) => {
    (e.target as HTMLInputElement).setCustomValidity("");
    setvalidatePwd({
      invalid: false,
      errors: "",
    });
  };

  const onValidFullName = (e: any) => {
    if (isEmpty((e.target as HTMLInputElement).value)) {
      setvalidateName({
        invalid: true,
        errors: "Họ và tên không được để trống",
      });
    }
    (e.target as HTMLInputElement).setCustomValidity(" ");
  };

  const onInputFullName = (e: any) => {
    (e.target as HTMLInputElement).setCustomValidity("");
    setvalidateName({ invalid: false, errors: "" });
  };

  const onValidEmail = (e: any) => {
    (e.target as HTMLInputElement).setCustomValidity(" ");
    if (isEmpty((e.target as HTMLInputElement).value)) {
      setvalidateEmail({
        invalid: true,
        errors: "Email không được để trống",
      });
    }
  };
  const onInputEmail = (e: any) => {
    (e.target as HTMLInputElement).setCustomValidity("");
    setvalidateEmail({ invalid: false, errors: "" });
  };

  const onValidPhone = (e: any) => {
    (e.target as HTMLInputElement).setCustomValidity(" ");
    if (isEmpty((e.target as HTMLInputElement).value)) {
      setvalidatePhone({
        invalid: true,
        errors: "Số điện thoại không được để trống",
      });
    }
  };
  const onInputPhone = (e: any) => {
    (e.target as HTMLInputElement).setCustomValidity("");
    setvalidatePhone({ invalid: false, errors: "" });
  };

  const handleDateChange = (date: any) => {
    setState({ ...state, birth_day: date });
  };

  const onChangePackage = (e: any, newValue: any) => {
    setState({
      ...state,
      package_id: newValue || list_package[0]._id,
      // domain:
      //   list_package!.find((x) => x._id == newValue)!.price > 0
      //     ? state.domain.filter((x) => x != configs.trial_domain[0])
      //     : state.domain
      //         .filter((x) => x != configs.trial_domain[0])
      //         .concat(configs.trial_domain),
    });
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginBottom: "auto",
      }}
    >
      <div style={{ width: "90%", marginLeft: laptopUI ? "4%" : "1%" }}>
        <div>
          <Typography
            level="h3"
            component="h1"
            sx={{ alignSelf: "center", textAlign: "center", padding: "1vh" }}
          >
            Thông tin khách hàng mới
          </Typography>
        </div>
        <div>
          <Card
            variant="outlined"
            sx={{
              marginLeft: "auto",
              width: "100%",
              display: "flex",
              alignItems: "center",
              // height: "calc(100vh - 90px)",
            }}
          >
            <form
              ref={nameForm}
              onSubmit={onSubmitHandler}
              style={{ width: "100%", maxWidth: "800px" }}
            >
              <Stack
                spacing={2}
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <FormControl sx={{ width: "100%" }} required>
                  <FormLabel>Họ và tên</FormLabel>
                  <Input
                    name="full_name"
                    className="InputForm"
                    type="text"
                    error={validateName.invalid}
                    sx={{ width: "100%" }}
                    onChange={handleChange}
                    slotProps={{
                      input: {
                        maxLength: 250,
                      },
                    }}
                    value={state.full_name}
                    onBlur={onBlurNameHandler}
                    onInvalid={onValidFullName}
                    onInput={onInputFullName}
                  />
                  {validateName.invalid ? (
                    <FormHelperText sx={{ color: "red" }}>
                      {validateName.errors}
                    </FormHelperText>
                  ) : (
                    <></>
                  )}
                </FormControl>
                <FormControl sx={{ width: "100%" }} required>
                  <FormLabel>Tên dòng họ</FormLabel>
                  <Input
                    name="family_name"
                    className="InputForm"
                    type="text"
                    sx={{ width: "100%" }}
                    slotProps={{
                      input: {
                        maxLength: 250,
                      },
                    }}
                    onChange={handleChange}
                    error={validateFamilyname.invalid}
                    value={state.family_name}
                    onBlur={onBlurFamilyName}
                    onInvalid={onValidFamilyName}
                    onInput={onInputFamilyName}
                  />
                  {validateFamilyname.invalid ? (
                    <FormHelperText sx={{ color: "red" }}>
                      {validateFamilyname.errors}{" "}
                    </FormHelperText>
                  ) : (
                    <></>
                  )}
                </FormControl>
                <FormControl sx={{ width: "100%" }} required>
                  <FormLabel>Mật khẩu</FormLabel>
                  <Input
                    name="password"
                    className="InputForm"
                    type={showPwd ? "text" : "password"}
                    sx={{ width: "100%" }}
                    onInvalid={onValidFPwd}
                    onInput={onInputPwd}
                    onChange={handleChange}
                    error={validatePwd.invalid}
                    endDecorator={
                      showPwd ? (
                        <VisibilityIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => setShowPwd(!showPwd)}
                        />
                      ) : (
                        <VisibilityOffSharpIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => setShowPwd(!showPwd)}
                        />
                      )
                    }
                    value={state.password}
                    onBlur={onBlurPwd}
                  />
                  {validatePwd.invalid ? (
                    <FormHelperText sx={{ color: "red" }}>
                      {validatePwd.errors}
                    </FormHelperText>
                  ) : (
                    <></>
                  )}
                </FormControl>
                <Box
                  sx={{
                    display: { xs: "block", md: "grid" },
                    width: "100%",
                    gridTemplateColumns: "64% 34%",
                    columnGap: "2%",
                  }}
                >
                  <FormControl sx={{ width: "100%", marginBottom:{xs: "15px", md:"0px"} }} required>
                    <FormLabel>Email</FormLabel>
                    <Input
                      name="email"
                      className="InputForm"
                      type="text"
                      sx={{ width: "100%" }}
                      onChange={handleChange}
                      error={validateEmails.invalid}
                      onInvalid={onValidEmail}
                      onInput={onInputEmail}
                      value={state.email}
                      onBlur={onBlurEmail}
                    />
                    {validateEmails.invalid ? (
                      <FormHelperText sx={{ color: "red" }}>
                        {validateEmails.errors}
                      </FormHelperText>
                    ) : (
                      <></>
                    )}
                  </FormControl>
                  <FormControl sx={{ width: "100%" }} required>
                    <FormLabel>Số điện thoại</FormLabel>
                    <Input
                      name="phone"
                      className="InputForm"
                      type="text"
                      sx={{ width: "100%" }}
                      onChange={handleChange}
                      error={validatePhones.invalid}
                      onInvalid={onValidPhone}
                      onInput={onInputPhone}
                      value={state.phone}
                      onBlur={onBlurPhone}
                    />
                    {validatePhones.invalid ? (
                      <FormHelperText sx={{ color: "red" }}>
                        {validatePhones.errors}
                      </FormHelperText>
                    ) : (
                      <></>
                    )}
                  </FormControl>
                </Box>
                <Box
                  sx={{
                    display: { xs: "block", md: "grid" },
                    width: "100%",
                    gridTemplateColumns: "64% 34%",
                    columnGap: "2%",
                  }}
                >
                  <FormControl sx={{ width: "100%", marginBottom:{xs: "15px", md:"0px"} }}>
                    <FormLabel>Ngày sinh</FormLabel>
                    {/* <Input
                      name="birth_day"
                      className="InputForm"
                      type="date"
                      sx={{ width: "100%", pt: "8px" }}
                      onChange={handleChange}
                      value={state.birth_day}
                    /> */}
                    <DatePicker
                      onChange={handleDateChange}
                      selected={state.birth_day}
                      customInput={
                        <Input
                          className="InputForm"
                          name="birth_day"
                          sx={{ width: "100%" }}
                          endDecorator={
                            <CalendarTodaySharpIcon fontSize="small" />
                          }
                        />
                      }
                      locale={vi}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="dd/mm/yyyy"
                    />
                  </FormControl>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel>Giới tính</FormLabel>
                    <Select
                      name="gender"
                      sx={{ width: "100%" }}
                      value={state.gender.toString()}
                      onChange={(e: any, newValue) => {
                        setState({ ...state, gender: Number(newValue) });
                      }}
                    >
                      <Option value="1"> Nam</Option>
                      <Option value="2"> Nữ </Option>
                    </Select>
                  </FormControl>
                </Box>
                <FormControl sx={{ width: "100%" }} required>
                  <FormLabel>Gói dịch vụ chính</FormLabel>
                  <Select
                    name="package_id"
                    className="InputForm"
                    sx={{ width: "100%" }}
                    value={state.package_id}
                    onChange={onChangePackage}
                  >
                    {list_package.map((x) => {
                      return (
                        <Option key={`${x._id}`} value={`${x._id}`}>
                          {x.package_name}
                        </Option>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl sx={{ width: "100%" }} required>
                  <FormLabel>Domain</FormLabel>
                  <RadioGroup
                    defaultValue="outlined"
                    name="radio-buttons-group"
                    value={pickdomain}
                    onChange={(event)=> setPickDomain(event.target.value)}
                  >
                    <Radio
                      color="primary"
                      value="1"
                      label={
                        <FormControl
                          sx={{
                            width: "100%",
                            display: "grid",
                            gridTemplateColumns: {xs : "20% 80%", md: "10% 90%" },
                            columnGap: "2%",
                          }}
                        >
                          <FormLabel>Dùng thử:</FormLabel>
                          <Box sx={{ marginTop: "-4px", display:"flex", flexDirection:"column" }}>
                            {configs.trial_domain.map((x) => (
                              <Typography level="body-md">{x}</Typography>
                            ))}
                          </Box>
                        </FormControl>
                      }
                    />
                    <Radio
                      color="primary"
                      value="2"
                      label={
                        <FormControl sx={{ width: "100%" , zIndex: 100}}>
                          <FormLabel>Danh sách Domain:</FormLabel>
                          <Input
                            name="domain"
                            className="InputForm"
                            placeholder={domaintype == '2' ? "https://domain.com" : 'domain'}
                            type="text"
                            value={currentdomain}
                            sx={{
                              width: "100%",
                              "--Input-decoratorChildHeight": "40px",
                            }}
                            onFocus={()=>setPickDomain("2")}
                            onChange={(event: any) => {
                              setPickDomain("2");
                              const value = event.target.value.toLowerCase();
                              setCurrentDomain(value);
                            }}
                            endDecorator={
                              <Box sx={{ width : "100%", display: "flex", gap: 2}}>
                                <ChipDelete
                                  color="neutral"
                                  variant="plain"
                                  disabled={currentdomain.length > 0 ? false : true}
                                  onClick={() => {
                                    setCurrentDomain("");
                                    setvalidateDomain({ invalid: false, errors: "" });
                                  }}
                                >
                                  <ClearIcon />
                                </ChipDelete>
                                <FormControl sx={{ width: "100%" }}>
                                  <Select
                                    name="gender"
                                    size="sm"
                                    sx={{ zIndex:100, margin:"auto" }}
                                    value={domaintype}
                                    onChange={(e: any, newValue) => {
                                      setDomainType(newValue);
                                    }}
                                  >
                                    <Option value="1"> giaphaso.com </Option>
                                    <Option value="2"> Khác</Option>
                                  </Select>
                                </FormControl>
                                <Button
                                  sx={{
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 0,
                                  }}
                                  onClick={onAddDomain}
                                >
                                  Thêm
                                </Button>
                              </Box>
                            }
                          />
                          {validateDomain.invalid ? (
                            <FormHelperText sx={{ color: "red" }}>
                              {validateDomain.errors}
                            </FormHelperText>
                          ) : (
                            <></>
                          )}
                          {state.domain.length == 0 ? (<></>) : (
                            <div
                              className="selectedTags"
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                marginTop:"5px",
                                width: "100%",
                                flexWrap: "wrap",
                                flexDirection: "column"
                              }}
                            >
                              {state.domain.map((option: any, index: number)=>
                                <ChipRender option={option} index={index} />
                              )}
                            </div>
                          )}
                        </FormControl>
                      }
                    />
                  </RadioGroup>
                  
                </FormControl>
                
                {/* <FormControl sx={{ width: "100%" }}>
                  <FormLabel>Thêm Domain (https://domain.com)</FormLabel>
                  <Input
                    name="domain"
                    className="InputForm"
                    placeholder="https://domain.com"
                    type="text"
                    value={currentdomain}
                    sx={{
                      width: "100%",
                      "--Input-decoratorChildHeight": "40px",
                    }}
                    onChange={(event: any) => {
                      const value = event.target.value;
                      setCurrentDomain(value);
                    }}
                    endDecorator={
                      <Box sx={{ width : "100%", display: "flex", gap: 2}}>
                        <ChipDelete
                          color="neutral"
                          variant="plain"
                          disabled={currentdomain.length > 0 ? false : true}
                          onClick={() => {
                            setCurrentDomain("");
                            setvalidateDomain({ invalid: false, errors: "" });
                          }}
                        >
                          <ClearIcon />
                        </ChipDelete>
                        <Button
                          sx={{
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                          }}
                          onClick={onAddDomain}
                        >
                          Thêm
                        </Button>
                      </Box>
                    }
                  />
                  {validateDomain.invalid ? (
                    <FormHelperText sx={{ color: "red" }}>
                      {validateDomain.errors}
                    </FormHelperText>
                  ) : (
                    <></>
                  )}
                </FormControl>
                <FormControl sx={{ width: "100%" }} required>
                  <FormLabel>Danh sách Domain:</FormLabel>
                </FormControl>
                {state.domain.length == 0 ? (
                  <Typography
                    level="body-lg"
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    <i>Chưa cài đặt domain.</i>
                  </Typography>
                ) : (
                  <div
                    className="selectedTags"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      width: "100%",
                      flexWrap: "wrap",
                    }}
                  >
                    <ChipRender arrDomain={state.domain} />
                  </div>
                )} */}
                <Box sx={{ display: "flex" }}>
                  <Button
                    onClick={() => {
                      navigate("/customer");
                    }}
                    variant="outlined"
                    color="primary"
                    sx={{
                      width: "150px",
                      background: "#DDF1FF",
                      marginRight: "15px",
                    }}
                  >
                    Quay lại
                  </Button>
                  <Button sx={{ width: "150px" }} type="submit">
                    Tạo mới
                  </Button>
                </Box>

                {/* <IconButton
        aria-label="Like"
        variant="plain"
        color="neutral"
        onClick={() => {
          navigate("/customer");
        }}
      >
        <ArrowBackIcon />
      </IconButton> */}
                <CommonPopupRender {...initPopup} />
              </Stack>
            </form>
          </Card>
        </div>
      </div>
      <PopupRender>{popup}</PopupRender>
      <Backdrop
        sx={{
          background: "rgba(245, 245, 245, 0.6)",
          zIndex: 10000,
          backdropFilter: "blur(5px)",
        }}
        open={loading}
        transitionDuration={{
          appear: 1,
          exit: 1,
        }}
      >
        <CircularProgress thickness={4} size="lg" variant="plain" />
      </Backdrop>
    </Box>
  );
}
