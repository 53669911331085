import {
  Avatar,
  Box,
  Button,
  Divider,
  Modal,
  ModalClose,
  ModalDialog,
  IconButton,
  Typography,
  ThemeProvider,
} from "@mui/joy";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import app from "../../common/sendrequest";
import configs from "../../config";
import CommonPopupRender from "../../common/popuprender";
import LogoutIcon from "@mui/icons-material/Logout";
import { createTheme, useMediaQuery } from "@mui/material";

export default function Logout(props: {menu: any}) {
  const {menu} = props;
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 375,
        md: 1000,
        lg: 1280,
        xl: 1630,
      },
    },
  });
  const laptopUI = useMediaQuery(theme.breakpoints.up('lg'));
  const xllaptopUI = useMediaQuery(theme.breakpoints.up('xl'));
  const navigate = useNavigate();
  const [open, setOpen] = React.useState<boolean>(false);
  const [popup, setPopUp] = React.useState<JSX.Element>(<></>);
  const [initPopup, setInitPopup] = React.useState({
    openFlag: false,
    type: "",
    content: "",
    onPass: () => {},
    onCancel: null,
    setOpenFlag: (flag: boolean) =>
      setInitPopup({ ...initPopup, openFlag: flag }),
  });
  const user = JSON.parse(localStorage.getItem("user") || "{}");

  const handlePass = () => {
    app.post(configs.auth + "logout").catch((err) => {
      localStorage.removeItem("user");
      navigate("/login");
    }),
      localStorage.clear();
    sessionStorage.clear();
    navigate("/login");
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Divider />
      <Box
        sx={{
          display: "flex",
          gap: 2,
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: menu || xllaptopUI || !laptopUI ? "row" : "column",
          paddingTop: "20px",
        }}
      >
        
        {(menu || xllaptopUI || !laptopUI) ?
        <Box
          sx={{
            minWidth: 0,
            margin: "auto",
            maxWidth: "200px",
          }}
        >
          <Button
          variant="outlined"
          color="primary"
          sx={{ border: 1 }}
          startDecorator={<LogoutIcon />}
          onClick={() => {
            setInitPopup({
              ...initPopup,
              type: "confirmed",
              openFlag: true,
              content: " Bạn có muốn đăng xuất khỏi hệ thống ?",
              onPass: handlePass,
            });
          }}
          >
            Đăng xuất
          </Button>
        </Box>
        : <>
        <Avatar variant="outlined" color="primary" title={user!.full_name} />
        <IconButton
        variant="outlined"
        color="primary"
        sx={{
          padding: "10px",
          // "&:hover": {
          //   border: "1px solid",
          // },
        }}
        onClick={() => {
          setInitPopup({
            ...initPopup,
            type: "confirmed",
            openFlag: true,
            content: " Bạn có muốn đăng xuất khỏi hệ thống ?",
            onPass: handlePass,
          });
        }}
      >
        <LogoutIcon />
      </IconButton>
        </>}
        
      </Box>
      {/* <Button
          variant="outlined"
          color="primary"
          sx={{ border: 1 }}
          startDecorator={<LogoutIcon />}
          onClick={() => {
            setInitPopup({
              ...initPopup,
              type: "confirmed",
              openFlag: true,
              content: " Bạn có muốn đăng xuất khỏi hệ thống ?",
              onPass: handlePass,
            });
          }}
        >
          Đăng xuất
        </Button> */}
      <CommonPopupRender {...initPopup} />
    </Box>
  );
}
