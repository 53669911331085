import * as React from "react";
import { useState, useEffect } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  Typography,
  Box,
  IconButton,
  Card,
  CardOverflow,
  AspectRatio,
  CardContent,
  CardCover,
  Alert,
  Divider,
} from "@mui/joy";
import { useSearchParams } from "react-router-dom";
import { Snackbar, createTheme, useMediaQuery } from "@mui/material";
import './ListAllOrder.css';
import axios, { AxiosError } from "axios";
import "react-datepicker/dist/react-datepicker.css";
import configs from "../../config";
type ServerError = { message: string };
const initialValidate = { invalid: false, errors: "" };
const initialCount = {
  completed_count: 0,
  canceled_count: 0,
  pending_count: 0,
  count_total: 0,
  pending_total: 0,
};
export default function ListOrderQR() {
  const sessiondata = JSON.parse(
    sessionStorage.getItem("package_searchname_next") ||
      '{"searchName":"","currentpage":1,"activeStatus":"all"}'
  );
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 375,
        md: 900,
        lg: 1280,
        xl: 1536,
      },
    },
  });
  const [searchParam, setSearchParam] = useSearchParams();
  const laptopUI = useMediaQuery(theme.breakpoints.up('md'));
  const tabletpUI = useMediaQuery(theme.breakpoints.up('lg'));
  const [open, setOpen] = useState<boolean>(false);
  const [payment_list, setPaymentList] = useState<any[]>([])
  
  const [initPopup, setInitPopup] = useState({
    openFlag: false,
    type: "",
    content: "",
    onPass: () => {},
    onCancel: () => {},
    setOpenFlag: (flag: boolean) =>
      setInitPopup({ ...initPopup, openFlag: flag }),
  });

  const copyClipboard = (value: string) => {
    navigator.clipboard.writeText(value ? value : "");
    handleClick();
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  useEffect(()=>{
    getData();
  },[]);

  const getData = () => {
    try {
      setPaymentList(configs.payment.map((x: any)=>{
        x['qr_code'] = `${configs.api}order/image-qr?order_alias=&bank_key=${x.bank_key}`;
        return x;
      }))
      // app
      //   .post(`${configs.api}setting/listall`)
      //   .then((data: any) => {
      //     if(data.data)
      //     {
      //       setPage(data.data.total);
      //       setRow(data.data.original);
      //       setcountAtrr({
      //         canceled_count : data.data.canceled_count ? data.data.canceled_count : 0,
      //         completed_count: data.data.completed_count ? data.data.completed_count : 0,
      //         pending_count: data.data.pending_count ? data.data.pending_count : 0,
      //         count_total: data.data.count_total ? data.data.count_total : 0,
      //         pending_total: data.data.pending_total ? data.data.pending_total : 0
      //       })
      //     }
      //   })
      //   .catch((err: Error) => {
      //     setPage(0);
      //     setRow([]);
      //     setcountAtrr(initialCount);
      //   });
    } catch (err) {}
  };

  return (
    <Box
      // sx={{
      //   marginBottom: "auto",
      //   mx: "auto",
      // }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "flex-start",
          margin: "auto",
        }}
      >
        <Typography
          level="h4"
          component="h1"
          sx={{ alignSelf: "center", '--Typography-margin': 'calc(-1 * var(--ModalDialog-titleOffset)) 0 var(--ModalDialog-gap) 0'}}
        >
          Thông tin chuyển khoản
        </Typography>
        <Divider/>
        <Box
          sx={{
            display: "flex",
            padding: "12px",
            width: "fit-content",
            gap: 2,
            margin:"auto",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          {payment_list.length > 0 &&
            payment_list.map((x, i) => (
              <>
                <Card
                  key={i}
                  orientation="horizontal"
                  variant="outlined"
                  sx={{
                    height: '12vh',
                    minHeight:{xs:'150px', md:'120px'},
                    padding: 2,
                    maxWidth: 1000,
                    bgcolor: "initial",
                    boxShadow: "none",
                    "--Card-padding": "0px",
                    '.css-ka6ypg-JoyCardCover-root':{
                      top: 'unset',
                    }
                  }}
                >
                  <CardCover
                    className="gradient-cover"
                    sx={{
                      verticalAlign: 'top',
                      zIndex: 100,
                      width: '98%',
                      height: '20%',
                      inset: 'auto',
                      bottom: 0,
                    }}
                  >
                    <div>
                      <Box
                        sx={{
                          p: 1,
                          display: "flex",
                          alignItems: "center",
                          justifyContent:"space-between",
                          gap: 1.5,
                          width: '100%',
                          flexGrow: 1,
                          alignSelf: "flex-end",
                        }}
                      >
                        <div></div>
                        <IconButton
                          size="sm"
                          variant="plain"
                          sx={{ right: 0}}
                          color="primary"
                          onClick={() => {
                            copyClipboard(`${x.account_address}\r\nSố tài khoản: ${x.account_number}\r\nChủ tài khoản: ${x.account_name}`);
                          }}
                        >
                          <ContentCopyIcon />
                        </IconButton>
                        <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
                          <Alert variant="solid" color="success" sx={{ width: "100%" }}>
                            Đã sao chép
                          </Alert>
                        </Snackbar>
                      </Box>
                    </div>
                  </CardCover>
                  <CardOverflow>
                    <AspectRatio ratio="1" sx={{ width: 90}}>
                      <img
                        style={{zIndex:1000}}
                        src={x.qr_code}
                      />
                    </AspectRatio>
                  </CardOverflow>
                  <CardContent sx={{zIndex: 1000, height: '80%'}}>
                    <Typography level="h4" textColor="primary.plainColor" component='p'>
                      {x.account_address}
                    </Typography>
                    <Typography level="body-md">
                      Số tài khoản: {x.account_number}
                    </Typography>
                    <Typography level="body-md">
                      Chủ tài khoản: {x.account_name}
                    </Typography>
                  </CardContent>
                  
                </Card>
                {/* <div style={{
              height:"100%",
              width:"100%",
              border:"1px solid #f1c638",
              borderRadius:"6px",
            }}>
                <div className="element elementImg">
                    <img src={x.qr_code}/>
                </div>
                <div className="element">
                    <p>
                        <strong>{x.account_address}</strong><br />
                        <strong>Số tài khoản:</strong> {x.account_number}<br />
                        <strong>Chủ tài khoản:</strong> {x.account_name}
                    </p>
                </div>
            </div> */}
              </>
            ))}
        </Box>
      </div>
    </Box>
  );
}


