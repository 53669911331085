import { useState, useEffect, useRef } from "react";
import app from "../../common/sendrequest";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffSharpIcon from "@mui/icons-material/VisibilityOffSharp";
import ClearIcon from '@mui/icons-material/Clear';
import * as React from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Typography,
  Box,
  FormHelperText,
  Stack,
  Option,
  Select,
  Modal,
  ModalClose,
  ModalDialog,
  ModalOverflow,
  Card,
  CardCover,
  CardContent,
  AspectRatio,
  IconButton,
  ChipDelete,
} from "@mui/joy";
import {
  isEmpty,
  validatePassWord,
  validateURL,
} from "../../common/expression";
import configs from "../../config";
import axios, { AxiosError } from "axios";
import CommonPopupRender from "../../common/popuprender";
import { validateEmail } from "../../common/expression";
import { createTheme, useMediaQuery } from "@mui/material";
import { Favorite } from "@mui/icons-material";
const initialValidate = { invalid: false, errors: "" };
type ServerError = { message: string };
const initState = {
  address: "",
  page_link: "",
  support_email: "",
  id: "",
  phone: "",
  company_name: "",
};
const template_config = [
  { key: "create_tag", content: "MAIL01 - Đăng ký tài khoản mới" },
  {
    key: "register_pass",
    content: "MAIL02 - Xác nhận đăng ký dịch vụ thành công",
  },
  { key: "register_fail", content: "MAIL03 - Hủy đơn đăng ký dịch vụ" },
  { key: "reset_tag", content: "MAIL04 - Khôi phục mật khẩu" },
  { key: "update_confirm", content: "MAIL05 - Thay đổi gói dịch vụ" },
  {
    key: "update_fail",
    content: "MAIL06 - Thay đổi gói dịch vụ không thành công",
  },
  { key: "update_pass", content: "MAIL07 - Thay đổi gói dịch vụ thành công" },
];
export default function InforDetailSetting() {
  const [validateEmails, setvalidateEmail] = useState(initialValidate);

  const [state, setState] = React.useState(initState);
  const [file, setFile] = useState("");
  const [open, setOpen] = React.useState(false);
  const [popup, setPopUp] = React.useState(<></>);
  const [validateAddress, setvalidateAddress] = React.useState(initialValidate);
  const [validateLink, setvalidateLink] = useState(initialValidate);
  const [validateSEmail, setvalidateSEmail] = useState(initialValidate);
  const [validatePhone, setvalidatePhone] = useState(initialValidate);
  const [initPopup, setInitPopup] = useState({
    openFlag: false,
    type: "",
    content: "",
    onPass: () => {},
    onCancel: null,
    setOpenFlag: (flag: boolean) =>
      setInitPopup({ ...initPopup, openFlag: flag }),
  });
  const PopupRender = ({ children }: any) => {
    return (
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <ModalOverflow>
          <ModalDialog
            aria-labelledby="basic-modal-dialog-title"
            aria-describedby="basic-modal-dialog-description"
            size="md"
          >
            <ModalClose />
            {children}
          </ModalDialog>
        </ModalOverflow>
      </Modal>
    );
  };
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 375,
        md: 900,
        lg: 1280,
        xl: 1536,
      },
    },
  });
  const laptopUI = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    getData();
  }, []);
  const getValueSetting = (arr: any, key: string, defaultValue = "") => {
    if (arr && arr.length > 0) {
      if (arr!.find((x: any) => x.type_key == key))
        return arr!.find((x: any) => x.type_key == key)!.type_name;
      else return defaultValue;
    }
    return defaultValue;
  };
  const getData = async () => {
    await app
      .post(`${configs.api}setting/listall`)
      .then((data: any) => {
        if (data.data) {
          const emaildata = data.data.find(
            (x: any) => x.key_setting == "infor"
          );
          setState({
            address: getValueSetting(emaildata.value_setting, "address"),
            page_link: getValueSetting(emaildata.value_setting, "page_link"),
            support_email: getValueSetting(
              emaildata.value_setting,
              "support_email"
            ),
            id: emaildata._id ? emaildata._id : "",
            phone: getValueSetting(emaildata.value_setting, "phone"),
            company_name: getValueSetting(
              emaildata.value_setting,
              "company_name"
            ),
          });
          setFile(
            getValueSetting(emaildata.value_setting, "logo").length > 0
              ? `${configs.api}setting/image${getValueSetting(
                  emaildata.value_setting,
                  "logo"
                )}`
              : ""
          );
          // app.get(`${configs.api}setting/image${getValueSetting(emaildata.value_setting,"logo")||""}`)
          // .then((data: any)=>{
          //   setFile(data||"")
          // })
        }
      })
      .catch((err) => {
        console.log(err);
        setState(initState);
      });
  };

  const handleError = (err: any) => {
    if (axios.isAxiosError(err)) {
      const serverError = err as AxiosError<ServerError>;
      if (serverError && serverError.response) {
        setInitPopup({
          ...initPopup,
          openFlag: true,
          type: "fail",
          content: serverError.response.data.message,
        });
      }
    }
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (validateSEmail.invalid && state.support_email == "") {
      return;
    }

    if (
      validateLink.invalid ||
      validateAddress.invalid ||
      validatePhone.invalid
    ) {
      return;
    }
    const arrFilesUpload = (
      document.getElementById("fileUpload") as HTMLInputElement
    ).files;
    const fileUpload = arrFilesUpload && file.length > 0 ? arrFilesUpload[0] : null;

    const formData = new FormData();
    if (fileUpload) {
      formData.append("file_upload", fileUpload);
    }
    formData.append('key_setting', "infor")
    formData.append(
      "value_setting",
      JSON.stringify([
        {
          type_key: "address",
          type_name: state.address,
        },
        {
          type_key: "page_link",
          type_name: state.page_link,
        },
        {
          type_key: "support_email",
          type_name: state.support_email,
        },
        {
          type_key: "phone",
          type_name: state.phone,
        },
        {
          type_key: "company_name",
          type_name: state.company_name,
        },
      ])
    );

    setInitPopup({
      ...initPopup,
      type: "confirmed",
      openFlag: true,
      content: "Bạn có chắc muốn thay đổi cấu hình thông tin không ?",
      onPass: () =>
        app
          .post(`${configs.api}setting/update/${state.id}`, formData, {
            headers: {
              "content-type": "multipart/form-data",
            },
          })
          .then(() => {
            setInitPopup({
              ...initPopup,
              openFlag: true,
              type: "success",
              content: "Thay đổi thông tin thành công!",
            });
            getData();
          })
          .catch((err) => {
            handleError(err);
          }),
    });
  };

  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setState({ ...state, [name]: value });
  };

  const handleFile = (event: any) => {
    if (event!.target!.files[0])
      setFile(URL.createObjectURL(event!.target!.files[0]));
  };

  const onBlurEmpty = (e: any) => {
    if (isEmpty(e.target.value)) {
      if (e.target.name == "address")
        setvalidateAddress({
          invalid: true,
          errors: "Địa chỉ không để trống",
        });

      if (e.target.name == "phone")
        setvalidatePhone({
          invalid: true,
          errors: "Số điện thoại không để trống",
        });
    } else {
      if (e.target.name == "address")
        setvalidateAddress({ invalid: false, errors: "" });

      if (e.target.name == "phone")
        setvalidatePhone({ invalid: false, errors: "" });
    }
  };

  const onBlurLink = (e: any) => {
    if (isEmpty(e.target.value)) {
      setvalidateLink({
        invalid: true,
        errors: "Đường dẫn không để trống",
      });
    } else {
      if (!validateURL(e.target.value)) {
        setvalidateLink({
          invalid: true,
          errors: "Đường dẫn sai định dạng",
        });
      } else {
        setvalidateLink({ invalid: false, errors: "" });
      }
    }
  };

  const onBlurEmail = (e: any) => {
    if (!validateEmail(e.target.value) && isEmpty(e.target.value)) {
      setvalidateSEmail({
        invalid: true,
        errors: "Email sai định dạng",
      });
    } else {
      setvalidateSEmail({ invalid: false, errors: "" });
    }
  };
  const EmailTempalate = () => {
    const [template, setTemplate] = React.useState("create_tag");
    const [child, setChild] = React.useState(<></>);
    useEffect(() => {
      app
        .post(`${configs.api}setting/email_preview`, {
          template: template,
          [template_config.find((x) => x.key == template)!.key]: true,
        })
        .then((data: any) => {
          setChild(
            <div
              style={{ paddingTop: "20px", fontSize: "smaller" }}
              dangerouslySetInnerHTML={{ __html: data.data }}
            ></div>
          );
        })
        .catch((err) => {
          handleError(err);
        });
    }, [template]);
    return (
      <>
        <div style={{ height: "25px" }}></div>
        <FormControl sx={{ width: "100%" }}>
          <FormLabel sx={{ width: "100%" }}>Email Template</FormLabel>
          <div>
            <Select
              value={template}
              sx={{ minWidth: 200 }}
              onChange={(e: any, newValue: any) => {
                setTemplate(newValue);
              }}
            >
              {template_config.map((x) => (
                <Option key={x.key} value={x.key}>
                  {x.content}
                </Option>
              ))}
            </Select>
          </div>
        </FormControl>
        {child}
      </>
    );
  };
  const onOpenPreview = () => {
    setOpen(true);
    setPopUp(<EmailTempalate />);
    // app
    //       .post(`${configs.api}setting/email_preview`, {
    //         template : template,
    //         [template_config.find(x=>x.key == template)!.key] : true,
    //       })
    //       .then((data:any) => {
    //         setOpen(true);
    //         setPopUp(<div style={{paddingTop:"20px", fontSize:"smaller"}} dangerouslySetInnerHTML={{__html : data.data}}></div>);
    //       })
    //       .catch((err) => {
    //         handleError(err);
    //       })
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginBottom: "auto",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "90%",
          marginLeft: laptopUI ? "5%" : "3%",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Typography
          level="h3"
          component="h1"
          sx={{ alignSelf: "center", padding: "2vh" }}
        >
          Cấu hình Thông tin
        </Typography>
        <Box
          sx={(theme) => ({
            width: "100%",
            maxWidth: "1200px",
            borderRadius: "lg",
            boxShadow: "sm",
            overflow: "auto",
            border: `1px solid ${theme.vars.palette.divider}`,
            margin: "auto",
            padding: "2% 2%",
          })}
        >
          <form onSubmit={onSubmit} style={{ width: "80%", margin: "auto" }}>
            <Stack
              spacing={2}
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <FormControl sx={{ width: "100%" }} required>
                <FormLabel>Địa chỉ</FormLabel>
                <div>
                  <Input
                    name="address"
                    type="text"
                    error={validateAddress.invalid}
                    sx={{ width: "100%" }}
                    onChange={handleChange}
                    slotProps={{
                      input: {
                        maxLength: 250,
                      },
                    }}
                    value={state.address}
                    onBlur={onBlurEmpty}
                  />
                  {validateAddress.invalid ? (
                    <FormHelperText sx={{ color: "red" }}>
                      {validateAddress.errors}
                    </FormHelperText>
                  ) : (
                    <></>
                  )}
                </div>
              </FormControl>
              <FormControl sx={{ width: "100%" }} required>
                <FormLabel>Tên công ty</FormLabel>
                <div>
                  <Input
                    name="company_name"
                    sx={{ width: "100%" }}
                    onChange={handleChange}
                    slotProps={{
                      input: {
                        maxLength: 250,
                      },
                    }}
                    value={state.company_name}
                  />
                  {validateLink.invalid ? (
                    <FormHelperText sx={{ color: "red" }}>
                      {validateLink.errors}
                    </FormHelperText>
                  ) : (
                    <></>
                  )}
                </div>
              </FormControl>
              <FormControl sx={{ width: "100%" }} required>
                <FormLabel>Website link</FormLabel>
                <div>
                  <Input
                    name="page_link"
                    error={validateLink.invalid}
                    sx={{ width: "100%" }}
                    onChange={handleChange}
                    slotProps={{
                      input: {
                        maxLength: 250,
                      },
                    }}
                    value={state.page_link}
                    onBlur={onBlurLink}
                  />
                  {validateLink.invalid ? (
                    <FormHelperText sx={{ color: "red" }}>
                      {validateLink.errors}
                    </FormHelperText>
                  ) : (
                    <></>
                  )}
                </div>
              </FormControl>
              <FormControl sx={{ width: "100%" }} required>
                <FormLabel>Email hỗ trợ</FormLabel>
                <div>
                  <Input
                    name="support_email"
                    type="text"
                    error={validateSEmail.invalid}
                    sx={{ width: "100%" }}
                    onChange={handleChange}
                    slotProps={{
                      input: {
                        maxLength: 50,
                      },
                    }}
                    value={state.support_email}
                    onBlur={onBlurEmail}
                  />
                  {validateSEmail.invalid ? (
                    <FormHelperText sx={{ color: "red" }}>
                      {validateSEmail.errors}
                    </FormHelperText>
                  ) : (
                    <></>
                  )}
                </div>
              </FormControl>
              <FormControl sx={{ width: "100%" }}>
                <FormLabel>Số điện thoại</FormLabel>
                <div>
                  <Input
                    name="phone"
                    type="text"
                    sx={{ width: "100%" }}
                    error={validatePhone.invalid}
                    onChange={handleChange}
                    slotProps={{
                      input: {
                        maxLength: 250,
                      },
                    }}
                    value={state.phone}
                    onBlur={onBlurEmpty}
                  />
                </div>
                {validatePhone.invalid ? (
                  <FormHelperText sx={{ color: "red" }}>
                    {validatePhone.errors}
                  </FormHelperText>
                ) : (
                  <></>
                )}
              </FormControl>

              <FormControl sx={{ width: "100%" }}>
                <FormLabel>Logo</FormLabel>
                <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                  <Button
                    variant="outlined"
                    size="sm"
                    sx={{ height: "10px", width: "120px" }}
                    component="label"
                  >
                    Upload File
                    <input
                      id="fileUpload"
                      type="file"
                      hidden
                      accept="image/*"
                      onChange={handleFile}
                    />
                  </Button>
                  <Card
                    sx={{
                      width: 300,
                      bgcolor: "initial",
                      boxShadow: "none",
                      "--Card-padding": "0px",
                    }}
                  >
                    <Box sx={{ position: "relative", marginLeft: "10px", display:(file.length > 0? 'block':'none') }}>
                      <AspectRatio ratio="5/5" sx={{ maxWidth: "180px" }}>
                        <figure>
                          <img
                            src={file}
                            srcSet={file}
                            loading="lazy"
                          />
                        </figure>
                      </AspectRatio>
                      <CardCover
                        className="gradient-cover"
                        sx={{
                          "&:hover, &:focus-within": {
                            opacity: 1,
                          },
                          opacity: 0,
                          maxWidth: "180px",
                          transition: "0.1s ease-in",
                          background:
                            "linear-gradient(0deg, transparent 62%, rgba(0,0,0,0.00345888) 63.94%, rgba(0,0,0,0.014204) 65.89%, rgba(0,0,0,0.0326639) 67.83%, rgba(0,0,0,0.0589645) 69.78%, rgba(0,0,0,0.0927099) 71.72%, rgba(0,0,0,0.132754) 73.67%, rgba(0,0,0,0.177076) 75.61%, rgba(0,0,0,0.222924) 77.56%, rgba(0,0,0,0.267246) 79.5%, rgba(0,0,0,0.30729) 81.44%, rgba(0,0,0,0.341035) 83.39%, rgba(0,0,0,0.367336) 85.33%, rgba(0,0,0,0.385796) 87.28%, rgba(0,0,0,0.396541) 89.22%, rgba(0,0,0,0.4) 91.17%)",
                        }}
                      >
                        <div>
                          <Box
                            sx={{
                              p: 1,
                              display: "flex",
                              alignItems: "center",
                              maxWidth: "180px",
                              gap: 1.5,
                              flexGrow: 1,
                              alignSelf: "flex-start",
                            }}
                          >
                            <IconButton
                              onClick={(e)=>setFile("")}
                              size="sm"
                              variant="solid"
                              color="neutral"
                              sx={{ ml: "auto", bgcolor: "rgba(0 0 0 / 0.2)" }}
                            >
                              <ClearIcon/>
                            </IconButton>
                          </Box>
                        </div>
                      </CardCover>
                    </Box>
                  </Card>
                  {/* <img
                    src={file}
                    style={{ maxWidth: "180px", marginLeft: "10px" }}
                  /> */}
                </Box>
              </FormControl>

              <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                <Button
                  sx={{ width: "100px", marginRight: "15px" }}
                  type="submit"
                >
                  Cập nhật
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{
                    width: "150px",
                    background: "#DDF1FF",
                  }}
                  onClick={onOpenPreview}
                >
                  Xem trước email
                </Button>
              </Box>
            </Stack>
          </form>
        </Box>

        <CommonPopupRender {...initPopup} />
        <PopupRender>{popup}</PopupRender>
      </div>
    </Box>
  );
}
