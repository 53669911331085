import * as React from "react";
import { useState, useEffect } from "react";
import Table from "@mui/joy/Table";
import app from "../../common/sendrequest";
import CommonPopupRender from "../../common/popuprender";
import SearchIcon from "@mui/icons-material/Search";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import CheckIcon from "@mui/icons-material/Check";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DownloadIcon from "@mui/icons-material/Download";
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import Pagination from "@mui/material/Pagination";
import DoDisturbOnOutlinedIcon from "@mui/icons-material/DoDisturbOnOutlined";
import {
  Typography,
  Sheet,
  Button,
  Modal,
  ModalClose,
  ModalDialog,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Divider,
  Box,
  styled,
  Tooltip,
  IconButton,
  Select,
  Option,
  Chip,
  ColorPaletteProp,
  Textarea,
  FormHelperText,
  CircularProgress,
  ChipDelete,
  Card,
  Link,
  ModalOverflow,
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from "@mui/joy";
import {  useNavigate, useSearchParams } from "react-router-dom";
import { Backdrop, ButtonGroup, Button as MButoon, createTheme, useMediaQuery } from "@mui/material";
import './ListAllOrder.css';
import axios, { AxiosError } from "axios";
import configs from "../../config";
import { Order } from "./Order.type";
import { convertDate, isEmpty, validateHost, validateURL } from "../../common/expression";
import moment from "moment";
import { DisplayDate } from "../component/DisplayDate";
import ClearIcon from '@mui/icons-material/Clear';
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import UpdateIcon from '@mui/icons-material/Update';
import { Setting } from "../setting/Setting.type";
import DatePicker from "react-datepicker";
import CalendarTodaySharpIcon from '@mui/icons-material/CalendarTodaySharp';
import { vi } from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css";
import { NumericFormatCustom } from "../../common/currencyrender";
import { Package } from "../packages/Package.type";
type ServerError = { message: string };
const initialValidate = { invalid: false, errors: "" };
const initialCount = {
  completed_count: 0,
  canceled_count: 0,
  pending_count: 0,
  count_total: 0,
  pending_total: 0,
};
export default function ListAllOrder() {
  const sessiondata = JSON.parse(
    sessionStorage.getItem("package_searchname_next") ||
      '{"searchName":"","currentpage":1,"activeStatus":"all"}'
  );
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 375,
        md: 900,
        lg: 1280,
        xl: 1536,
      },
    },
  });
  const [searchParam, setSearchParam] = useSearchParams();
  const laptopUI = useMediaQuery(theme.breakpoints.up('md'));
  const tabletpUI = useMediaQuery(theme.breakpoints.up('lg'));
  const [rows, setRow] = useState<Order[]>([]);
  const [list_type, setListType] = useState<Setting[]>([]);
  const [packages, setPack] = useState('all');
  const [list_package, setListPack] = useState<Package[]>([]);
  const [popup, setPopUp] = useState<JSX.Element>(<></>);
  const [open, setOpen] = useState<boolean>(false);
  const [layout, setLayout] = useState(false);
  const [countAtrr, setcountAtrr] = useState(initialCount);
  const [searchClick, setsearchClick] = useState(false);
  const [currentpage, setCurrentPage] = useState(sessiondata!.currentpage);
  const [searchName, setName] = useState(searchParam.get('searchName')||sessiondata!.searchName);
  const [fromDate, setfromDate] = useState<Date>();
  const [loading, setLoading] = useState(false);
  const [toDate, settoDate] = useState<Date>();
  const [status, setStatus] = useState('all');
  const [page, setPage] = useState(0);
  

  const statusData = [
    {
      key: "PENDING",
      value: "Chờ X.Lý",
      icon: <WarningOutlinedIcon fontSize="small" />,
      color: "danger" as ColorPaletteProp,
    },
    {
      key: "CANCELED",
      value: "Hủy đơn",
      icon: <ClearOutlinedIcon fontSize="small" />,
      color: "neutral" as ColorPaletteProp,
    },
    {
      key: "COMPLETED",
      value: "H.Thành",
      icon: <CheckOutlinedIcon fontSize="small" />,
      color: "success" as ColorPaletteProp,
    },
  ];
  const [initPopup, setInitPopup] = useState({
    openFlag: false,
    type: "",
    content: "",
    onPass: () => {},
    onCancel: () => {},
    setOpenFlag: (flag: boolean) =>
      setInitPopup({ ...initPopup, openFlag: flag }),
  });
  const [tempSearch, setTempSearch] = useState({
    searchName, status, fromDate, toDate, packages,
  })

  const user = JSON.parse(localStorage.getItem('user')||"{}");

  
  const handleSearch = ()=>{
    setTempSearch({searchName, status, fromDate, toDate, packages,}); 
    setCurrentPage(1); 
    setsearchClick(!searchClick);
  }
  const PopupRender = ({ children }: any) => {
    return (
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          setLayout(false);
        }}
      >
        <ModalDialog
          aria-labelledby="basic-modal-dialog-title"
          aria-describedby="basic-modal-dialog-description"
          // sx={{ width: "35vw", minWidth: "400px" }}
          size="md"
          layout={layout ? "fullscreen" : "center"}
        >
          <ModalClose />
          {children}
        </ModalDialog>
      </Modal>
    );
  };
  const onValidReason = (e : any) => {
    (e.target as HTMLInputElement).setCustomValidity(" ");
  }

  const onInputReason = (e :any) => {
    (e.target as HTMLInputElement).setCustomValidity("");
  }
  
  const cancelOrder = (id:string, row: Order)=>{
    const CancelComponet = ()=>{
      const [reason, setReason]= useState(row.cancel_reason);
      const [validateReason, setvalidateReason] = useState(initialValidate);

      const handleChange = (e: any) => {
        setReason(e.target.value);
      };
      const onBlur = (e: any) => {
        if(isEmpty(e.target.value))
        {
          setvalidateReason({invalid: true, errors: "Vui lòng nhập lý do."})
        }else{
          setvalidateReason(initialValidate);
        }
      };
      const handleSubmitCancel = (event: any) =>
      {
        setLoading(true);
        event.preventDefault();
        if(validateReason.invalid)
        {
          return;
        }
        app
        .post(configs.api + "order/updatestatus/"+id, {
          status: 'CANCELED',
          cancel_reason: reason,
          family_name: row.family.family_name, 
          full_name: row.customer.full_name, 
          email: row.customer.email,
          phone: row.customer.phone,
          birth_day: row.customer.birth_day,
          prev_package_id: row.family.package_id,
          gender: row.customer.gender == 1 ? "Nam" : (row.customer.gender == 2 ? "Nữ": ""), 
          package_name: row.package.package_name,
        })
        .then((data: any) => {
          setLoading(false);
          if (data.data) {
            setInitPopup({
              ...initPopup,
              openFlag: true,
              type: "success",
              content: "Hủy đơn thành công",
              onPass: () => {
                setOpen(false);
                getData();
              },
            });
          } else {
            setInitPopup({
              ...initPopup,
              openFlag: true,
              type: "fail",
              content: "Có lỗi xảy ra",
            });
          }
        })
        .catch((err) => {
          handleError(err)
        });
      }
      return (
        <>
          <Typography component="h2" id="basic-modal-dialog-title">
            Xác nhận hủy đơn
          </Typography>
          <Divider />
          
          <Typography level="body-lg" id="basic-modal-dialog-description">Bạn có muốn hủy đơn hàng này không?</Typography>
          <Typography level="body-lg" >Vui lòng điền <b>lý do hủy đơn</b> để xác nhận :</Typography>
          <form
            onSubmit={(event)=>handleSubmitCancel(event)}
          >
            <Stack spacing={2} id="basic-modal-dialog-description">
              <FormControl required>
                <FormLabel>Lý do hủy đơn</FormLabel>
                <Textarea
                  autoFocus
                  required
                  value={reason}
                  error={validateReason.invalid}
                  minRows={4}
                  onChange={handleChange}
                  onInvalid={onValidReason}
                  onInput={onInputReason}
                  onBlur={onBlur}
                />
                {validateReason.invalid ? (
                    <FormHelperText sx={{ color: "red" }}>
                      {validateReason.errors}
                    </FormHelperText>
                  ) : (
                    <></>
                  )}
              </FormControl>
              <Button 
                disabled= {status == 'CANCELED'? true : false}
                type="submit"
              >Xác nhận</Button>
            </Stack>
          </form>
        </>
      )
    }
    setOpen(true);
    setPopUp(
      <CancelComponet/>
    );
  }

  const updateOrder = (id:string, row: Order)=>{
    const UpdateComponet = ()=>{
      const start_day = moment(new Date);
      const end_day = moment(row.family.package_expiration_date);
      const cycle_day = end_day.diff(start_day,'days');
      const init_percent = row.sale_percent_value? row.sale_percent_value.toString():"0";
      const [choice, setChoice]= useState<string|null>('0');
      const [reason, setReason]= useState( row.note);
      const [cancel, setCancel]= useState( row.cancel_reason);
      const [price, setPrice]= useState(row.total_money_before_sale != null? row.total_money_before_sale: row.total);
      const [validatePercent, setvalidatePercent] = useState(initialValidate);
      const [sale_percent, setSalepercent] = useState<string|undefined>(init_percent);

      const Invalid = (e: any) => {
        if (Number((e.target as HTMLInputElement).value) < 0 || Number((e.target as HTMLInputElement).value) > 100) {
          setvalidatePercent({
            errors: "Vui lòng nhập chiết khấu từ 0 - 100%.",
            invalid: true,
          });
          (e.target as HTMLInputElement).setCustomValidity(" ");
        } else {
          setvalidatePercent(initialValidate);
          (e.target as HTMLInputElement).setCustomValidity("");
        }
      };
  
      const onInput = (e: any) => {
        if (Number((e.target as HTMLInputElement).value) < 0 ) {
          (e.target as HTMLInputElement).value = "0";
          (e.target as HTMLInputElement).setCustomValidity(" ");
        }else if((e.target as HTMLInputElement).value.includes("-")){
          (e.target as HTMLInputElement).value = (e.target as HTMLInputElement).value.replace("-","");
        }else if(Number((e.target as HTMLInputElement).value) > 100 && (e.target as HTMLInputElement).name == 'sale_percent'){
          (e.target as HTMLInputElement).value = "100";
          (e.target as HTMLInputElement).setCustomValidity(" ");
        } else{
          (e.target as HTMLInputElement).setCustomValidity("");
          setvalidatePercent(initialValidate);
        }
      };
  
      const handleSalePercentChange = (event: any) => {
        const value = event.target.value;
        setSalepercent(value);
      };
      const handlePriceChange = (event: any) => {
        const value = event.target.value;
        setPrice(value);
      };
      const handleChange = (e: any) => {
        setReason(e.target.value);
      };
      const handleSubmit = (event: any) =>
      {
        event.preventDefault();
        const content = "Bạn có muốn cập nhật thông tin đơn hàng này không ?";
          setInitPopup({
            ...initPopup,
            type: "confirmed",
            openFlag: true,
            content: content,
            onPass: () =>{
              setLoading(true);
              app
              .post(configs.api + "order/updatestatus/"+id, {
                note: reason,
                cancel_reason: cancel,
                init_count: 1,
                sale_percent_value: sale_percent,
                total: ((price)*(100 - Number(sale_percent)))/100,
                total_money_before_sale: price,
              })
              .then((data: any) => {
                setLoading(false);
                if (data.data) {
                  setInitPopup({
                    ...initPopup,
                    openFlag: true,
                    type: "success",
                    content: 'Bạn đã cập nhật thông tin hóa đơn thành công.',
                    onPass: () => {
                      setOpen(false);
                      getData();
                    },
                  });
                } else {
                  setInitPopup({
                    ...initPopup,
                    openFlag: true,
                    type: "fail",
                    content: "Có lỗi xảy ra",
                    onPass: () => {
                      setOpen(false);
                      getData();
                    },
                  });
                }
              })
              .catch((err) => {
                handleError(err)
              });
            },
          });
      }
      
      return (
        <Box minWidth={400} maxWidth={600}>
          <Typography
            component="h3"
            sx={{ textAlign: "center" }}
            id="basic-modal-dialog-title"
          >
            Thông tin hóa đơn {row.order_alias}
          </Typography>
          <Divider />
          <form onSubmit={(event) => handleSubmit(event)}>
            <Table
              borderAxis="none"
            >
              <tbody>
                <tr>
                  <td colSpan={2}>
                    <Box
                      sx={{
                        height: { xl: "70vh", sm: "60vh" },
                        overflow: "auto",
                      }}
                    >
                      <Table
                        borderAxis="none"
                        sx={{
                          "--unstable_TableCell-height": "40px",
                        }}
                      >
                        <tbody>
                          <tr>
                            <th
                              scope="row"
                              style={{ color: "black", width: "30%" }}
                            >
                              Dòng họ:
                            </th>
                            <td>
                              <Input
                                size="sm"
                                slotProps={{
                                  input: {
                                    readOnly: true,
                                  },
                                }}
                                value={
                                  row.family.family_name +
                                  "-" +
                                  row.family.family_alias_name +
                                  row.family.family_alias_number
                                    .toString()
                                    .padStart(4, "0")
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <th scope="row" style={{ color: "black" }}>
                              Họ và tên:
                            </th>
                            <td>
                              <Input
                                size="sm"
                                slotProps={{
                                  input: {
                                    readOnly: true,
                                  },
                                }}
                                value={row.customer.full_name}
                              />
                            </td>
                          </tr>
                          <tr>
                            <th scope="row" style={{ color: "black" }}>
                              Email:
                            </th>
                            <td style={{ wordWrap: "break-word" }}>
                              <Input
                                size="sm"
                                slotProps={{
                                  input: {
                                    readOnly: true,
                                  },
                                }}
                                value={row.customer.email}
                              />
                            </td>
                          </tr>
                          <tr>
                            <th scope="row" style={{ color: "black" }}>
                              Điện thoại:
                            </th>
                            <td>
                              <Input
                                size="sm"
                                type="text"
                                slotProps={{
                                  input: {
                                    readOnly: true,
                                  },
                                }}
                                value={row.customer.phone}
                              />
                            </td>
                          </tr>
                          <tr>
                            <th scope="row" style={{ color: "black" }}>
                              Ngày tạo:
                            </th>
                            <td>
                              <Input
                                size="sm"
                                slotProps={{
                                  input: {
                                    readOnly: true,
                                  },
                                }}
                                value={convertDate(row.create_at)}
                              />
                            </td>
                          </tr>
                          {/* {ty} */}
                          <tr>
                            <th scope="row" style={{ color: "black" }}>
                              Gói dịch vụ
                            </th>
                            <td>
                              <Input
                                size="sm"
                                slotProps={{
                                  input: {
                                    readOnly: true,
                                  },
                                }}
                                value={row.package.package_name}
                              />
                            </td>
                          </tr>
                          <tr>
                            <th scope="row" style={{ color: "black" }}>
                              Chu kì gia hạn:
                            </th>
                            <td>{row.package.cycle_day}</td>
                          </tr>
                          <tr>
                            <th scope="row" style={{ color: "black" }}>
                              Giá dịch vụ (gốc):
                            </th>
                            <td>
                              <Input
                                size="sm"
                                slotProps={{
                                  input: {
                                    readOnly: true,
                                    component: NumericFormatCustom as any,
                                  },
                                }}
                                endDecorator={"VND"}
                                value={row.package.price}
                              />
                            </td>
                          </tr>
                          <tr>
                            <th scope="row" style={{ color: "black" }}>
                              Số tiền thực trả:
                            </th>
                            <td>
                              <Input
                                size="sm"
                                name="total_money_before_sale"
                                onInvalid={Invalid}
                                onInput={onInput}
                                slotProps={{
                                  input: {
                                    min: 0,
                                    maxLength: 20,
                                    component: NumericFormatCustom as any,
                                    readOnly: (row.status == 'CANCELED')
                                  },
                                }}
                                endDecorator={"VND"}
                                value={price}
                                onChange={handlePriceChange}
                              />
                              {/* {row.package.price
                                .toLocaleString("vi-VN", {
                                  style: "currency",
                                  currency: "VND",
                                })
                                .replace("₫", "VND")} */}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row" style={{ color: "black" }}>
                              Chiết khấu:
                            </th>
                            <td>
                              <Input
                                name="sale_percent"
                                value={sale_percent}
                                type="number"
                                size="sm"
                                sx={{ width: "100%" }}
                                onInvalid={Invalid}
                                onInput={onInput}
                                endDecorator={"%"}
                                slotProps={{
                                  input: {
                                    max: 100,
                                    min: 0,
                                    readOnly: (row.status == 'CANCELED')

                                  },
                                }}
                                onChange={handleSalePercentChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={2}>
                              <Stack spacing={2}>
                                <FormControl>
                                  {/* <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  > */}
                                      {row.status == 'CANCELED'?
                                      // <Select
                                      //   sx={{marginBottom:'20px'}}
                                      //   size="sm"
                                      //   onChange={(e: any, newValue)=>{setChoice(newValue); if(newValue == '1'){setReason(row.note)}else {setReason(row.cancel_reason)}}}
                                      //   value={choice}
                                      // >
                                      //   <Option value="1" >Ghi chú:</Option>
                                      //   <Option value="2">Lý do hủy đơn:</Option>
                                      // </Select>
                                      <Tabs 
                                        sx={{
                                          '& .MuiTabPanel-root':{
                                            padding: `var(--Tabs-spacing) 0 0 0`
                                          }
                                        }} 
                                        size="sm" 
                                        aria-label="Basic tabs" 
                                        defaultValue={0} 
                                      >
                                        <TabList>
                                          <Tab>Lý do hủy đơn</Tab>
                                          <Tab>Ghi chú</Tab>
                                        </TabList>
                                        <TabPanel value={0}>
                                          <Textarea
                                            value={cancel}
                                            minRows={3}
                                            onChange={(e)=>setCancel(e.target.value)}
                                          />
                                        </TabPanel>
                                        <TabPanel value={1}>
                                          <Textarea
                                            value={reason}
                                            minRows={3}
                                            onChange={handleChange}
                                          />
                                        </TabPanel>
                                      </Tabs> 
                                      : <>
                                      <FormLabel>Ghi chú:</FormLabel>
                                      <Textarea
                                        value={reason}
                                        minRows={3}
                                        onChange={handleChange}
                                      />
                                      </>}
                                  {/* </Box> */}
                                  
                                </FormControl>
                              </Stack>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Box>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th scope="row" style={{ color: "black" }}>
                    Tổng cộng
                  </th>
                  <td>
                    <b>
                      {((price * (100 - Number(sale_percent))) / 100)
                        .toLocaleString("vi-VN", {
                          style: "currency",
                          currency: "VND",
                        })
                        .replace("₫", "VND")}
                    </b>
                  </td>
                </tr>
              </tfoot>
            </Table>
            <Stack spacing={3} sx={{ padding: "10px" }}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{ position: "absolute", left: 0, marginLeft: "16px" }}
                >
                  <Typography
                    level="body-md"
                    startDecorator={<HistoryOutlinedIcon />}
                  >
                    {" "}
                    Đã thay đổi {row.update_count ? row.update_count : 0} lần.
                  </Typography>
                </div>
                <Button
                  type="submit"
                  sx={{
                    width: "150px",
                  }}
                >
                  Cập nhật
                </Button>
                <div></div>
              </Box>
            </Stack>
          </form>
        </Box>
      );
    }
    setOpen(true);
    setPopUp(
      <UpdateComponet/>
    );
  }

  const completeOrder = (id:string, row: Order)=>{
    const CompleteComponet = ()=>{
      const start_day = moment(new Date);
      const end_day = moment(row.family.package_expiration_date);
      const cycle_day = end_day.diff(start_day,'days');
      const init_percent = row.sale_percent_value? row.sale_percent_value.toString():"0";
      const init_domain = row.family!.domain ? row.family!.domain : (row.customer!.domain ? row.customer!.domain : []);
      const [reason, setReason]= useState(row.note? row.note: "");
      const [cur_pack, setCurPack]= useState({
        package_name: '',
        cycle_day: 0,
        price: 0,
      });
      const [list_add_domain, setListAddDomain] = useState<string[]>([]);
      const [list_delete_domain, setListDeleteDomain] = useState<string[]>([]);
      const [currentStatus , setcurrentStatus] = useState("COMPLETED");
      const [currentdomain, setCurrentDomain] = useState("");
      const [current_domain_list, setCurrentDomainList] = useState<string[]>(init_domain.filter(x=>!configs.trial_domain.includes(x)));
      const [validateDomain, setvalidateDomain] = useState(initialValidate);
      const [domain, setDomain] = useState<string[]>(init_domain.filter(x=>!configs.trial_domain.includes(x)));
      const [validatePercent, setvalidatePercent] = useState(initialValidate);
      const [sale_percent, setSalepercent] = useState<string|undefined>(init_percent);
      const [editTime, setEditTime] = useState(false);
      const [domaintype, setDomainType] = useState<string | null> ("1");
      useEffect(()=>{
        app
        .get(configs.api + "package/" + row.family.package_id)
        .then((response) => { setCurPack({
          package_name: response.data.package_name,
          cycle_day: response.data.cycle_day,
          price: response.data.price
        });})
        .catch((err) => setCurPack({
          package_name: '',
          cycle_day: 0,
          price: 0,
        }));
      },[])
      const ChipRender = ({option,index} : {option : any, index: any}) => {
        const [label, setLabel] = useState(option);
        return (
          <Chip
            // level='body-md'
            size="md"
            startDecorator={
              <Link
                sx={{zIndex: 100,}}
                onClick={() => window.open(label, "_blank", "noopener,noreferrer")}
              >
              {label}
              </Link>
            }
            
            sx={{
              pl: 1,
              py: 0.5,
              zIndex: 100,
              maxWidth: "inherit",
              my: "5px",
              borderRadius: "100px",
              justifyContent: "space-between",
              '.MuiChip-startDecorator': {
                pointerEvents: 'auto'
              }
            }}
            variant="soft"
            color="primary"
            endDecorator={
              <ChipDelete
                sx={{ height: "30px", width: "30px" }}
                onDelete={() => handleChipdelete(option)}
              />
            }
          >
            
          </Chip>
        );
      };
      const handleChipdelete = (option : string)=>{
        if(option.includes('giaphaso.com'))
        {
          const delete_data = option;
          if(delete_data){
            if(current_domain_list.includes(option)){
              setListDeleteDomain(list_delete_domain.concat(delete_data));
            }
            const exits_both = delete_data.replace('.giaphaso.com','').replace('https://','');
            if(list_add_domain.includes(exits_both)){
              setListAddDomain(list_add_domain.filter(x => x != exits_both));
            }
          }
        }
        setDomain(domain.filter((entry) => entry !== option));
      }
    
      const handleChipChange = (event : any, index : number)=>{
        const value = event.target.value;
        if(validateURL(value.trim()) && value != "")
        {
          setDomain(
            domain.map((x, i) => {
              if (i == index) {
                if(x.includes('giaphaso.com'))
                {
                  const delete_data = x.replace('.giaphaso.com','').replace('https://','');
                  if(delete_data){
                    setListDeleteDomain(list_delete_domain.concat(delete_data));
                  }
                }
                if(value.includes('giaphaso.com'))
                {
                  const add_data = value.replace('.giaphaso.com','').replace('https://','');
                  if(add_data){
                    setListAddDomain(list_add_domain.concat(add_data.trim()));
                  }
                }
                x = value.toLowerCase();
              }
              return x;
            }),
          );
        }
      }
      const onAddDomain = () => {
        if (
          currentdomain.length > 0 &&
          !domain.some(
            (x) => x == currentdomain.trim()
          )
        ) {
          if (!validateURL(currentdomain.trim()) && domaintype != "1" || currentdomain.trim().endsWith('giaphaso.com')) {
            setvalidateDomain({
              invalid: true,
              errors: "Đường dẫn không đúng định dạng",
            });
          } else if (!validateHost(currentdomain.trim()) && domaintype == '1') {
            setvalidateDomain({
              invalid: true,
              errors: "Đường dẫn không đúng định dạng",
            });
          } else {
            setvalidateDomain({
              invalid: false,
              errors: "",
            });
            let new_domain = currentdomain.trim();
            if (domaintype == "1") {
              new_domain = `https://${currentdomain.trim()}.giaphaso.com`;
              if(!domain.includes(new_domain)){
              setListAddDomain(list_add_domain.concat(currentdomain.trim()));
              }
            }
            setDomain( Array.from(new Set( domain.filter(x=>!configs.trial_domain.includes(x)).concat(new_domain))));
            setCurrentDomain("");
          }
        } else {
          setvalidateDomain({
            invalid: true,
            errors:
              "Đường dẫn trống hoặc đã xuất hiện trong danh sách",
          });
        }
      }
      const updateExpireDate = ()=>{
        if(sale_percent == "")
        {
          setSalepercent(init_percent)
        }
        setEditTime(false);
      }

      const Invalid = (e: any) => {
        if (Number((e.target as HTMLInputElement).value) < 0 || Number((e.target as HTMLInputElement).value) > 100) {
          setvalidatePercent({
            errors: "Vui lòng nhập chiết khấu từ 0 - 100%.",
            invalid: true,
          });
          (e.target as HTMLInputElement).setCustomValidity(" ");
        } else {
          setvalidatePercent(initialValidate);
          (e.target as HTMLInputElement).setCustomValidity("");
        }
      };
  
      const onBlurPercentChange = (e: any) => {
        if (Number((e.target as HTMLInputElement).value) < 0) {
          setvalidatePercent({
            errors: "Vui lòng nhập chiết khấu từ 0 - 100%.",
            invalid: true,
          });
        } else {
          setvalidatePercent(initialValidate);
        }
      };
  
      const onInput = (e: any) => {
        if (Number((e.target as HTMLInputElement).value) < 0 ) {
          (e.target as HTMLInputElement).value = "0";
          (e.target as HTMLInputElement).setCustomValidity(" ");
        }else if(Number((e.target as HTMLInputElement).value) > 100){
          (e.target as HTMLInputElement).value = "100";
          (e.target as HTMLInputElement).setCustomValidity(" ");
        } else{
          (e.target as HTMLInputElement).setCustomValidity("");
          setvalidatePercent(initialValidate);
        }
      };
  
      const handleSalePercentChange = (event: any) => {
        const value = event.target.value;
        setSalepercent(value);
      };
      const handleChange = (e: any) => {
        setReason(e.target.value);
      };
      const handleSubmit = (event: any) =>
      {
        event.preventDefault();
        if(domain.length <= 0 && currentStatus == 'COMPLETED')
        {
          setInitPopup({
            ...initPopup,
            openFlag: true,
            type: "fail",
            content: "Vui lòng thêm domain cho dòng họ này",
            onPass: () =>{}
          });
        }else{
          const content = (currentStatus == "COMPLETED" ? "Bạn có muốn xác nhận hoàn thành đơn hàng này không ?":"Bạn có muốn cập nhật thông tin đơn hàng này không ?");
          const successContent =
            currentStatus == "COMPLETED"
              ? `Hoàn thành đơn thành công ${
                  list_add_domain.length > 0 || list_delete_domain.length > 0
                    ? ". Vui lòng chờ trong vòng 10 phút để hoàn tất cập nhật domain"
                    : ""
                }`
              : "Cập nhật thông tin đơn thành công";
          setInitPopup({
            ...initPopup,
            type: "confirmed",
            openFlag: true,
            content: content,
            onPass: () =>{
              setLoading(true);
              app
              .post(configs.api + "order/updatestatus/"+id, {
                status: currentStatus,
                note: reason,
                family_name: row.family.family_name, 
                full_name: row.customer.full_name, 
                email: row.customer.email,
                phone: row.customer.phone,
                list_delete_domain: list_delete_domain
                    .filter((x) => !configs.trial_domain.includes(x))
                    .map((x) =>
                      x.replace(".giaphaso.com", "").replace("https://", "")
                    ),
                list_add_domain: currentStatus != 'PENDING' ? list_add_domain.filter((x) => !configs.trial_domain.includes(`https://${x}.giaphaso.com`)) : [],
                birth_day: row.customer.birth_day, 
                gender: row.customer.gender == 1 ? "Nam" : (row.customer.gender == 2 ? "Nữ": ""), 
                sale_percent_value: sale_percent,
                total: (row.total_money_before_sale && row.sale_percent_value > 0 ? row.total_money_before_sale: row.total)*(100 - Number(sale_percent))/100,
                total_money_before_sale: row.total_money_before_sale && row.sale_percent_value > 0 ? row.total_money_before_sale: row.total,
                domain,
                pwd:configs.customer_pwd,
                package_name: row.package.package_name,
              })
              .then((data: any) => {
                setLoading(false);
                if (data.data) {
                  setInitPopup({
                    ...initPopup,
                    openFlag: true,
                    type: "success",
                    content: successContent,
                    onPass: () => {
                      setOpen(false);
                      getData();
                    },
                  });
                } else {
                  setInitPopup({
                    ...initPopup,
                    openFlag: true,
                    type: "fail",
                    content: "Có lỗi xảy ra",
                    onPass: () => {
                      setOpen(false);
                      getData();
                    },
                  });
                }
              })
              .catch((err) => {
                handleError(err);
              });
            },
          });
        }
      }
      return (
        <Box minWidth={400}>
          <Typography component="h3" id="basic-modal-dialog-title">
            Xác nhận hoàn thành đơn hàng {row.order_alias}
          </Typography>
          <Divider />
          <form onSubmit={(event) => handleSubmit(event)}>
            <Table borderAxis="none">
              <tbody>
                <tr>
                  <td colSpan={2}>
                    <Box
                      sx={{
                        height: { xl: "75vh", sm: "60vh" },
                        overflow: "auto",
                      }}
                    >
                      <Table
                        borderAxis="none"
                        sx={{ "--unstable_TableCell-height": "30px" }}
                      >
                        <tbody>
                          <tr>
                            <th scope="row" style={{ color: "black", width: row.type == 'TYPE00002'? '20%' : 'unset'}} >
                              Dòng họ:
                            </th>
                            <td>
                              {row.family.family_name +
                                "-" +
                                row.family.family_alias_name +
                                row.family.family_alias_number
                                  .toString()
                                  .padStart(4, "0")}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row" style={{ color: "black" }}>
                              Họ và tên:
                            </th>
                            <td>{row.customer.full_name}</td>
                          </tr>
                          <tr>
                            <th scope="row" style={{ color: "black" }}>
                              Email:
                            </th>
                            <td style={{ wordWrap: "break-word" }}>
                              {row.customer.email}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row" style={{ color: "black" }}>
                              Điện thoại:
                            </th>
                            <td>{row.customer.phone}</td>
                          </tr>
                          {row.type == 'TYPE00002'?
                          <tr>
                            <td colSpan={2} style={{padding: 0}}>
                              <Table sx={{ width: '100%', "--TableCell-height": "25px"}} borderAxis="both">
                                <tbody>
                                  <tr>
                                    <th scope="row" style={{ color: "black", borderTop: '1px solid var(--TableCell-borderColor)', width: '20%', height: '25px' }}>
                                    </th>
                                    <th scope="row" style={{ color: "black", borderTop: '1px solid var(--TableCell-borderColor)', height: '25px' }}>
                                      Gói dịch vụ hiện tại
                                    </th>
                                    <th scope="row" style={{ color: "black", borderTop: '1px solid var(--TableCell-borderColor)', height: '25px' }}>
                                      Gói dịch vụ mới
                                    </th>
                                  </tr>
                                  <tr>
                                    <th scope="row" style={{ color: "black" }}>
                                      Gói dịch vụ:
                                    </th>
                                    <td>{cur_pack!.package_name}</td>
                                    <td>{row.package.package_name}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row" style={{ color: "black" }}>
                                    Chu kì gia hạn:
                                    </th>
                                    <td>{cur_pack!.cycle_day}</td>
                                    <td>{row.package.cycle_day}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row" style={{ color: "black" }}>
                                      Giá dịch vụ:
                                    </th>
                                    <td>{cur_pack!.price
                                      .toLocaleString("vi-VN", {
                                        style: "currency",
                                        currency: "VND",
                                      })
                                      .replace("₫", "VND")}
                                    </td>
                                    <td>{row.package.price
                                      .toLocaleString("vi-VN", {
                                        style: "currency",
                                        currency: "VND",
                                      })
                                      .replace("₫", "VND")}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row" style={{ color: "black" }}>
                                      Ngày bắt đầu:
                                    </th>
                                    <td>
                                      {moment(new Date(row.family.package_start_date))
                                        .utcOffset("+07")
                                        .format("DD/MM/YYYY H:mm")}
                                    </td>
                                    <td>
                                      {moment()
                                        .utcOffset("+07")
                                        .format("DD/MM/YYYY H:mm")}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row" style={{ color: "black" }}>
                                      Ngày hết hạn:
                                    </th>
                                    <td>
                                      {moment(new Date(row.family.package_expiration_date))
                                        .utcOffset("+07")
                                        .format("DD/MM/YYYY H:mm")}
                                    </td>
                                    <td>
                                      {moment()
                                        .add(row.package.cycle_day, "days")
                                        .utcOffset("+07")
                                        .endOf("day")
                                        .format("DD/MM/YYYY H:mm")}
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </td>
                          </tr>
                          : 
                          <>
                          <tr>
                            <th scope="row" style={{ color: "black" }}>
                              Gói dịch vụ
                            </th>
                            <td>{row.package.package_name}</td>
                          </tr>
                          <tr>
                            <th scope="row" style={{ color: "black" }}>
                              Chu kì gia hạn:
                            </th>
                            <td>{row.package.cycle_day}</td>
                          </tr>
                          <tr>
                            <th scope="row" style={{ color: "black" }}>
                              Số ngày sử dụng:
                            </th>
                            <td>{row.package.package_type == 'TYPE00002' ? cycle_day :row.package.cycle_day}</td>
                          </tr>
                          <tr>
                            <th scope="row" style={{ color: "black" }}>
                              Giá dịch vụ:
                            </th>
                            <td>
                              {row.package.price
                                .toLocaleString("vi-VN", {
                                  style: "currency",
                                  currency: "VND",
                                })
                                .replace("₫", "VND")}
                            </td>
                          </tr>
                          
                          </>
                          }
                          
                          <tr>
                            <th scope="row" style={{ color: "black" }}>
                            Số tiền thực trả:
                            </th>
                            <td>
                              {(row.total_money_before_sale && row.sale_percent_value > 0 ? row.total_money_before_sale: row.total)
                                .toLocaleString("vi-VN", {
                                  style: "currency",
                                  currency: "VND",
                                })
                                .replace("₫", "VND")}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row" style={{ color: "black" }}>
                              Chiết khấu:
                            </th>
                            <td>
                              <div style={{width:"100%", display:"flex", justifyContent:"space-between"}}>
                              {!editTime ? (
                                sale_percent + "%"
                              ) : (
                                <Input
                                  className="InputForm"
                                  name="birth_day"
                                  value={sale_percent}
                                  type="number"
                                  size="sm"
                                  sx={{ width: "100%" }}
                                  onInvalid={Invalid}
                                  onBlur={onBlurPercentChange}
                                  onInput={onInput}
                                  startDecorator={"%"}
                                  slotProps={{
                                    input: {
                                      max: 100,
                                      min: 0,
                                    },
                                  }}
                                  onChange={handleSalePercentChange}
                                  endDecorator={
                                    <>
                                      <ChipDelete
                                        color="neutral"
                                        variant="plain"
                                        disabled={
                                          sale_percent != "" ? false : true
                                        }
                                        onClick={() => {
                                          setSalepercent("");
                                        }}
                                      >
                                        <ClearIcon />
                                      </ChipDelete>
                                      <IconButton
                                        color="success"
                                        variant="plain"
                                        sx={{
                                          height:
                                            "var(--Chip-deleteSize, 2rem)",
                                          width: "var(--Chip-deleteSize, 2rem)",
                                          borderRadius:
                                            "var(--Chip-deleteRadius, 50%)",
                                          margin: "var(--Chip-deleteMargin",
                                        }}
                                        onClick={updateExpireDate}
                                      >
                                        <CheckIcon />
                                      </IconButton>
                                    </>
                                  }
                                />
                              )}
                              {!editTime ? (
                                <IconButton
                                  variant="plain"
                                  size="sm"
                                  sx={{ marginLeft: "10px" }}
                                  onClick={() => setEditTime(true)}
                                >
                                  <ModeEditIcon color="info" />
                                </IconButton>
                              ) : (
                                <></>
                              )}
                              </div>
                            </td>
                          </tr>
                          {
                            row.type != 'TYPE00002'?
                            <tr>
                            <th scope="row" style={{ color: "black" }}>
                              Ngày hết hạn:
                            </th>
                            <td>
                              {moment()
                                .add(row.package.cycle_day, "days")
                                .utcOffset("+07")
                                .endOf("day")
                                .format("DD/MM/YYYY H:mm")}
                            </td>
                          </tr>:
                          <></>
                          }
                          
                          <tr>
                            <td colSpan={2}>
                              <Stack spacing={3}>
                                <FormControl>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <FormLabel>Ghi chú:</FormLabel>
                                    <Button
                                      startDecorator={
                                        <UpdateIcon fontSize="small" />
                                      }
                                      size="sm"
                                      type="submit"
                                      variant="outlined"
                                      sx={{
                                        width: "120px",
                                        marginBottom: "10px",
                                      }}
                                      onClick={() => {
                                        setcurrentStatus("PENDING");
                                      }}
                                    >
                                      Cập nhật
                                    </Button>
                                  </Box>

                                  <Textarea
                                    value={reason}
                                    minRows={3}
                                    onChange={handleChange}
                                  />
                                </FormControl>
                              </Stack>
                            </td>
                          </tr>
                          <tr>
                            {/* {row.type == 'TYPE00001' ? ( */}
                            <td colSpan={2}>
                              <Stack spacing={1}>
                              <FormControl sx={{ width: "100%" , zIndex: 100}}>
                                    <FormLabel>Danh sách Domain:</FormLabel>
                                    <Input
                                      name="domain"
                                      className="InputForm"
                                      placeholder={domaintype == '2' ? "https://domain.com" : 'domain'}
                                      type="text"
                                      value={currentdomain}
                                      sx={{
                                        width: "100%",
                                        "--Input-decoratorChildHeight": "40px",
                                      }}
                                      onChange={(event: any) => {
                                        const value = event.target.value.toLowerCase();
                                        setCurrentDomain(value);
                                      }}
                                      endDecorator={
                                        <Box sx={{ width : "100%", display: "flex", gap: 2}}>
                                          <ChipDelete
                                            color="neutral"
                                            variant="plain"
                                            disabled={currentdomain.length > 0 ? false : true}
                                            onClick={() => {
                                              setCurrentDomain("");
                                              setvalidateDomain({ invalid: false, errors: "" });
                                            }}
                                          >
                                            <ClearIcon />
                                          </ChipDelete>
                                          <FormControl sx={{ width: "100%" }}>
                                            <Select
                                              name="gender"
                                              size="sm"
                                              sx={{ zIndex:100, margin:"auto" }}
                                              value={domaintype}
                                              onChange={(e: any, newValue) => {
                                                setDomainType(newValue);
                                              }}
                                            >
                                              <Option value="1"> giaphaso.com </Option>
                                              <Option value="2"> Khác</Option>
                                            </Select>
                                          </FormControl>
                                          <Button
                                            sx={{
                                              borderTopLeftRadius: 0,
                                              borderBottomLeftRadius: 0,
                                            }}
                                            onClick={onAddDomain}
                                          >
                                            Thêm
                                          </Button>
                                        </Box>
                                      }
                                    />
                                    {validateDomain.invalid ? (
                                      <FormHelperText sx={{ color: "red" }}>
                                        {validateDomain.errors}
                                      </FormHelperText>
                                    ) : (
                                      <></>
                                    )}
                                    {domain.length == 0 ? (<></>) : (
                                      <div
                                        className="selectedTags"
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-start",
                                          marginTop:"5px",
                                          width: "100%",
                                          flexWrap: "wrap",
                                          flexDirection: "column"
                                        }}
                                      >
                                        {domain.map((option: any, index: number)=>
                                          <ChipRender option={option} index={index} key={index}/>
                                        )}
                                      </div>
                                    )}
                                  </FormControl>
                              </Stack>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Box>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th scope="row" style={{ color: "black" }}>
                    Tổng cộng
                  </th>
                  <td>
                    <b>
                      {((row.total_money_before_sale && row.sale_percent_value > 0 ? row.total_money_before_sale: row.total)*(100 - Number(sale_percent))/100)
                        .toLocaleString("vi-VN", {
                          style: "currency",
                          currency: "VND",
                        })
                        .replace("₫", "VND")}
                    </b>
                  </td>
                </tr>
              </tfoot>
            </Table>
            <Stack spacing={3} sx={{ padding: "10px", margin: "auto", "& >:not(style):not(style)": { margin: "auto"} }}>
                <Button
                  type="submit"
                  sx={{
                    width: "150px",
                    margin: "auto"
                  }}
                >
                  Hoàn thành
                </Button>
            </Stack>
          </form>
        </Box>
      );
    }
    setOpen(true);
    setPopUp(
      <CompleteComponet/>
    );
  }

  const exportExcel = () => {
    setInitPopup({
      ...initPopup,
      type: "confirmed",
      openFlag: true,
      content: "Bạn có muốn tiếp tục tải file xuống không?",
      onPass: () => {
        axios({
          url: configs.api + "order/download_excel",
          method: "post",
          data : {
            searchstring: tempSearch.searchName,
            status: tempSearch.status,
            fromday: tempSearch.fromDate,
            today: tempSearch.toDate,
            package_id: tempSearch.packages,
          },
          responseType: "blob",
          withCredentials: true,
        })
          .then((response) => {
            const headerLine = response.headers["content-disposition"];
            if (headerLine) {
              const filename = headerLine.split(`"`)[1];
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", filename);
              document.body.appendChild(link);
              link.click();
            }
          })
          .catch((error) => {handleError(error)});
      },
    });
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
    setsearchClick(!searchClick);
  };

  const handleError = (err: any) => {
    if (axios.isAxiosError(err)) {
      const serverError = err as AxiosError<ServerError>;
      if (serverError && serverError.response) {
        setLoading(false);
        setInitPopup({
          ...initPopup,
          openFlag: true,
          type: "fail",
          content:  serverError.response.data.message,
        });
      }
    }
  };

  useEffect(()=>{
    getSetting();
  },[]);

  useEffect(() => {
    setLoading(true);
    getData();
    sessionStorage.removeItem('pending_searchname_next');
  }, [searchClick ]);

  const getSetting = () =>{
    try {
      app
        .post(`${configs.api}setting/list`)
        .then((data: any) => {
          if (data.data) {
            const emaildata = data.data.find((x: any) => x.key_setting == "order_type");
            setListType(emaildata.value_setting);
          }
        })
        .catch((err) => {
          console.log(err);
          setListType([]);
        });
        app
        .post(configs.api + "package/list_all", { package_type: "TYPE00001" })
        .then((response) => setListPack(response.data.original.filter((x:any)=>x.price > 0)))
        .catch((err) => setListPack([]));
    } catch (err) {
      setPage(0);
      setRow([]);
    }
  }
  const changeStatus =(type: string)=>{
    setStatus(type);
    setTempSearch({
      ...tempSearch,
      status: type,
    });
    setCurrentPage(1);
    setsearchClick(!searchClick);
  }

  const getData = () => {
    try {
      app
        .post(configs.api + "order/listall", {
          searchstring: tempSearch.searchName,
          status: tempSearch.status,
          fromday: tempSearch.fromDate,
          today: tempSearch.toDate,
          package_id: packages,
          rowsPerpage: 10,
          currentPage: currentpage - 1,
        })
        .then((data: any) => {
          if(data.data)
          {
            setLoading(false);
            setPage(data.data.total);
            setRow(data.data.original);
            setcountAtrr({
              canceled_count : data.data.canceled_count ? data.data.canceled_count : 0,
              completed_count: data.data.completed_count ? data.data.completed_count : 0,
              pending_count: data.data.pending_count ? data.data.pending_count : 0,
              count_total: data.data.count_total ? data.data.count_total : 0,
              pending_total: data.data.pending_total ? data.data.pending_total : 0
            })
          }
        })
        .catch((err: Error) => {
          setLoading(false);
          setPage(0);
          setRow([]);
          setcountAtrr(initialCount);
        });
      } catch (err) {handleError(err)}
  };
  return (
    <Box
      className="root_box"
      sx={{
        marginBottom: "auto",
        mx: "auto",
        width: { lg: "83%", xl: "100%" },
      }}
    >
      <div
        className="coverboxOrder"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "flex-start",
          margin: "auto",
        }}
      >
        <Typography
          level="h3"
          component="h1"
          sx={{ alignSelf: "center", padding: "2vh" }}
        >
          Danh sách hóa đơn
        </Typography>
        <Box
          className="RootBox"
          sx={{
            display: "flex",
            // padding: "12px",
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div className="FindBox" style={{ display: "flex", gap: 2 }}>
            <FormControl sx={{ margin: "3px", width: "100%" }} size="md">
              <FormLabel>
                Từ khóa tìm kiếm{" "}
                <Tooltip
                  title="Nhập Mã hóa đơn, Thông tin liên hệ, Mã dòng họ, Tên dòng họ, ..."
                  placement="right"
                  size="sm"
                >
                  <InfoOutlinedIcon fontSize="small" />
                </Tooltip>
              </FormLabel>
              <Input
                placeholder="Nhập nội dung tìm kiếm"
                value={searchName}
                sx={{ minWidth: 300 }}
                size="sm"
                onChange={(e) => {
                  setName((e.target as HTMLInputElement).value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setName((e.target as HTMLInputElement).value);
                    setTempSearch({
                      ...tempSearch,
                      searchName: (e.target as HTMLInputElement).value,
                    });
                    setCurrentPage(1);
                    setsearchClick(!searchClick);
                  }
                }}
                startDecorator={<SearchIcon />}
              />
            </FormControl>
            <FormControl sx={{ margin: "3px", width: "100%" }} size="md">
              <FormLabel>Trạng thái hóa đơn</FormLabel>
              <Select
                name="status"
                size="sm"
                value={status}
                sx={{ minWidth: 170 }}
                onChange={(e: any, newValue: any) => {
                  setStatus(newValue);
                }}
              >
                <Option value="all">Tất cả</Option>
                {statusData.map((x) => {
                  return (
                    <Option key={x.key} value={x.key}>
                      {x.value}
                    </Option>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl sx={{ margin: "3px", width: "100%" }} size="md">
              <FormLabel>Gói dịch vụ</FormLabel>
              <Select
                name="status"
                size="sm"
                value={packages}
                sx={{ minWidth: 170 }}
                onChange={(e: any, newValue: any) => {
                  setPack(newValue);
                }}
              >
                <Option value="all">Tất cả</Option>
                {list_package.map((x) => {
                  return (
                    <Option key={x._id} value={x._id}>
                      {x.package_name}
                    </Option>
                  );
                })}
              </Select>
            </FormControl>
            <Box sx={{ display: "flex", width: "90%" }}>
              <FormControl sx={{ margin: "3px", width: "50%" }} size="md">
                <FormLabel>Ngày lập hóa đơn</FormLabel>
                <DatePicker
                  locale={vi}
                  openToDate={new Date()}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="dd/mm/yyyy"
                  onChange={(date) => setfromDate(date ? date : undefined)}
                  selected={fromDate}
                  startDate={fromDate}
                  endDate={toDate}
                  customInput={
                    <Input
                      sx={{ width: 170 }}
                      size="sm"
                      // onChange={(e) => {
                      //   setfromDate((e.target as HTMLInputElement).value);
                      // }}
                      startDecorator={"Từ"}
                      endDecorator={
                        <CalendarTodaySharpIcon fontSize="inherit" />
                      }
                    />
                  }
                />
              </FormControl>
              <FormControl sx={{ margin: "3px", width: "50%" }} size="md">
                <div style={{ height: "25px" }}></div>
                <DatePicker
                  locale={vi}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="dd/mm/yyyy"
                  onChange={(date) => settoDate(date ? date : undefined)}
                  startDate={fromDate}
                  openToDate={new Date()}
                  endDate={toDate}
                  minDate={fromDate}
                  selected={toDate}
                  customInput={
                    <Input
                      sx={{ width: 170 }}
                      size="sm"
                      // onChange={(e) => {
                      //   setfromDate((e.target as HTMLInputElement).value);
                      // }}
                      startDecorator={"Đến"}
                      endDecorator={
                        <CalendarTodaySharpIcon fontSize="inherit" />
                      }
                    />
                  }
                />
              </FormControl>
            </Box>
            <Box sx={{ margin: "3px", width: "100%" }}>
              <div style={{ height: "25px" }}></div>

              <Box
                sx={{
                  height: "2.3vh",
                  display: "flex",
                  width: {
                    xs: "100%",
                    lg: "170px",
                  },
                }}
              >
                <Button
                  size="sm"
                  startDecorator={<SearchIcon />}
                  onClick={() => handleSearch()}
                  sx={{
                    width: '90%',
                    marginRight: '5%'
                  }}
                >
                  Tìm kiếm
                </Button>
                <Tooltip title="Làm mới">
                  <IconButton
                    size="sm"
                    variant="outlined"
                    sx={{
                      height: "2.3vh",
                      width: '5%',
                    }}
                    onClick={() => {
                      setName('');
                      setPack('all');
                      setStatus('all');
                      setfromDate(undefined);
                      settoDate(undefined);
                      setTempSearch({ searchName:'', packages:'all', status:'all', fromDate: undefined, toDate: undefined,});
                      setCurrentPage(1);
                      setsearchClick(!searchClick);
                    }}
                  >
                    <RestartAltIcon color="primary" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </div>
          <Box className="ButtonDiv" sx={{ display: "flex" }}>
            <FormControl sx={{ margin: "5px" }} size="sm">
              <div style={{ height: "22px" }} />
              <Button
                size="sm"
                color="success"
                disabled={page <= 0}
                startDecorator={<DownloadIcon />}
                onClick={() => exportExcel()}
              >
                Xuất Excel
              </Button>
            </FormControl>
            {/* <FormControl sx={{ margin: "5px" }} size="sm">
              <div style={{ height: "2.3vh" }} />
              <Button
                size="sm"
                // style={{ width: "7vw" }}
                startDecorator={<AddIcon />}
                onClick={() => navigate("/package/addnew")}
              >
                Thêm mới
              </Button>
            </FormControl> */}
          </Box>
        </Box>

        <Sheet
          sx={{
            bgcolor: "background.level1",
            borderRadius: "sm",
            px: 1.5,
            py: 0.5,
            my: 1,
            display: "flex",
            gap: 2,
            "& > div": { flex: 1 },
            "& > div > div > p > a": { textDecorationColor: 'unset' },
            "& > div:first-of-type": { flex: { xs: 1 } },
            "& > div:first-of-type > div": { flex: { xs: 1 } },
            "& > div:nth-of-type(2)": { flex: { xs: 1, lg: 2 } },
            // "& > div:nth-of-type(2) > p:nth-of-type(2)": { width: { xs: "50%" } },
          }}
        >
          <div className="Number">
            <div>
              <Typography level={laptopUI ? "body-sm" : "body-xs"} fontWeight="lg">
                Số đơn chưa xử lý
              </Typography>
              <Typography
                textColor="warning.400"
                fontSize={tabletpUI ? "xl" : "md"}
                fontWeight="xl"
                my={1}
              >
                <Link underline="always" textColor="warning.400" color="danger" onClick={()=>changeStatus(statusData[0].key)}>{countAtrr.pending_count}</Link>
              </Typography>
            </div>

            <div>
              <Typography level={laptopUI ? "body-sm" : "body-xs"} fontWeight="lg">
                Số đơn hoàn thành
              </Typography>
              <Typography
                textColor="success.400"
                fontSize={tabletpUI ? "xl" : "md"}
                fontWeight="xl"
                my={1}
              >
                <Link underline="always" textColor="success.400" color="success" onClick={()=>changeStatus(statusData[2].key)}>{countAtrr.completed_count}</Link>
              </Typography>
            </div>
            <div>
              <Typography level={laptopUI ? "body-sm" : "body-xs"} fontWeight="lg">
                Số đơn đã hủy
              </Typography>
              <Typography
                textColor="neutral.400"
                fontSize={tabletpUI ? "xl" : "md"}
                fontWeight="xl"
                my={1}
              >
                <Link underline="always" textColor="neutral.400" color="neutral" onClick={()=>changeStatus(statusData[1].key)}>{countAtrr.canceled_count}</Link>
              </Typography>
            </div>
          </div>
          <div className="Money">
            <div>
              <Typography level={laptopUI ? "body-sm" : "body-xs"} fontWeight="lg">
                Tổng tiền hóa đơn
              </Typography>
              <Typography
                textColor="primary.500"
                fontSize={tabletpUI ? "xl" : "md2"}
                fontWeight="xl"
                my={1}
              >
                {(countAtrr.pending_total + countAtrr.count_total)
                  .toLocaleString("vi-VN", {
                    style: "currency",
                    currency: "VND",
                  })
                  .replace("₫", "VND")}
              </Typography>
            </div>
            <div>
              <Typography level={laptopUI ? "body-sm" : "body-xs"} fontWeight="lg">
                Tổng doanh thu
              </Typography>
              <Typography
                textColor="primary.400"
                fontSize={tabletpUI ? "xl" : "md2"}
                fontWeight="xl"
                my={1}
              >
                {countAtrr.count_total
                  .toLocaleString("vi-VN", {
                    style: "currency",
                    currency: "VND",
                  })
                  .replace("₫", "VND")}
              </Typography>
            </div>
            <div>
              <Typography level={laptopUI ? "body-sm" : "body-xs"} fontWeight="lg">
                Tổng tiền chưa thanh toán
              </Typography>
              <Typography
                textColor="warning.400"
                fontSize={tabletpUI ? "xl" : "md2"}
                fontWeight="xl"
                my={1}
              >
                {countAtrr.pending_total
                  .toLocaleString("vi-VN", {
                    style: "currency",
                    currency: "VND",
                  })
                  .replace("₫", "VND")}
              </Typography>
            </div>
          </div>
        </Sheet>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
          }}
        >
          <Typography
            sx={{
              textAlign: "end",
              pr: "10px",
            }}
          >
            Tổng số bản ghi : {page}
          </Typography>
        </div>
        <Sheet
          className="OrderTableContainer"
          variant="outlined"
          sx={{
            width: "100%",
            borderRadius: "md",
            flex: 1,
            overflow: "auto",
            minHeight: "60vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          {rows.length > 0 ? (
            <div className="DivTable" style={{ width: "100%", height: "60vh" }}>
              <Table
                aria-labelledby="tableTitle"
                stickyHeader
                hoverRow
                borderAxis="bothBetween"
                sx={{
                  "& tbody tr > *:nth-of-type(16)": {
                    position: "sticky",
                    right: 0,
                    bgcolor: "var(--TableCell-headBackground)",
                  },
                  "& thead tr:first-of-type > *:nth-of-type(10)": {
                    position: "sticky",
                    right: 0,
                    zIndex: 12,
                    bgcolor: "var(--TableCell-headBackground)",
                  },
                  "& tr > *:first-of-type": { textAlign: "center" },
                  "& tr > *:nth-of-type(2)": { textAlign: "center" },
                  "& tr > *:nth-of-type(3)": { textAlign: "center" },
                  "& tr > *:nth-of-type(8)": { textAlign: "right" },
                  "& tr > *:nth-of-type(9)": { textAlign: "right" },
                  "& tr > *:nth-of-type(10)": { textAlign: "right" },
                  "& tr > *:nth-of-type(11)": { textAlign: "right" },
                  "& tr > *:nth-of-type(12)": { textAlign: "center" },
                  "& tr > *:nth-of-type(13)": { textAlign: "center" },
                  "& tr > *:nth-of-type(14)": { textAlign: "center" },
                  "& tr > *:nth-of-type(15)": { textAlign: "center" },
                  "& th": {
                    color: "#096BDE",
                    height: "22px",
                  },
                  // borderCollapse: 'collapse',
                }}
                style={{ tableLayout: "auto" }}
              >
                <thead>
                  <tr>
                    <th
                      rowSpan={2}
                      style={{ width: "4%", textAlign: "center" }}
                    >
                      STT
                    </th>
                    <th
                      rowSpan={2}
                      style={{ width: "6%", textAlign: "center" }}
                    >
                      Mã hóa đơn
                    </th>
                    <th
                      rowSpan={2}
                      style={{ width: "6%", textAlign: "center" }}
                    >
                      Ngày tạo
                    </th>
                    <th colSpan={3} style={{ textAlign: "center" }}>
                      Thông tin khách hàng
                    </th>
                    <th colSpan={5} style={{ textAlign: "center" }}>
                      Thông tin gói dịch vụ
                    </th>
                    <th
                      rowSpan={2}
                      style={{ width: "6%", textAlign: "center" }}
                    >
                      Hoàn thành
                    </th>
                    <th
                      rowSpan={2}
                      style={{ width: "7%", textAlign: "center" }}
                    >
                      Ghi chú
                    </th>
                    <th
                      rowSpan={2}
                      style={{ width: "10%", textAlign: "center" }}
                    >
                      Loại
                    </th>
                    <th
                      rowSpan={2}
                      style={{ textAlign: "center" }}
                    >
                      Trạng thái
                    </th>
                    <th
                      rowSpan={2}
                      style={{
                        textAlign: "center",
                      }}
                    ></th>
                  </tr>
                  <tr>
                    <th
                      style={{
                        width: "6%",
                        borderRightWidth: 0,
                        borderRadius: "0px",
                        top: "35px",
                      }}
                    >
                      Mã dòng họ
                    </th>
                    <th
                      style={{
                        width: "10%",
                        textAlign: "center",
                        borderRightWidth: 0,
                        top: "35px",
                      }}
                    >
                      Dòng họ
                    </th>
                    {/* <th
                      style={{
                        width: "15%",
                        textAlign: "center",
                        borderRightWidth: 0,
                        top: "35px",
                      }}
                    >
                      Họ tên
                    </th> */}
                    <th
                      style={{
                        width: "13%",
                        textAlign: "center",
                        borderRightWidth: 0,
                        top: "35px",
                      }}
                    >
                      Thông tin liên hệ
                    </th>
                    <th
                      style={{
                        width: "10%",
                        textAlign: "center",
                        borderRightWidth: 0,
                        top: "35px",
                      }}
                    >
                      Gói dịch vụ
                    </th>
                    <th
                      style={{
                        width: "5%",
                        textAlign: "center",
                        top: "35px",
                      }}
                    >
                      Chu kì
                    </th>
                    <th
                      style={{
                        // width: "5%",
                        textAlign: "center",
                        top: "35px",
                      }}
                    >
                      SL
                    </th>
                    <th
                      style={{
                        // width: "5%",
                        textAlign: "center",
                        top: "35px",
                      }}
                    >
                      CK
                    </th>
                    <th
                      style={{
                        // width: "10%",
                        textAlign: "center",
                        borderRightWidth: 0,
                        top: "35px",
                      }}
                    >
                      Tổng tiền
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {rows.map((row: Order, index: number) => (
                    <tr key={row._id}>
                      <td>{(currentpage - 1) * 10 + index + 1}</td>
                      <td>{row.order_alias ? row.order_alias : ""}</td>
                      <td>
                        <DisplayDate value={row.create_at.toString()} />
                      </td>
                      <td>
                        {row!.family!.family_alias_name +
                          row!
                            .family!.family_alias_number.toString()
                            .padStart(4, "0")}
                      </td>
                      <td>{row.family.family_name}</td>
                      {/* <td>{row.customer.full_name}</td> */}
                      <td>
                        <div>
                          <b>Họ tên: </b>
                          {row.customer.full_name}
                        </div>
                        <div>
                          <b>Email: </b>
                          {row.customer.email}
                        </div>
                        <div>
                          <b>Điện thoại: </b>
                          {row.customer.phone}
                        </div>
                      </td>
                      <td>{row.package.package_name}</td>
                      <td>{row.package.cycle_day}</td>
                      <td>{row.count}</td>
                      <td>{row.sale_percent_value ? row.sale_percent_value : '0'}%</td>
                      <td>
                        {row.total
                          .toLocaleString("vi-VN", {
                            style: "currency",
                            currency: "VND",
                          })
                          .replace("₫","")}
                      </td>
                      <td>
                        <DisplayDate
                          value={
                            row.processDate
                              ? row.processDate.toString()
                              : undefined
                          }
                          defaultValue=""
                        />
                      </td>
                      <Tooltip
                        title={
                          row.cancel_reason != null && row.cancel_reason
                            ? row.cancel_reason
                            : row.note
                        }
                        arrow
                        color="warning"
                        sx={{ maxWidth: "300px" }}
                      >
                        <td
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            maxWidth: "20px",
                          }}
                        >
                          {row.cancel_reason != null && row.cancel_reason
                            ? row.cancel_reason
                            : row.note}
                        </td>
                      </Tooltip>
                      <td>
                        {list_type.find((x) => x.type_key == row.type) &&
                        row.type
                          ? list_type.find((x) => x.type_key == row.type)!
                              .type_name
                          : ""}
                      </td>
                      <td>
                        {statusData.map((x) => {
                          if (x.key == row.status) {
                            return (
                              <Chip
                                variant="solid"
                                size="md"
                                color={x.color}
                                key={x.key}
                              >
                                {x.value}
                              </Chip>
                            );
                          }
                          return;
                        })}
                      </td>
                      <td>
                        {user!.role == "root" ? (
                          <ButtonGroup variant="text" size="small">
                            <Tooltip title="Chỉnh sửa">
                              <MButoon
                                onClick={() => updateOrder(row._id, row)}
                              >
                                <ModeEditIcon fontSize="small" />
                              </MButoon>
                            </Tooltip>
                          </ButtonGroup>
                        ) : (
                          <ButtonGroup variant="text" size="small">
                            <Tooltip title="Hủy đơn">
                              <MButoon
                                onClick={() => cancelOrder(row._id, row)}
                                disabled={
                                  row.status == "COMPLETED" ||
                                  row.status == "CANCELED"
                                    ? true
                                    : false
                                }
                              >
                                <DoDisturbOnOutlinedIcon fontSize="small" />
                              </MButoon>
                            </Tooltip>
                            <Tooltip title="Hoàn thành đơn">
                              <MButoon
                                onClick={() => {
                                  completeOrder(row._id, row);
                                  setLayout(!laptopUI);
                                }}
                                disabled={
                                  row.status == "COMPLETED" ||
                                  row.status == "CANCELED"
                                    ? true
                                    : false
                                }
                              >
                                <AssignmentTurnedInOutlinedIcon fontSize="small" />
                              </MButoon>
                            </Tooltip>
                          </ButtonGroup>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <div
              style={{
                width: "100%",
                textAlign: "center",
                margin: "auto",
                height: "10vh",
              }}
            >
              {" "}
              <Typography level="body-lg">
                Không có kết quả phù hợp !
              </Typography>{" "}
            </div>
          )}
          <PopupRender>{popup}</PopupRender>
          <CommonPopupRender {...initPopup} />
        </Sheet>
        <Box sx={{ marginTop: "10px", display: { md: "block", xs: "none" } }}>
          <Pagination
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "auto",
            }}
            showFirstButton
            showLastButton
            count={page > 10 ? Math.ceil(page / 10) : 1}
            size="medium"
            color="primary"
            page={currentpage}
            onChange={handleChangePage}
          />
        </Box>
        <Box
          sx={{
            display: { xs: "flex", md: "none" },
            margin: "auto",
          }}
        >
          <IconButton
            size="sm"
            color="neutral"
            variant="plain"
            disabled={currentpage === 1}
            onClick={() => {
              setCurrentPage(1);
              setsearchClick(!searchClick);
            }}
            sx={{ bgcolor: "background.surface" }}
          >
            <FirstPageIcon />
          </IconButton>
          <IconButton
            size="sm"
            color="neutral"
            variant="plain"
            disabled={currentpage === 1}
            onClick={() => {
              setCurrentPage(currentpage - 1);
              setsearchClick(!searchClick);
            }}
            sx={{ bgcolor: "background.surface" }}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
          <Typography textAlign="center" sx={{ minWidth: 80 }}>
            {`Trang ${currentpage}`}
          </Typography>
          <IconButton
            size="sm"
            color="neutral"
            variant="plain"
            disabled={currentpage == Math.ceil(page / 10) || rows.length == 0}
            onClick={() => {
              setCurrentPage(currentpage + 1);
              setsearchClick(!searchClick);
            }}
            sx={{ bgcolor: "background.surface" }}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
          <IconButton
            size="sm"
            color="neutral"
            variant="plain"
            disabled={currentpage == Math.ceil(page / 10) || rows.length == 0}
            onClick={() => {
              setCurrentPage(Math.ceil(page / 10));
              setsearchClick(!searchClick);
            }}
            sx={{ bgcolor: "background.surface" }}
          >
            <LastPageIcon />
          </IconButton>
        </Box>
      </div>
      <Backdrop
        sx={{
          background: "rgba(245, 245, 245, 0.6)",
          zIndex: 10000,
          backdropFilter: "blur(5px)",
        }}
        open={loading}
        transitionDuration={{
          appear: 1,
          exit: 1,
        }}
      >
        <CircularProgress thickness={4} size="lg" variant="plain" />
      </Backdrop>
    </Box>
  );
}


