import { useState, useEffect, useRef } from "react";
import app from "../../common/sendrequest";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import VisibilityOffSharpIcon from '@mui/icons-material/VisibilityOffSharp';
import * as React from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Typography,
  Select,
  Option,
  TabPanel,
  Box,
  Tab,
  TabList,
  Tabs,
  tabClasses,
  FormHelperText,
  Modal,
  ModalClose,
  ModalDialog,
} from "@mui/joy";
import { isEmpty, validatePassWord } from "../../common/expression";
import configs from "../../config";
import axios, { AxiosError } from "axios";
import CommonPopupRender from "../../common/popuprender";
import { isLetter } from "../../common/expression";
import { validatePhone } from "../../common/expression";
import { validateEmail } from "../../common/expression";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import CalendarTodaySharpIcon from '@mui/icons-material/CalendarTodaySharp';
import { vi } from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css";
const initialValidate = { invalid: false, errors: "" };
type ServerError = { message: string };
const initState = {
  full_name: "",
  email: "",
  birth_day: "",
  gender: 0,
  phone: "",
}
export default function InforUser() {
  const nameForm = useRef(null);
  let navigate = useNavigate();
  const [validateName, setvalidateName] = useState(initialValidate);
  const [validateEmails, setvalidateEmail] = useState(initialValidate);
  const [validatePhones, setvalidatePhone] = useState(initialValidate);
  const [state, setState] = React.useState(initState);
  const [birth_day, setBirthday] = useState<Date>();
  const [showPwd, setShowPwd] = React.useState(false);
  const [showPwd1, setShowPwd1] = React.useState(false);
  const [showPwd2, setShowPwd2] = React.useState(false);
  const [open, setOpen] = React.useState<boolean>(false);
  const [popup, setPopUp] = React.useState<JSX.Element>(<></>);
  const [pwd1, setPwd1] = React.useState("");
  const [validatePwd1, setvalidatePwd1] = React.useState(initialValidate);
  const [pwd2, setPwd2] = React.useState("");
  const [validatePwd2, setvalidatePwd2] = React.useState(initialValidate);
  const [pwd3, setPwd3] = React.useState("");
  const [validatePwd3, setvalidatePwd3] = React.useState(initialValidate);
  const [initPopup, setInitPopup] = useState({
    openFlag: false,
    type: "",
    content: "",
    onPass: () => {},
    onCancel: null,
    setOpenFlag: (flag: boolean) =>
      setInitPopup({ ...initPopup, openFlag: flag }),
  });

  useEffect(() => {
    getData();
    sessionStorage.clear();
  }, []);
  const getData = async () => {
    const data = JSON.parse(localStorage.getItem("user") || "{}");
    await app
      .post(`${configs.auth}infor_user`)
      .then((data: any) => {
        if(data.data)
        {
          setState({
            full_name : data.data.full_name ? data.data.full_name : "",
            email : data.data.email ? data.data.email : "",
            phone : data.data.phone ? data.data.phone : "",
            birth_day : data.data.birth_day? new Date(data.data.birth_day.toString()).toLocaleDateString("en-CA"): "",
            gender :  data.data.gender ? data.data.gender : 0,
          })
          if(data.data.birth_day){setBirthday(new Date(data.data.birth_day))}
        }
      }).catch((err) => {
        console.log(err)
        setState(initState);
      });
  };

  const handleError = (err: any) => {
    if (axios.isAxiosError(err)) {
      const serverError = err as AxiosError<ServerError>;
      if (serverError && serverError.response) {
        setInitPopup({
          ...initPopup,
          openFlag: true,
          type: "fail",
          content:  serverError.response.data.message,
        });
      }
    }
  };

  const PopupRender = ({ children }: any) => {
    return (
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog
          aria-labelledby="basic-modal-dialog-title"
          aria-describedby="basic-modal-dialog-description"
          sx={{ width: "20vw" }}
          size="md"
        >
          <ModalClose />
          {children}
        </ModalDialog>
      </Modal>
    );
  };

  const onBlurPwd1 = (e: any) => {
    setPwd1(e.target.value);
    if (isEmpty(e.target.value)) {
      setvalidatePwd1({
        invalid: true,
        errors: "Mật khẩu người dùng không được để trống",
      });
    } else if (!validatePassWord(e.target.value)) {
      setvalidatePwd1({
        invalid: true,
        errors: "Mật khẩu sai định dạng",
      });
    } else setvalidatePwd1({ invalid: false, errors: "" });
  };

  const onBlurPwd2 = (e: any) => {
    setPwd2(e.target.value);
    if (isEmpty(e.target.value)) {
      setvalidatePwd2({
        invalid: true,
        errors: "Mật khẩu người dùng không được để trống",
      });
    } else if (!validatePassWord(e.target.value)) {
      setvalidatePwd2({
        invalid: true,
        errors: "Mật khẩu sai định dạng",
      });
    } else setvalidatePwd2({ invalid: false, errors: "" });
  };

  const onBlurPwd3 = (e: any) => {
    setPwd3(e.target.value);
    if (isEmpty(e.target.value)) {
      setvalidatePwd2({
        invalid: true,
        errors: "Mật khẩu người dùng không được để trống",
      });
    } else if (!validatePassWord(e.target.value)) {
      setvalidatePwd3({
        invalid: true,
        errors: "Mật khẩu sai định dạng",
      });
    } else setvalidatePwd3({ invalid: false, errors: "" });
  };

  const handleOnPass = (CurrentPwdOnChange: string, newPwdOnChange : string, checkPwdOnChange : string) =>{
    if (CurrentPwdOnChange == "" || newPwdOnChange == "") {
      setInitPopup({
        ...initPopup,
        openFlag: true,
        type: 'fail',
        content: `Nhập thiếu mật khẩu`
      }
      );
      return ;
    }

    if (CurrentPwdOnChange == newPwdOnChange) {
      setInitPopup({
        ...initPopup,
        openFlag: true,
        type: 'fail',
        content: `Không được nhập mật khẩu giống mật khẩu cũ`
      }
      );
      return ;
    }

    if (checkPwdOnChange != newPwdOnChange) {
      setInitPopup({
        ...initPopup,
        openFlag: true,
        type: 'fail',
        content: `Nhập mật khẩu không khớp`
      }
      );
      return ;
    }
    app
      .post(configs.auth + "resetpassword", {
        cur_pwd: CurrentPwdOnChange,
        new_pwd: newPwdOnChange,
      })
      .then(async (data: any) => {
        setInitPopup({
          ...initPopup,
          openFlag: true,
          type: "success",
          content: `Thay đổi mật khẩu thành công`,
          onPass : ()=>{
            app.post(configs.auth + "logout").catch((err)=>{
              localStorage.removeItem("user_infor");
              navigate("/login");
            }),
            localStorage.clear();
            sessionStorage.clear();
            navigate("/login");
          }
        });
      })
      .catch((err) => {
        handleError(err);
      });
  }

  const onSubmitHandler = async (event: any) => {
    const form = nameForm.current || {
      cur_pwd: { value: "" },
      new_pwd: { value: "" },
      password: { value: "" },
    };
    const CurrentPwdOnChange = form["cur_pwd"]!.value;
    const newPwdOnChange = form["new_pwd"]!.value;
    const checkPwdOnChange = form["password"]!.value;

    setInitPopup({
      ...initPopup,
      openFlag: true,
      type: 'confirmed',
      content: `Bạn có chắc muốn thay đổi thông tin tài khoản này không ?`,
      onPass : () => handleOnPass(CurrentPwdOnChange,newPwdOnChange,checkPwdOnChange)
    });
    
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (
      state.full_name == "" ||
      validateName.invalid
    ) {
      return;
    }
    
    setInitPopup({
      ...initPopup,
      type: "confirmed",
      openFlag: true,
      content: "Bạn có chắc muốn thay đổi thông tin tài khoản này không ?",
      onPass: () =>
        app
          .post(`${configs.auth}update_infor`, {
            full_name: state.full_name,
            email: state.email,
            phone: state.phone,
            birth_day: birth_day,
            gender: state.gender,
          })
          .then(() => {
            setInitPopup({
              ...initPopup,
              openFlag: true,
              type: "success",
              content: "Thay đổi thông tin thành công!",
              onPass: () => {
                const data = JSON.parse(localStorage.getItem("user") || "{}");
                localStorage.setItem('user',JSON.stringify({
                  ...data,
                  full_name: state.full_name,
                  email: state.email,
                  phone: state.phone,
                  birth_day: birth_day,
                  gender: state.gender
                }))
                window.location.reload();
              },
            });
          })
          .catch((err) => {
            handleError(err);
          }),
    });
  };

  const handleChange = (event : any) => {
    const name = event.target.name;
    const value = event.target.value;
    setState({ ...state, [name]: value });
  };
  const handleDateChange = (date: any) => {
    // setState({ ...state, birth_day: date });
    setBirthday(date)
  };

  const onBlurEmail = (e: any) => {
    if (!validateEmail(e.target.value) && !isEmpty(e.target.value)) {
      setvalidateEmail({
        invalid: true,
        errors: "Email sai định dạng",
      });
    } else setvalidateEmail({ invalid: false, errors: "" });
  };

  const onBlurNameHandler = (e: any) => {
    setTimeout(() => {
      if (isEmpty(e.target.value)) {
        setvalidateName({
          invalid: true,
          errors: "Họ và tên người dùng không được để trống",
        });
      } else {
        setvalidateName({ invalid: false, errors: "" });
      }
    }, 200);
  };

  const onBlurPhone = (e: any) => {
    if (!validatePhone(e.target.value) && !isEmpty(e.target.value)) {
      setvalidatePhone({
        invalid: true,
        errors: "Số điện thoại sai định dạng",
      });
    } else setvalidatePhone({ invalid: false, errors: "" });
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginBottom: "auto",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Typography
          level="h4"
          component="h1"
          sx={{ alignSelf: "center", padding: "2vh" }}
        >
          Thông tin cá nhân
        </Typography>
        <Tabs
          size="sm"
          aria-label="Pricing plan"
          defaultValue={0}
          sx={(theme) => ({
            width: "90%",
            maxWidth: "1200px",
            "--Tabs-gap": "0px",
            borderRadius: "lg",
            boxShadow: "sm",
            overflow: "auto",
            border: `1px solid ${theme.vars.palette.divider}`,
            margin: "auto",
          })}
        >
          <TabList
            sx={{
              "--ListItem-radius": "0px",
              borderRadius: 0,
              [`& .${tabClasses.root}`]: {
                fontWeight: "lg",
                flex: 1,
                bgcolor: "background.body",
                position: "relative",
                [`&.${tabClasses.selected}`]: {
                  color: "primary.500",
                },
                [`&.${tabClasses.selected}:before`]: {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  bottom: -1,
                  width: "100%",
                  height: 2,
                  bgcolor: "primary.400",
                },
                [`&.${tabClasses.focusVisible}`]: {
                  outlineOffset: "-3px",
                },
              },
            }}
          >
            <Tab sx={{ py: 1.5 }}>Thông tin cá nhân</Tab>
            <Tab>Thay đổi mật khẩu</Tab>
          </TabList>
          <TabPanel value={0} sx={{ p: 3 }}>
            <form onSubmit={onSubmit} style={{ width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FormControl sx={{ width: "100%" }} required>
                  <FormLabel>Họ và tên</FormLabel>
                  <Input
                    required
                    name="full_name"
                    type="text"
                    error={validateName.invalid}
                    sx={{ width: "100%" }}
                    slotProps={{
                      input:{
                        maxLength:250
                      }
                    }}
                    onChange={handleChange}
                    value={state.full_name}
                    onBlur={onBlurNameHandler}
                  />
                  {validateName.invalid ? (
                    <FormHelperText sx={{ color: "red" }}>
                      {validateName.errors}
                    </FormHelperText>
                  ) : (
                    <></>
                  )}
                </FormControl>
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel>Email</FormLabel>
                  <Input
                    name="email"
                    type="text"
                    sx={{ width: "100%" }}
                    onChange={handleChange}
                    value={state.email}
                  />
                </FormControl>
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel>Số điện thoại</FormLabel>
                  <Input
                    name="phone"
                    type="text"
                    sx={{ width: "100%" }}
                    onChange={handleChange}
                    value={state.phone}
                  />
                </FormControl>
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel>Ngày sinh</FormLabel>
                  {/* <Input
                    name="birth_day"
                    type="date"
                    sx={{ width: "100%" }}
                    onChange={handleChange}
                    value={state.birth_day}
                  /> */}
                  <DatePicker
                    onChange={handleDateChange}
                    selected={birth_day}
                    customInput={
                      <Input
                        className="InputForm"
                        name="birth_day"
                        sx={{ width: "100%" }}
                        endDecorator={
                          <CalendarTodaySharpIcon fontSize="small" />
                        }
                      />
                    }
                    locale={vi}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="dd/mm/yyyy"
                  />
                </FormControl>
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel>Giới tính</FormLabel>
                  <Select
                    name="genders"
                    sx={{ width: "100%" }}
                    value={state.gender.toString()}
                    onChange={(e: any, newValue) => {
                      setState({ ...state, gender: Number(newValue) });
                    }}
                  >
                    <Option value="1"> Nam</Option>
                    <Option value="2"> Nữ </Option>
                  </Select>
                </FormControl>
                <Button sx={{ width: "100px", marginTop: "3vh" }} type="submit">
                  Cập nhật
                </Button>
              </div>
            </form>
          </TabPanel>
          <TabPanel value={1} sx={{ p: 3 }}>
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              ref={nameForm}
            >
              <FormControl sx={{ width: "100%" }} required>
                <FormLabel>Mật khẩu hiện tại</FormLabel>
                <Input
                  name="cur_pwd"
                  type={showPwd ? "text" : "password"}
                  sx={{ width: "100%" }}
                  error={validatePwd1.invalid}
                  endDecorator={
                    showPwd ? (
                      <VisibilityIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => setShowPwd(!showPwd)}
                      />
                    ) : (
                      <VisibilityOffSharpIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => setShowPwd(!showPwd)}
                      />
                    )
                  }
                  onBlur={onBlurPwd1}
                  defaultValue={pwd1}
                />
                {validatePwd1.invalid ? (
                  <FormHelperText sx={{ color: "red" }}>
                    {validatePwd1.errors}
                  </FormHelperText>
                ) : (
                  <></>
                )}
              </FormControl>
              <FormControl sx={{ width: "100%" }} required>
                <FormLabel>Mật khẩu mới</FormLabel>
                <Input
                  name="new_pwd"
                  type={showPwd1 ? "text" : "password"}
                  sx={{ width: "100%" }}
                  error={validatePwd2.invalid}
                  endDecorator={
                    showPwd1 ? (
                      <VisibilityIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => setShowPwd1(!showPwd1)}
                      />
                    ) : (
                      <VisibilityOffSharpIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => setShowPwd1(!showPwd1)}
                      />
                    )
                  }
                  onBlur={onBlurPwd2}
                  defaultValue={pwd2}
                />
                {validatePwd2.invalid ? (
                  <FormHelperText sx={{ color: "red" }}>
                    {validatePwd2.errors}
                  </FormHelperText>
                ) : (
                  <></>
                )}
              </FormControl>
              <FormControl sx={{ width: "100%" }} required>
                <FormLabel>Nhập lại mật khẩu</FormLabel>
                <Input
                  name="password"
                  type={showPwd2 ? "text" : "password"}
                  sx={{ width: "100%" }}
                  error={validatePwd3.invalid}
                  endDecorator={
                    showPwd2 ? (
                      <VisibilityIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => setShowPwd2(!showPwd2)}
                      />
                    ) : (
                      <VisibilityOffSharpIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => setShowPwd2(!showPwd2)}
                      />
                    )
                  }
                  onBlur={onBlurPwd3}
                  defaultValue={pwd3}
                />
                {validatePwd3.invalid ? (
                  <FormHelperText sx={{ color: "red" }}>
                    {validatePwd3.errors}
                  </FormHelperText>
                ) : (
                  <></>
                )}
              </FormControl>
              <Button
                sx={{ width: "100px", marginTop: "3vh" }}
                onClick={onSubmitHandler}
              >
                Cập nhật
              </Button>
              <PopupRender>{popup}</PopupRender>
            </form>
          </TabPanel>
        </Tabs>
        <CommonPopupRender {...initPopup} />
      </div>
    </Box>
  );
}
