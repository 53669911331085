import Logout from "../auth/Logout";
import * as React from "react";
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined';
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
  ListSubheader,
  Sheet,
  Modal,
  ModalClose,
  Card,
  Avatar,
  Typography,
  Divider,
  ModalDialog,
  ModalOverflow,
} from "@mui/joy";
import {styled} from '@mui/joy/styles'
import { Link, useNavigate } from "react-router-dom";
import { Icon, createTheme, useMediaQuery } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import AccessAlarmsOutlinedIcon from '@mui/icons-material/AccessAlarmsOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import QrCodeScannerOutlinedIcon from '@mui/icons-material/QrCodeScannerOutlined';
import Logo from "../../assets/images/logo-large.webp";
import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import DomainOutlinedIcon from '@mui/icons-material/DomainOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import LogoSmall from "../../assets/images/logo-small.webp";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import ListOrderQR from "../order/ListOrderQR";
 
export default function Navbar() {
  const [open, setOpen] = React.useState<boolean>(false);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [popup, setPopUp] = React.useState<JSX.Element>(<></>);
  const [layout, setLayout] = React.useState(false);
  const [openNav, setOpenNav] = React.useState<boolean>(false);
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 375,
        md: 1000,
        lg: 1280,
        xl: 1630,
      },
    },
  });
  const xllaptopUI = useMediaQuery(theme.breakpoints.up('xl'));
  let laptopUI = useMediaQuery(theme.breakpoints.up('lg'));
  let mobileUI = useMediaQuery(theme.breakpoints.up('md'));
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  // setLayout(mobileUI);
  const PopupRender = ({ children }: any) => {
    return (
      <Modal
        // sx={{width:'fit-content'}}
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          setLayout(false);
        }}
      >
        <ModalOverflow
          sx={{height:"unset"}}
        >
          <ModalDialog
            aria-labelledby="basic-modal-dialog-title"
            aria-describedby="basic-modal-dialog-description"
            sx={{ width:'fit-content' }}
            size="md"
            layout={layout ? "fullscreen" : "center"}
          >
            <ModalClose />
            {children}
          </ModalDialog>
        </ModalOverflow>
      </Modal>
    );
  };

  const StyledBox = styled(Box)(({ theme } ) => ({
    ".SecondSidebar":{
      "@media screen and (max-width: 900px)" : {
        minWidth : "30px",
      },
    },
    "@media screen and (min-width: 1280px)" : {
      // width : "10%"
    },
    "@media screen and (min-width: 1630px)" : {
      // width : "20%"
    },
    "@media screen and (min-width: 900px)" : {
      minHeight : "100vh"
    },

    ".MuiListItemContent-root": {
      "@media screen and (max-width: 900px)" : {
        display: 'none',
      },
    },

  }));
  const pages = [
    {
      key: "pending",
      title: "Hóa đơn chờ xử lý",
      path: "/order/pending",
      icon: <PendingActionsOutlinedIcon fontSize="small" color="inherit" />,
    },
    {
      key: "user",
      title: "Danh Sách Người dùng",
      path: "/user",
      icon: <PeopleAltOutlinedIcon fontSize="small"/>,
    },
    {
      key: "order",
      title: "Danh sách hóa đơn",
      path: "/order",
      icon: <ReceiptLongOutlinedIcon  fontSize="small"/>,
      // children: []
    },
    
    {
      key: "customer",
      title: "Quản lý Khách hàng",
      path: "/customer",
      icon: <PeopleAltOutlinedIcon fontSize="small" />,
    },{
      key: "domain",
      title: "Danh sách domain",
      path: "/domain-list",
      icon: <PublicOutlinedIcon fontSize="small" />,
    },
    {
      key: "package",
      title: "Quản lý gói dịch vụ",
      path: "/package",
      icon: <DnsOutlinedIcon fontSize="small" />,
    },
    {
      key: "setting",
      title: "Cấu hình chung",
      icon: <SettingsSuggestOutlinedIcon fontSize="small" />,
      children: [
        {
          key: "email-setting",
          title: "Cấu hình Email",
          path: "/email-setting",
          icon: <ContactMailOutlinedIcon fontSize="small" />,
        },
        {
          key: "infor-setting",
          title: "Cấu hình Thông tin",
          path: "/infor-setting",
          icon: <SettingsOutlinedIcon fontSize="small" />,
        },
        {
          key: "time-setting",
          title: "Cấu hình Thời gian",
          path: "/time-setting",
          icon: <AccessAlarmsOutlinedIcon fontSize="small" />,
        },
        {
          key: "domain-setting",
          title: "Cấu hình Domain",
          path: "/domain-setting",
          icon: <DomainOutlinedIcon fontSize="small" />,
        },
      ]
    },
    {
      key: "inforuser",
      title: "Thông tin Cá nhân",
      path: "/infor-user",
      icon: <ManageAccountsOutlinedIcon fontSize="small" />,
    },
    {
      key: "qr",
      title: "Thông tin thanh toán",
      icon: <QrCodeScannerOutlinedIcon fontSize="small"/>,
      func: ()=>{
        setLayout(!mobileUI);
        setOpenModal(!openNav);
        setPopUp(<ListOrderQR/>);
      }
    }
  ];
  const handleOnclick = (index : number, path : string)=>{
    // navigate(path);
    // setOpen(false);
  }

  function SubSlideBar(props: {
    page: {
      key: string;
      title: string;
      icon: any;
      children: {
        key: string;
        title: string;
        path: string;
        icon: any;
      }[];
    };
  }) {
    const { page } = props;
    const [sub_open, setSubOpen] = React.useState<boolean>(false);
    React.useEffect(() => {
      page.children.map((childpage: any, childindex: number) => {
        if (window.location.href.includes(childpage.path)) {
          setSubOpen(true);
        }
      });
    }, []);
    
    return (
      <ListItem
        key={page.key}
        nested
        sx={{ padding:"10px"}}
        endAction={
          xllaptopUI || openNav || !laptopUI ?
            <KeyboardArrowDown
              sx={{ transform: sub_open ? "initial" : "rotate(-90deg)" }}
            />
            :<></>
        }
      >
        <ListItemButton
          variant={"plain"}
          sx={{margin: "auto", width:"100%"}}
          onClick={() => {
            setSubOpen(!sub_open);
          }}
        >
          {(xllaptopUI || openNav) || !laptopUI ? (
            <>
              <ListItemDecorator sx={{color:sub_open ? "black": undefined}}>{page.icon}</ListItemDecorator>
              <ListItemContent sx={{fontWeight: sub_open ? 'bold' : undefined}}>{page.title}</ListItemContent>
            </>
          ) : (
            <Icon color={sub_open ? "inherit" : "action"}>
                    {page.icon}
            </Icon>
          )}
        </ListItemButton>
        {sub_open && (
          <List
            color="neutral"
            // variant="outlined"
            sx={{
              "--NestedList-marginRight":
                "calc(-1 * var(--ListItem-paddingRight) - 0px)",
              "--List-gap": "0px",
              backdropFilter: "brightness(90%)",
              transition:'0.5s linear',
            }}
          >
            {page.children.map((childpage: any, childindex: number) => {
              let child_select = false;
              if (window.location.href.includes(childpage.path)) {
                child_select = true;
              }
              return (
                <ListItem key={childpage.key}>
                  <ListItemButton
                    selected={child_select}
                    color={child_select ? "primary": undefined}
                    // variant={child_select ? "soft" : "plain"}
                    sx={{ margin: "auto" }}
                    onClick={() => {
                      handleOnclick(childindex, childpage.path);
                    }}
                    component="a"
                    title={xllaptopUI || openNav || !laptopUI ? "" : childpage.title}
                    href={childpage.path}
                  >
                    {xllaptopUI || openNav || !laptopUI ? (
                      <>
                        <ListItemDecorator>{childpage.icon}</ListItemDecorator>
                        <ListItemContent>{childpage.title}</ListItemContent>
                      </>
                    ) : (
                      <Icon color={child_select ? "secondary" :"action"}>{childpage.icon}</Icon> 
                    )}
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        )}
      </ListItem>
    );
  }

  const SildeBar = () => (
    <Sheet
      className="SecondSidebar"
      // variant="soft"
      color="primary"
      invertedColors
      sx={{
        // position: (laptopUI ? "sticky" :"relative"),
        borderRight: "1px solid",
        borderColor: "divider",
        transition: "transform 0.4s",
        zIndex: 100,
        // position:"fixed",
        height: "100%",
        top: 0,
        flexShrink: 0,
        alignItems: 'center',
        display: "flex",
        flexDirection: "column",
        gap: 4,
      }}
    >
      <List
        sx={{
          width:"100%",
          "--ListItem-radius": "8px",
          "--ListItem-minHeight": "32px",
          '--ListItem-endActionWidth': '0px',
          '--ListItem-endActionTranslateX': '-50%',
          transition:'0.5s linear',
          '& [class*="endAction"]' : {transform : "translate(var(--ListItem-endActionTranslateX), 0%)"},
          '& [class*="MuiSvgIcon-root"]':{height: "1.5rem", textAlign:"center"}
          // '--ListItem-paddingX' : (xllaptopUI || !laptopUI ? '0.75rem': '0rem')
          // '--List-nestedInsetStart': '5px',
        }}
      >
        <ListSubheader
          role="presentation"
          sx={{ color: "text.primary", alignItems: "center", marginRight:"auto", marginLeft:"auto", width:"100%" }}
        >
          {laptopUI ? (
            <Box sx={{width:"100%", maxWidth:"300px", textOverflow:"ellipsis", overflow:"hidden", wordWrap:"break-word"}}>
              <Box
                sx={{
                  backgroundPosition: "center",
                  backgroundImage: `url(${Logo})`,
                  width: xllaptopUI || openNav? "120px" : "50px",
                  height: xllaptopUI || openNav? "100px" : "75px",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: (xllaptopUI ?"cover":"contain"),
                  marginLeft:"auto",
                  marginRight:"auto",
                }}
              />
              <div/>
              {xllaptopUI || openNav || !laptopUI?<Typography fontSize="md" textAlign="center" fontWeight="lg" color="primary">
                {user!.full_name}
              </Typography>:<></>}
              <Divider/>
            </Box>
          ) : (
            <Box style={{display:"flex", alignItems:"center", width:"100%"}}>
              <MenuIcon />
              <Box>
                <Box
                  sx={{
                    backgroundPosition: "center",
                    backgroundImage: `url(${Logo})`,
                    width: "100px",
                    marginLeft:"50px",
                    height: "80px",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                />
                <Typography fontSize="md" textAlign="center" fontWeight="lg" color="primary">
                  {user!.full_name}
                </Typography>
              </Box>
              <ModalClose />
            </Box>
          )}
        </ListSubheader>
        
        {pages.map((page: any, index: number) => {
          let select_index = false;
          const data = JSON.parse(localStorage.getItem("user") || "{}");

          if (window.location.href.includes(page.path)) {
            if(page.key == "order" && window.location.href.includes('pending'))
            {
              select_index = false;
            }else{
              select_index = true;
            }
          }


          if (data.role == "admin") {
            switch (page.key) {
              case "user":
                return;
              case "setting":
              case "infor-setting":
              case "email-setting":
              case "time-setting":
                return;
              case "package":
              return;
              default:
                break;
            }
          }

          if (data.role == "root") {
            switch (page.key) {
              case "customer":
              case "domain":
              // case "order":
              case "pending":
                return;
              default:
                break;
            }
          }
          if(page.children)
          {
            return <SubSlideBar page={page} key={page.key}/>
          }
          return (
            <ListItem
              key={page.key}
              sx={{ marginLeft: "0px", padding: "5px 10px" }}
            >
              <ListItemButton
                sx={{
                  margin:
                    xllaptopUI || openNav || !laptopUI
                      ? "auto auto auto 0"
                      : "auto",
                }}
                selected={select_index}
                // variant={select_index ? "soft" : "plain"}
                color={select_index ? "primary" : undefined}
                onClick={() => {
                  if (page.hasOwnProperty("func")) {
                    page.func();
                  } else handleOnclick(index, page.path);
                }}
                component="a"
                href={page.path}
                title={page.title}
              >
                {xllaptopUI || openNav || !laptopUI ? (
                  <>
                    <ListItemDecorator>{page.icon}</ListItemDecorator>
                    <ListItemContent>{page.title}</ListItemContent>
                  </>
                ) : (
                  <Icon color={select_index ? "secondary" : "action"}>
                    {page.icon}
                  </Icon>
                )}
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
      <Box
        sx={{
          mt: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width:"90%",
          m: 2,
        }}
      >
        <Logout menu= {xllaptopUI || openNav || !laptopUI}/>
      </Box>
    </Sheet>
  ); 
  return (
    <>
      <StyledBox
        sx={{
          display: "flex",
          height: "auto",
          // width: { xl: "12vw" },
          // width: "100%",
          // minHeight : "100vh",
          position:'sticky',
          top: 0,
          flexDirection : (laptopUI ? "row" : "column"),
          justifyContent : "flex-start"
        }}
      >
        {laptopUI ? <div style={{height : "100%", width:(xllaptopUI?"250px":"auto"), display: (openNav ? 'none':'flex'), position:"sticky", top:0}}>
            <SildeBar />
            <IconButton sx={{display: (laptopUI && !xllaptopUI ? 'block':'none'), position:"absolute", marginLeft:'10px', marginTop:"10px", height:'25px', left: "75px" }} variant="outlined" onClick={(e)=>{setOpenNav(!openNav)}}><MenuIcon/></IconButton>
            </div> : 
        <IconButton sx={{ height : "10px", width : "10px"}} variant="plain" onClick={()=>setOpen(true)}>
          <MenuIcon/>
          </IconButton>
        }
        <Modal 
          open={open}
          onClose={() => {setOpen(false)} }
          >
            <div style={{height : "100%", width:"300px"}}>
            <SildeBar />
            </div>
        </Modal>
        <div className="test" style={{height : "100%", width:"300px", display: ((laptopUI && openNav) ? 'flex':'none'), justifyContent:'flex-start',}}>
            <SildeBar />
            <IconButton sx={{display: (laptopUI && !xllaptopUI ? 'block':'none'), marginLeft:'10px', height:'25px', marginTop:"10px",}} variant="outlined" onClick={(e)=>{setOpenNav(!openNav)}}><MenuIcon/></IconButton>
        </div>
        <PopupRender>{popup}</PopupRender>
      </StyledBox>
    </>
  );
}
