import * as React from 'react';
import { useState, useRef, useEffect } from 'react';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffSharpIcon from '@mui/icons-material/VisibilityOffSharp';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import { Link, useNavigate } from 'react-router-dom';
import app from '../../common/sendrequest';
import configs from '../../config';
import CommonPopupRender from "../../common/popuprender";
import './Login.css';
type ServerError = { message: string };
import { isEmpty } from '../../common/expression';
import { FormHelperText, Modal, ModalClose, ModalDialog } from '@mui/joy';
import axios, { AxiosError } from 'axios';
declare global {
  interface Window {
    captchaOnLoad: () => void;
    grecaptcha: any;
  }
}
const initialValidate = { invalid: false, errors: "" };
export default function Login() {
    const inputElement = useRef<HTMLInputElement>(null);
    const [state, setState] = useState({ username: '', password: '' });
    const [validatePwd, setvalidatePwd] = React.useState(initialValidate);
    const [validateUsername, setvalidateUsername] = React.useState(initialValidate);
    const [open, setOpen] = React.useState<boolean>(false);
    const [showPwd, setShowPwd] = React.useState(false);
    const [initPopup, setInitPopup] = useState({
      openFlag: false,
      type: '',
      content: '',
      onPass: ()=>{},
      onCancel: null,
      setOpenFlag: (flag: boolean) => setInitPopup({ ...initPopup, openFlag: flag })
    });
    const navigate = useNavigate();
    const inputPropDefault = {
      input: {
        maxLength: 25,
      },
    };

    const removeRecaptcha = () => {
      const script = document.getElementById('recaptcha-key');
      if (script) {
          script.remove();
      }

      const recaptchaElems = document.getElementsByClassName('grecaptcha-badge');
      if (recaptchaElems.length) {
          recaptchaElems[0].remove();
      }
  };

    useEffect(() => {
      const loadScriptByURL = (id: any, url: any, callback: () => void) => {
        const isScriptExist = document.getElementById(id);
  
        if (!isScriptExist) {
          const script = document.createElement("script");
          script.type = "text/javascript";
          script.src = url;
          script.id = id;
          script.onload = function () {
            if (callback) callback();
          };
          document.body.appendChild(script);
        }
  
        if (isScriptExist && callback) callback();
      };
      // load the script by passing the URL
      loadScriptByURL(
        "recaptcha-key",
        `https://www.google.com/recaptcha/api.js?render=${configs.reCaptchaKey}`,
        () => {
          // console.log('Script recaptcha loaded!');
        }
      );
    }, []);

    const handleSubmit = async (event : any) => {
     try {
      event.preventDefault();
      const password = state.password;
      const username = state.username;

      if (password == "") {
        setvalidatePwd({
          invalid: true,
          errors: "Vui lòng nhập mật khẩu",
        });
      }

      if(username == ""){
        setvalidateUsername({
          invalid: true,
          errors: "Vui lòng nhập usename",
        })
      }

      if(username == "" || password == "")
      {
        setInitPopup({
          ...initPopup,
          openFlag: true,
          type: 'fail',
          content: "Vui lòng nhập đủ thiếu thông tin",
          onPass : ()=>setOpen(false)
        });
        setOpen(true)
        return ;
      }
      await window.grecaptcha.ready(async () => {
        await window.grecaptcha
          .execute(configs.reCaptchaKey, { action: "submit" })
          .then(async (token: any) => {
            const respone = await app.post( configs.auth + "login",{username,password,token});
            if(respone.data){
              delete respone.data.user._id ;
            }
            localStorage.setItem('user',JSON.stringify(respone.data.user))
            removeRecaptcha()
            if(respone.data.user.role == 'root'){
              navigate('/user');
            }
            
            if(respone.data.user.role == 'admin'){
              navigate('/order/pending');
            }
          }).catch((err: any) => {
            if (axios.isAxiosError(err)) {
              const serverError = err as AxiosError<ServerError>;
              if (serverError && serverError.response) {
                setInitPopup({
                  ...initPopup,
                  openFlag: true,
                  type: "fail",
                  content: serverError.response.data.message,
                  onPass: () => {
                  },
                });
              }
            }
          })})
      
      
     } catch (error) {
      setInitPopup({
        ...initPopup,
        openFlag: true,
        type: 'fail',
        content: "Thông tin đăng nhập không chính xác",
        onPass : ()=>{ setOpen(false) }
      });
       setOpen(true)
     }
     
    };

    const handleChange = (event : any) => {
      const name = event.target.name;
      const value = event.target.value;
      setState({ ...state, [name]: value });
    };
    
    const onBlurPwd = (e: any) => {
      if (isEmpty(e.target.value)) {
        setvalidatePwd({
          invalid: true,
          errors: "Vui lòng nhập mật khẩu",
        });
      } else setvalidatePwd({ invalid: false, errors: "" });
    }

    const onBlurUsername = (e: any) => {
      if (isEmpty(e.target.value)) {
        setvalidateUsername({
          invalid: true,
          errors: "Vui lòng nhập tên người dùng",
        });
      } else setvalidateUsername({ invalid: false, errors: "" });
    }

    useEffect(() => {
      !open && inputElement.current !== null && inputElement.current.focus();
    }, [open]);

    return (
      <form onSubmit={handleSubmit} style={{ margin: "auto" }}>
        <Sheet
          className = "loginsheet"
          sx={{
            width: 500,
            maxHeight: 350,
            mx: "auto", // margin left & right
            my: 4, // margin top & bottom
            py: 3, // padding top & bottom
            px: 2, // padding left & right
            display: "flex",
            flexDirection: "column",
            gap: 2,
            borderRadius: "sm",
            boxShadow: "md",
          }}
          variant="outlined"
        >
          <Typography className='titlee' level="h4" component="h1">
            Gia Phả Số | Admin
          </Typography>
          <div style={{ display: "flex", flexDirection: "column", gap: "10px"}}>
            <FormControl>
              <FormLabel>Tài khoản</FormLabel>
              <Input
                name="username"
                autoFocus
                required
                type="text"
                error={validateUsername.invalid}
                placeholder="Tài khoản"
                onChange={handleChange}
                onBlur={onBlurUsername}
                onInvalid={(e) => {
                  (e.target as HTMLInputElement).setCustomValidity(" ");
                }}
                onInput={(e) => {
                  (e.target as HTMLInputElement).setCustomValidity("");
                }}
                slotProps={{
                  input: {
                    maxLength: 25,
                    ref: inputElement,
                  },
                }}
              />
              {/* <div style={{ height: "1vh" }}> */}
                {validateUsername.invalid ? (
                  <FormHelperText sx={{ color: "red" }}>
                    {validateUsername.errors}
                  </FormHelperText>
                ) : (
                  <></>
                )}
              {/* </div> */}
            </FormControl>
            <FormControl>
              <FormLabel>Mật khẩu</FormLabel>
              <Input
                autoFocus={validatePwd.invalid}
                name="password"
                type={showPwd ? "text" : "password"}
                error={validatePwd.invalid}
                required
                placeholder="Mật khẩu"
                onChange={handleChange}
                onBlur={onBlurPwd}
                onInvalid={(e) => {
                  (e.target as HTMLInputElement).setCustomValidity(" ");
                }}
                onInput={(e) => {
                  (e.target as HTMLInputElement).setCustomValidity("");
                }}
                slotProps={inputPropDefault}
                endDecorator={
                  showPwd ? (
                    <VisibilityIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowPwd(!showPwd)}
                    />
                  ):(
                    <VisibilityOffSharpIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowPwd(!showPwd)}
                    />
                  )
                }
              />
              {/* <div style={{ height: "1vh" }}> */}
                {validatePwd.invalid ? (
                  <FormHelperText sx={{ color: "red" }}>
                    {validatePwd.errors}
                  </FormHelperText>
                ) : (
                  <></>
                )}
              {/* </div> */}
            </FormControl>

            <Button sx={{ mt: 1 /* margin top */ }} type="submit">
              Đăng nhập
            </Button>
          </div>
          <CommonPopupRender {...initPopup} />
          {/* <Typography
            endDecorator={<Link to="/quick-add">tại đây</Link>}
            fontSize="sm"
            sx={{ alignSelf: "center" }}
          >
            Đăng kí nhanh tài khoản dùng thử
          </Typography> */}
        </Sheet>
      </form>
    );
}
