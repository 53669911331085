import { useState, useEffect, useRef } from "react";
import app from "../../common/sendrequest";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffSharpIcon from "@mui/icons-material/VisibilityOffSharp";
import * as React from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Typography,
  Box,
  FormHelperText,
  Stack,
  Option,
  Select,
  Modal,
  ModalClose,
  ModalDialog,
  ModalOverflow,
  CircularProgress,
} from "@mui/joy";
import { isEmpty, validatePassWord } from "../../common/expression";
import configs from "../../config";
import axios, { AxiosError } from "axios";
import CommonPopupRender from "../../common/popuprender";
import { validateEmail } from "../../common/expression";
import { Backdrop, createTheme, useMediaQuery } from "@mui/material";
const initialValidate = { invalid: false, errors: "" };
type ServerError = { message: string };
const initState = {
  setting_id:"",
  delete_trial_cycle: 0,
  delete_expired_cycle: 0,
  near_expired_cycle: 0,
  license_expired_cycle: 0,
  domain_expired_cycle: 0,
};
export default function TimeSetting() {
  const [state, setState] = React.useState(initState);
  const [loading, setLoading] = React.useState(false);
  const [validateDelTrial, setvalidateDelTrial] = React.useState(initialValidate);
  const [validateDelExp, setvalidateDelExp] = useState(initialValidate);
  const [validateDomainExp, setvalidateDomainExp] = useState(initialValidate);
  const [validateLicExp, setvalidateLicExp] = useState(initialValidate);
  const [validateNearExp, setvalidateNearExp] = useState(initialValidate);
  const [initPopup, setInitPopup] = useState({
    openFlag: false,
    type: "",
    content: "",
    onPass: () => {},
    onCancel: null,
    setOpenFlag: (flag: boolean) =>
      setInitPopup({ ...initPopup, openFlag: flag }),
  });
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 375,
        md: 900,
        lg: 1280,
        xl: 1536,
      },
    },
  });
  const laptopUI = useMediaQuery(theme.breakpoints.up('lg'));
  
  useEffect(() => {
    getData();
  }, []);
  const getValueSetting = (arr: any, key: string, defaultValue = 0)=> {
    if (arr && arr.length > 0) {
      return (arr!.find((x: any) => x.type_key == key)|| {type_name: defaultValue})!.type_name || defaultValue;
    }
    return defaultValue;
  }
  const getData = async () => {
    await app
      .post(`${configs.api}setting/listall`)
      .then((data: any) => {
        if (data.data) {
          const scheduledata = data.data.find((x: any) => x.key_setting == "schedule");
          setState({
            setting_id: scheduledata._id,
            delete_trial_cycle: getValueSetting(scheduledata.value_setting,"delete_trial_cycle"),
            delete_expired_cycle: getValueSetting(scheduledata.value_setting,"delete_expired_cycle"),
            near_expired_cycle: getValueSetting(scheduledata.value_setting,"near_expired_cycle"),
            license_expired_cycle: getValueSetting(scheduledata.value_setting,"license_expired_cycle"),
            domain_expired_cycle: getValueSetting(scheduledata.value_setting,"domain_expired_cycle"),
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setState(initState);
      });
  };

  const handleError = (err: any) => {
    if (axios.isAxiosError(err)) {
      const serverError = err as AxiosError<ServerError>;
      if (serverError && serverError.response) {
        setInitPopup({
          ...initPopup,
          openFlag: true,
          type: "fail",
          content:  serverError.response.data.message,
        });
      }
    }
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (validateDelTrial.invalid || validateDelExp.invalid || validateNearExp.invalid) {
      return;
    }

    setInitPopup({
      ...initPopup,
      type: "confirmed",
      openFlag: true,
      content: "Bạn có chắc muốn thay đổi cấu hình thời gian không ?",
      onPass: () =>
        app
          .post(`${configs.api}setting/update/${state.setting_id}`, {
            key_setting: 'schedule',
            value_setting: [
              { type_key: "delete_trial_cycle", type_name: state.delete_trial_cycle },
              { type_key: "delete_expired_cycle", type_name: state.delete_expired_cycle },
              { type_key: "near_expired_cycle", type_name: state.near_expired_cycle },
              { type_key: "license_expired_cycle", type_name: state.license_expired_cycle },
              { type_key: "domain_expired_cycle", type_name: state.domain_expired_cycle },
            ],
          })
          .then(() => {
            setInitPopup({
              ...initPopup,
              openFlag: true,
              type: "success",
              content: "Thay đổi thông tin thành công!",
              onPass:()=>getData()
            });
          })
          .catch((err) => {
            handleError(err);
          }),
    });
  };

  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setState({ ...state, [name]: Number(value) });
  };

  const Invalid = (e: any) => {
    const name = e.target.name;
    const value = Number((e.target as HTMLInputElement).value);
    console.log(value)
    if (value < 0 || Number.isNaN(value)) {
      console.log(value)
      if(name == 'delete_trial_cycle')
      {
        setvalidateDelTrial({
          errors: "Vui lòng nhập số ngày từ 0 .",
          invalid: true,
        });
      }
      if(name == 'delete_expired_cycle')
      {
        setvalidateDelExp({
          errors: "Vui lòng nhập số ngày từ 0 .",
          invalid: true,
        });
      }
      if(name == 'near_expired_cycle')
      {
        setvalidateNearExp({
          errors: "Vui lòng nhập số ngày từ 0 .",
          invalid: true,
        });
      }
      if(name == 'license_expired_cycle')
      {
        setvalidateLicExp({
          errors: "Vui lòng nhập số ngày từ 0 .",
          invalid: true,
        });
      }
      (e.target as HTMLInputElement).setCustomValidity(" ");
    } else {
      if(name == 'delete_trial_cycle')
      {
        setvalidateDelTrial(initialValidate);
      }
      if(name == 'delete_expired_cycle')
      {
        setvalidateDelExp(initialValidate);
      }
      if(name == 'near_expired_cycle')
      {
        setvalidateNearExp(initialValidate);
      }
      if(name == 'license_expired_cycle')
      {
        setvalidateLicExp(initialValidate);
      }
      (e.target as HTMLInputElement).setCustomValidity("");
    }
  };


  const onInput = (e: any) => {
    console.log(e.target.value);
    if (Number((e.target as HTMLInputElement).value) < 0 || Number.isNaN(Number((e.target as HTMLInputElement).value))) {
      (e.target as HTMLInputElement).value = "0";
      (e.target as HTMLInputElement).setCustomValidity(" ");
    }else{
      const name = e.target.name;
      if(name == 'delete_trial_cycle')
      {
        setvalidateDelTrial(initialValidate);
      }
      if(name == 'delete_expired_cycle')
      {
        setvalidateDelExp(initialValidate);
      }
      if(name == 'near_expired_cycle')
      {
        setvalidateNearExp(initialValidate);
      }
      if(name == 'license_expired_cycle')
      {
        setvalidateLicExp(initialValidate);
      }
      (e.target as HTMLInputElement).setCustomValidity("");
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginBottom: "auto",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "90%",
          marginLeft: laptopUI ? "5%" : "3%",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Typography
          level="h3"
          component="h1"
          sx={{ alignSelf: "center", padding: "2vh" }}
        >
          Cấu hình thời gian
        </Typography>
        <Box
          sx={(theme) => ({
            width: "100%",
            maxWidth: "1200px",
            borderRadius: "lg",
            boxShadow: "sm",
            overflow: "auto",
            border: `1px solid ${theme.vars.palette.divider}`,
            margin: "auto",
            padding: "2% 2%",
          })}
        >
          <form onSubmit={onSubmit} style={{ width: "80%", margin: "auto" }}>
            <Stack
              spacing={2}
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <FormControl sx={{ width: "100%" }} required>
                <FormLabel>Số ngày gửi email thông báo sắp hết hạn</FormLabel>
                <div>
                  <Input
                    name="near_expired_cycle"
                    type="number"
                    error={validateNearExp.invalid}
                    sx={{ width: "100%" }}
                    onChange={handleChange}
                    slotProps={{
                      input: {
                        min: 0,
                      },
                    }}
                    value={state.near_expired_cycle}
                    onInput={onInput}
                    onInvalid={Invalid}
                  />
                  {validateNearExp.invalid ? (
                    <FormHelperText sx={{ color: "red" }}>
                      {validateNearExp.errors}
                    </FormHelperText>
                  ) : (
                    <></>
                  )}
                </div>
              </FormControl>
              <FormControl sx={{ width: "100%" }} required>
                <FormLabel>Số ngày xóa dữ liệu tài khoản hết hạn</FormLabel>
                <div>
                  <Input
                    name="delete_expired_cycle"
                    type="number"
                    error={validateDelExp.invalid}
                    sx={{ width: "100%" }}
                    onChange={handleChange}
                    slotProps={{
                      input: {
                        min: 0,
                      },
                    }}
                    onInput={onInput}
                    onInvalid={Invalid}
                    value={state.delete_expired_cycle}
                  />
                  {validateDelExp.invalid ? (
                    <FormHelperText sx={{ color: "red" }}>
                      {validateDelExp.errors}
                    </FormHelperText>
                  ) : (
                    <></>
                  )}
                </div>
              </FormControl>
              <FormControl sx={{ width: "100%" }} required>
                <FormLabel>Số ngày xóa dữ liệu tài khoản dùng thử (Hết hạn và Số thành viên = 0)</FormLabel>
                <div>
                  <Input
                    name="delete_trial_cycle"
                    type="number"
                    error={validateDelTrial.invalid}
                    sx={{ width: "100%" }}
                    onChange={handleChange}
                    slotProps={{
                      input: {
                        min: 0,
                      },
                    }}
                    value={state.delete_trial_cycle}
                    onInput={onInput}
                    onInvalid={Invalid}
                  />
                  {validateDelTrial.invalid ? (
                    <FormHelperText sx={{ color: "red" }}>
                      {validateDelTrial.errors}
                    </FormHelperText>
                  ) : (
                    <></>
                  )}
                </div>
              </FormControl>
              <FormControl sx={{ width: "100%" }} required>
                <FormLabel>Số ngày hết hạn mã kích hoạt</FormLabel>
                <div>
                  <Input
                    name="license_expired_cycle"
                    type="number"
                    error={validateLicExp.invalid}
                    sx={{ width: "100%" }}
                    onChange={handleChange}
                    slotProps={{
                      input: {
                        min: 0,
                      },
                    }}
                    onInput={onInput}
                    onInvalid={Invalid}
                    value={state.license_expired_cycle}
                  />
                  {validateLicExp.invalid ? (
                    <FormHelperText sx={{ color: "red" }}>
                      {validateDelExp.errors}
                    </FormHelperText>
                  ) : (
                    <></>
                  )}
                </div>
              </FormControl>
              <FormControl sx={{ width: "100%" }} required>
                <FormLabel>Số ngày hết hạn mã đăng ký tên miền</FormLabel>
                <div>
                  <Input
                    name="domain_expired_cycle"
                    type="number"
                    error={validateDomainExp.invalid}
                    sx={{ width: "100%" }}
                    onChange={handleChange}
                    slotProps={{
                      input: {
                        min: 0,
                      },
                    }}
                    onInput={onInput}
                    onInvalid={Invalid}
                    value={state.domain_expired_cycle}
                  />
                  {validateDomainExp.invalid ? (
                    <FormHelperText sx={{ color: "red" }}>
                      {validateDomainExp.errors}
                    </FormHelperText>
                  ) : (
                    <></>
                  )}
                </div>
              </FormControl>
              <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                <Button sx={{ width: "100px",marginRight: "15px" }} type="submit">
                  Cập nhật
                </Button>
              </Box>
            </Stack>
          </form>
        </Box>

        <CommonPopupRender {...initPopup} />
        <Backdrop
          sx={{
            background: "rgba(245, 245, 245, 0.6)",
            zIndex: 10000,
            backdropFilter: "blur(5px)",
          }}
          open={loading}
          transitionDuration={{
            appear: 1,
            exit: 1,
          }}
        >
          <CircularProgress thickness={4} size="lg" variant="plain" />
        </Backdrop>
      </div>
    </Box>
  );
}
