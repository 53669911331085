import { useState, useEffect, useRef } from "react";
import app from "../../common/sendrequest";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffSharpIcon from "@mui/icons-material/VisibilityOffSharp";
import * as React from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Typography,
  Box,
  FormHelperText,
  Stack,
  Option,
  Select,
  Modal,
  ModalClose,
  ModalDialog,
  ModalOverflow,
  CircularProgress,
} from "@mui/joy";
import { isEmpty, validatePassWord } from "../../common/expression";
import configs from "../../config";
import axios, { AxiosError } from "axios";
import CommonPopupRender from "../../common/popuprender";
import { validateEmail } from "../../common/expression";
import { Backdrop, createTheme, useMediaQuery } from "@mui/material";
const initialValidate = { invalid: false, errors: "" };
type ServerError = { message: string };
const initState = {
  emailsetting_id:"",
  password: "",
  user_name: "",
  address: "",
};

export default function DomainSetting() {
  const [state, setState] = React.useState(initState);
  const [showPwd, setShowPwd] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [validatePwd, setvalidatePwd] = React.useState(initialValidate);
  const [validateUsername, setvalidateUsername] = useState(initialValidate);
  const [validateAddress, setvalidateAddress] = useState(initialValidate);
  const [initPopup, setInitPopup] = useState({
    openFlag: false,
    type: "",
    content: "",
    onPass: () => {},
    onCancel: null,
    setOpenFlag: (flag: boolean) =>
      setInitPopup({ ...initPopup, openFlag: flag }),
  });
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 375,
        md: 900,
        lg: 1280,
        xl: 1536,
      },
    },
  });
  const laptopUI = useMediaQuery(theme.breakpoints.up('lg'));
  
  useEffect(() => {
    getData();
  }, []);
  const getValueSetting = (arr: any, key: string, defaultValue = '')=> {
    if (arr && arr.length > 0) {
      return arr!.find((x: any) => x.type_key == key)!.type_name || defaultValue;
    }
    return defaultValue;
  }
  const getData = async () => {
    await app
      .post(`${configs.api}setting/listall`)
      .then((data: any) => {
        if (data.data) {
          const emaildata = data.data.find((x: any) => x.key_setting == "domain_config");
          setState({
            user_name: getValueSetting(emaildata.value_setting,"user_name"),
            password: Buffer.from(getValueSetting(emaildata.value_setting,"password").trim().substring(3), 'base64').toString('ascii'),
            emailsetting_id: emaildata._id ? emaildata._id : "",
            address: getValueSetting(emaildata.value_setting,"address"),
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setState(initState);
      });
  };

  const handleError = (err: any) => {
    if (axios.isAxiosError(err)) {
      const serverError = err as AxiosError<ServerError>;
      if (serverError && serverError.response) {
        setInitPopup({
          ...initPopup,
          openFlag: true,
          type: "fail",
          content:  serverError.response.data.message,
        });
      }
    }
  };

  const onBlurPwd = (e: any) => {
    if (isEmpty(e.target.value)) {
      setvalidatePwd({
        invalid: true,
        errors: "Mật khẩu không được để trống",
      });
    } else if (!validatePassWord(e.target.value)) {
      setvalidatePwd({
        invalid: true,
        errors: "Mật khẩu sai định dạng",
      });
    } else setvalidatePwd({ invalid: false, errors: "" });
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (validatePwd.invalid && state.password == "") {
      return;
    }

    if (validateAddress.invalid && state.address == "") {
      return;
    }

    if (validateUsername.invalid && state.user_name == "") {
      return;
    }
     
    setInitPopup({
      ...initPopup,
      type: "confirmed",
      openFlag: true,
      content: "Bạn có chắc muốn thay đổi cấu hình domain không ?",
      onPass: () =>
        app
          .post(`${configs.api}setting/update/${state.emailsetting_id}`, {
            key_setting : "domain_config",
            value_setting: [
              { type_key: "user_name", type_name: state.user_name },
              { type_key: "password", type_name: state.password },
              { type_key: "address", type_name: state.address },
            ],
          })
          .then(() => {
            setInitPopup({
              ...initPopup,
              openFlag: true,
              type: "success",
              content: "Thay đổi thông tin thành công!",
            });
            getData();
          })
          .catch((err) => {
            handleError(err);
          }),
    });
  };

  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setState({ ...state, [name]: value });
  };

  const onBlurEmpty = (e: any) => {
    if (isEmpty(e.target.value)) {
      if (e.target.name == "user_name")
        setvalidateUsername({
          invalid: true,
          errors: "Tài khoản không để trống",
        });
      
      if (e.target.name == "address")
        setvalidateAddress({
          invalid: true,
          errors: "Địa chỉ không để trống",
        });
      
    }else{
      if (e.target.name == "user_name")
        setvalidateUsername(initialValidate);
      
      if (e.target.name == "address")
        setvalidateAddress(initialValidate);
      
    }
  }


  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginBottom: "auto",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "90%",
          marginLeft: laptopUI ? "5%" : "3%",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Typography
          level="h3"
          component="h1"
          sx={{ alignSelf: "center", padding: "2vh" }}
        >
          Cấu hình Domain
        </Typography>
        <Box
          sx={(theme) => ({
            width: "100%",
            maxWidth: "1200px",
            borderRadius: "lg",
            boxShadow: "sm",
            overflow: "auto",
            border: `1px solid ${theme.vars.palette.divider}`,
            margin: "auto",
            padding: "2% 2%",
          })}
        >
          <form onSubmit={onSubmit} style={{ width: "80%", margin: "auto" }}>
            <Stack
              spacing={2}
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <FormControl sx={{ width: "100%" }} required>
                <FormLabel>Tài khoản</FormLabel>
                <div>
                  <Input
                    name="user_name"
                    type="text"
                    error={validateUsername.invalid}
                    sx={{ width: "100%" }}
                    onChange={handleChange}
                    slotProps={{
                      input: {
                        maxLength: 50,
                      },
                    }}
                    value={state.user_name}
                    onBlur={onBlurEmpty}
                  />
                  {validateUsername.invalid ? (
                    <FormHelperText sx={{ color: "red" }}>
                      {validateUsername.errors}
                    </FormHelperText>
                  ) : (
                    <></>
                  )}
                </div>
              </FormControl>
              <FormControl sx={{ width: "100%" }} required>
                <FormLabel sx={{ alignSelf: "flex-start" }}>Mật khẩu</FormLabel>
                <div>
                  <Input
                    name="password"
                    type={showPwd ? "text" : "password"}
                    sx={{ width: "100%" }}
                    value={state.password}
                    onBlur={onBlurPwd}
                    onChange={handleChange}
                    error={validatePwd.invalid}
                    endDecorator={
                      showPwd ? (
                        <VisibilityIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => setShowPwd(!showPwd)}
                        />
                      ) : (
                        <VisibilityOffSharpIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => setShowPwd(!showPwd)}
                        />
                      )
                    }
                  />
                  {validatePwd.invalid ? (
                    <FormHelperText sx={{ color: "red" }}>
                      {validatePwd.errors}
                    </FormHelperText>
                  ) : (
                    <></>
                  )}
                </div>
              </FormControl>
              <FormControl sx={{ width: "100%" }} required>
                <FormLabel>Địa chỉ</FormLabel>
                <div>
                  <Input
                    name="address"
                    type="text"
                    error={validateAddress.invalid}
                    sx={{ width: "100%" }}
                    onChange={handleChange}
                    slotProps={{
                      input: {
                        maxLength: 250,
                      },
                    }}
                    value={state.address}
                    // onBlur={onBlurEmail}
                  />
                  {validateAddress.invalid ? (
                    <FormHelperText sx={{ color: "red" }}>
                      {validateAddress.errors}
                    </FormHelperText>
                  ) : (
                    <></>
                  )}
                </div>
              </FormControl>
              <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                <Button sx={{ width: "100px",marginRight: "15px" }} type="submit">
                  Cập nhật
                </Button>
              </Box>
            </Stack>
          </form>
        </Box>

        <CommonPopupRender {...initPopup} />
        <Backdrop
          sx={{
            background: "rgba(245, 245, 245, 0.6)",
            zIndex: 10000,
            backdropFilter: "blur(5px)",
          }}
          open={loading}
          transitionDuration={{
            appear: 1,
            exit: 1,
          }}
        >
          <CircularProgress thickness={4} size="lg" variant="plain" />
        </Backdrop>
      </div>
    </Box>
  );
}
