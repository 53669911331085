import { useState, useEffect } from "react";
import app from "../../common/sendrequest";
import ClearIcon from '@mui/icons-material/Clear';
import * as React from "react";
import './DetailCustomer.css';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Typography,
  Card,
  Select,
  Option,
  Stack,
  FormHelperText,
  ChipDelete,
  Box,
  CircularProgress,
  Radio,
  RadioGroup,
  Link,
  Chip,
} from "@mui/joy";
import { useNavigate, useParams } from "react-router-dom";
import { isEmpty, isLetter, validateHost, validateURL } from "../../common/expression";
import { validatePhone } from "../../common/expression";
import { validateEmail } from "../../common/expression";
import { Package } from "../packages/Package.type";
import configs from "../../config";
import axios, { AxiosError } from "axios";
import CommonPopupRender from "../../common/popuprender";
import { Backdrop, createTheme, useMediaQuery } from "@mui/material";
import DatePicker from "react-datepicker";
import CalendarTodaySharpIcon from '@mui/icons-material/CalendarTodaySharp';
import { vi } from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css";
const initialValidate = { invalid: false, errors: "" };
type ServerError = { message: string };

let initState = {
  full_name: "",
  family_name: "",
  email: "",
  gender: 0,
  phone: "",
  package_id: "",
  create_at:"",
  exprire_at:"",
  domain: [] as string[],
}
export default function InforCustomer() {
  const [birth_day, setBirthday] = React.useState<Date>();
  const [state, setState] = React.useState(initState);
  const [currentdomain, setCurrentDomain] = useState("");
  const [list_add_domain, setListAddDomain] = useState<string[]>([]);
  const [list_delete_domain, setListDeleteDomain] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [current_domain_list, setCurrentDomainList] = useState<string[]>([]);
  const [pickdomain, setPickDomain] = useState("1");
  const [domaintype, setDomainType] = useState<string | null> ("1");
  const [checklength, setCheckLength] = useState(0);
  const checkDomain = Array.from(new Set<string>(state.domain));
  const [validateName, setvalidateName] = useState(initialValidate);
  const [validateFamilyName, setvalidateFamilyName] = useState(initialValidate);
  const [validateEmails, setvalidateEmail] = useState(initialValidate);
  const [validatePhones, setvalidatePhone] = useState(initialValidate);
  const [validateDomain, setvalidateDomain] = useState(initialValidate);
  const [oldpackage_id, setOldPackages] = useState("");
  const [list_package, setListPack] = useState<Package[]>([]);
  const [initPopup, setInitPopup] = useState({
    openFlag: false,
    type: "",
    content: "",
    onPass: () => {},
    onCancel: () => {},
    setOpenFlag: (flag: boolean) =>
      setInitPopup({ ...initPopup, openFlag: flag }),
  });
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 375,
        md: 900,
        lg: 1280,
        xl: 1536,
      },
    },
  });
  const laptopUI = useMediaQuery(theme.breakpoints.up('lg'));
  const handleChipdelete = (option : string)=>{
    if(option.endsWith('giaphaso.com'))
    {
      const delete_data = option.replace('.giaphaso.com','').replace('https://','');
      if(delete_data){
        if(current_domain_list.includes(option))
        {
          setListDeleteDomain(list_delete_domain.concat(delete_data));
        }
        if(list_add_domain.includes(delete_data)){
          setListAddDomain(list_add_domain.filter(x => x != delete_data));
        }
      }
    }
    setState({
      ...state,
      domain: state.domain.filter((entry) => entry !== option),
    })
  }

  const handleChipChange = (event : any, index : number)=>{
    const value = event.target.value;
    if(validateURL(value.trim()) && value != "")
    {
      setState({
        ...state,
        domain: state.domain.map((x, i) => {
          if (i == index) {
            if(x.includes('giaphaso.com'))
            {
              const delete_data = x.replace('.giaphaso.com','').replace('https://','');
              if(delete_data){
                setListDeleteDomain(list_delete_domain.concat(delete_data));
              }
            }
            if(value.includes('giaphaso.com'))
            {
              const add_data = value.replace('.giaphaso.com','').replace('https://','');
              if(add_data){
                setListAddDomain(list_add_domain.concat(add_data.trim()));
              }
            }
            x = value.toLowerCase();
          }
          return x;
        }),
      });
    }
  }
  

  const ChipRender = ({option,index} : {option : any, index: any}) => {
      const [label, setLabel] = useState(option);
      return (
        <Chip
          // level='body-md'
          size="md"
          startDecorator={
            <Link
              sx={{zIndex: 100,}}
              onClick={() => window.open(label, "_blank", "noopener,noreferrer")}
            >
            {label}
            </Link>
          }
          
          sx={{
            pl: 1,
            py: 0.5,
            zIndex: 100,
            maxWidth: "inherit",
            my: "5px",
            borderRadius: "100px",
            justifyContent: "space-between",
            '.MuiChip-startDecorator': {
              pointerEvents: 'auto'
            }
          }}
          variant="soft"
          color="primary"
          endDecorator={
            <ChipDelete
              sx={{ height: "30px", width: "30px" }}
              onDelete={() => handleChipdelete(option)}
            />
          }
        >
          
        </Chip>
      );
  };

  let navigate = useNavigate();
  let { id, familyid } = useParams();

  useEffect(() => {
    sessionStorage.setItem('cus_searchname_next',sessionStorage.getItem('cus_searchname')||"{}");
    sessionStorage.removeItem('pending_searchname_next');
    getData();
  }, []);


  const getData = async () => {
    
    await app
      .get(`${configs.customer}${id}?family_id=${familyid}`)
      .then((data: any) => {
        let domain = [] as string[];
        setPickDomain("1");
        if(data.data)
        {
          if(data.data.family.domain && data.data.family.domain.length > 0)
          {
            if (data.data.family.domain.length > configs.trial_domain.length) {
              domain = Array.from (new Set(data.data.family.domain)) as string[];
              setPickDomain("2");
            }else {
              for(let i = 0; i< data.data.family.domain.length; i++){
                if(!configs.trial_domain.includes(data.data.family.domain[i]))
                {
                  domain = Array.from (new Set(data.data.family.domain)) as string[];
                  setPickDomain("2");
                  break;
                }
              }
            }
            // if(data.data.family.domain[0] != configs.trial_domain[0])
            // {
            //   domain = Array.from (new Set(data.data.family.domain)) as string[];
            //   setPickDomain("2");
            // }
          }
          setCurrentDomainList(domain);
          setState({
            full_name: data.data.full_name ? data.data.full_name : "",
            family_name: data.data.family.family_name,
            email: data.data.email ? data.data.email : "",
            phone: data.data.phone ? data.data.phone : "",
            gender: data.data.gender ? data.data.gender : 0,
            package_id: data.data.family.package_id,
            domain: domain,
            create_at: data.data.family.package_start_date,
            exprire_at: data.data.family.package_expiration_date
          });
          if(data.data.birth_day)
          {
            setBirthday(new Date(data.data.birth_day))
          }
          setCheckLength(data.data.family.domain ? data.data.family.domain : (data.data.domain.length ? data.data.domain : 0))
  
          setOldPackages(data.data.family.package_id);
        }else{
          setInitPopup({
            ...initPopup,
            openFlag: true,
            type: "fail",
            content: `Không có dữ liệu của tài khoản này!`,
            onPass:()=>{navigate('/customer')}
          });
        }
      })
      .catch((err) => {
        handleError(err);
        setState(initState);
        setOldPackages("");
      });
    await app
      .post(configs.api + "package/list_all",{
        is_active : 'all',
        is_public : 'all',
        package_type : 'TYPE00001',
      })
      .then((response) => setListPack(response.data.original))
      .catch((err)=>setListPack([]));
  };
  const handleError = (err: any) => {
    if (axios.isAxiosError(err)) {
      const serverError = err as AxiosError<ServerError>;
      if (serverError && serverError.response) {
        setInitPopup({
          ...initPopup,
          openFlag: true,
          type: "fail",
          content:  serverError.response.data.message,
        });
      }
    }
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    
    let cycleday = 0;
    let price = 0;
    let domain = [] as string[];
    if (list_package.find((x) => x._id == state.package_id)) {
      cycleday =
        list_package.find((x) => x._id == state.package_id)!.cycle_day || 0;
    }
    if (list_package.find((x) => x._id == state.package_id)) {
      price =
        list_package.find((x) => x._id == state.package_id)!.price || 0;
    }
    if (
      validateName.invalid ||
      validateEmails.invalid ||
      validatePhones.invalid
    ) {
      return;
    }

    if (state.domain.length != checkDomain.length) {
      setInitPopup({
        ...initPopup,
        openFlag: true,
        type: "fail",
        content: "Xuất hiện domain trùng.",
      });
      return;
    }

    if (state.domain.length == 0 && pickdomain == '2') {
      setInitPopup({
        ...initPopup,
        openFlag: true,
        type: "fail",
        content: "Vui lòng thêm ít nhất một domain",
      });
      return;
    }

    if(pickdomain == '1')
    {
      domain = configs.trial_domain;
    }

    if(pickdomain == '2')
    {
      domain = state.domain;
    }

    if (!list_package.some(x=>x._id == state.package_id)) {
      setInitPopup({
        ...initPopup,
        openFlag: true,
        type: "fail",
        content: "Vui lòng chọn gói dịch vụ chính",
      });
      return;
    }
    
    setInitPopup({
      ...initPopup,
      type: "confirmed",
      openFlag: true,
      content: "Bạn có chắc muốn thay đổi thông tin tài khoản này không ?",
      onPass: () =>{
        setLoading(true);
        app.post(`${configs.customer}${id}?family_id=${familyid}`, {
          full_name: state.full_name,
          family_name: state.family_name,
          email: state.email,
          phone: state.phone,
          birth_day: birth_day,
          gender: state.gender,
          // package_id: state.package_id,
          cycleday,
          // prev_package_id: oldpackage_id,
          domain: domain,
          list_delete_domain,
          list_add_domain: pickdomain == '1' ? [] : list_add_domain,
          create_at: state.create_at,
          exprire_at: state.exprire_at,
          // order_check: orderflag,
          price,
          package_name: list_package.find(x=>x._id == state.package_id)!.package_name,
          // email_check: (state.domain.length == 1 && checklength == 0),
          pwd: configs.default_pwd,
        })
        .then(() => {
          setLoading(false);
          setInitPopup({
            ...initPopup,
            openFlag: true,
            type: "success",
            content: "Thay đổi thông tin thành công!",
            onPass:()=>{navigate('/customer')},
            onCancel:()=>{navigate('/customer')},
          });
        })
        .catch((err) => {
          setLoading(false);
          handleError(err);
          setListAddDomain([]);
        });
      }
    });
  };

  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setState({ ...state, [name]: value });
  };

  const onBlurEmail = (e: any) => {
    if (isEmpty(e.target.value)) {
      setvalidateEmail({
        invalid: true,
        errors: "Email không được để trống",
      });
    } else if (!validateEmail(e.target.value)) {
      setvalidateEmail({
        invalid: true,
        errors: "Email sai định dạng",
      });
    } else setvalidateEmail({ invalid: false, errors: "" });
  };

  const onBlurNameHandler = (e: any) => {
    if (isEmpty(e.target.value)) {
      setvalidateName({
        invalid: true,
        errors: "Họ và tên không được để trống",
      });
    } else {
      setvalidateName({ invalid: false, errors: "" });
    }
  };

  const onBlurFamilyNameHandler = (e: any) => {
    if (isEmpty(e.target.value)) {
      setvalidateFamilyName({
        invalid: true,
        errors: "Tên dòng họ không được để trống",
      });
    } else {
      setvalidateFamilyName({ invalid: false, errors: "" });
    }
  };

  const onBlurPhone = (e: any) => {
    if (isEmpty(e.target.value)) {
      setvalidatePhone({
        invalid: true,
        errors: "Số điện thoại không được để trống",
      });
    } else if (!validatePhone(e.target.value)) {
      setvalidatePhone({
        invalid: true,
        errors: "Số điện thoại sai định dạng",
      });
    } else setvalidatePhone({ invalid: false, errors: "" });
  };

  const onValidFullName = (e : any) => {
    if (isEmpty((e.target as HTMLInputElement).value)) {
      setvalidateName({
        invalid: true,
        errors: "Họ và tên không được để trống",
      });
    }
    (e.target as HTMLInputElement).setCustomValidity(" ");
  }

  const onInputFamilyName = (e :any) => {
    (e.target as HTMLInputElement).setCustomValidity("");
    setvalidateFamilyName({ invalid: false, errors: "" });
  }

  const onValidFamilyName = (e : any) => {
    if (isEmpty((e.target as HTMLInputElement).value)) {
      setvalidateFamilyName({
        invalid: true,
        errors: "Tên dòng họ không được để trống",
      });
    }
    (e.target as HTMLInputElement).setCustomValidity(" ");
  }

  const onInputFullName = (e :any) => {
    (e.target as HTMLInputElement).setCustomValidity("");
    setvalidateName({ invalid: false, errors: "" });
  }
  const onValidEmail = (e : any) => {
    (e.target as HTMLInputElement).setCustomValidity(" ");
    if (isEmpty((e.target as HTMLInputElement).value)) {
      setvalidateEmail({
        invalid: true,
        errors: "Email không được để trống",
      });
    }
  }
  const onInputEmail = (e :any) => {
    (e.target as HTMLInputElement).setCustomValidity("");
    setvalidateEmail({invalid: false,errors: "",});
  }

  const onValidPhone = (e : any) => {
    (e.target as HTMLInputElement).setCustomValidity(" ");
    if (isEmpty((e.target as HTMLInputElement).value)) {
      setvalidatePhone({
        invalid: true,
        errors: "Số điện thoại không được để trống",
      });
    }
  }
  const onInputPhone = (e :any) => {
    (e.target as HTMLInputElement).setCustomValidity("");
    setvalidatePhone({ invalid: false, errors: "" });
  }

  const handleDateChange = (date: any) => {
    // setState({ ...state, birth_day: date });
    setBirthday(date)
  };

  const onChangePackage = (e: any, newValue : any) => {
    setState({
      ...state,
      package_id: newValue || list_package[0]._id,
    });
  }

  const onAddDomain = () => {
    if (
      currentdomain.length > 0 &&
      !state.domain.some((x) => x == currentdomain.trim())
    ) {

      if (!validateURL(currentdomain.trim()) && domaintype != "1" || currentdomain.trim().endsWith('giaphaso.com')) {
        setvalidateDomain({
          invalid: true,
          errors: "Đường dẫn không đúng định dạng",
        });
      } else if (!validateHost(currentdomain.trim()) && domaintype == '1') {
        setvalidateDomain({
          invalid: true,
          errors: "Đường dẫn không đúng định dạng",
        });
      } else {
        setvalidateDomain({
          invalid: false,
          errors: "",
        });
        let new_domain = currentdomain.trim().toLowerCase();
        if (domaintype == "1") {
          new_domain = `https://${currentdomain.trim()}.giaphaso.com`;
          if(!state.domain.includes(new_domain)){
            setListAddDomain(list_add_domain.concat(currentdomain.trim()));
          }
        }
        setState({
          ...state,
          domain: Array.from(new Set(state.domain.concat(new_domain))),
        });
        setCurrentDomain("");
      }
    } else {
      setvalidateDomain({
        invalid: true,
        errors: "Đường dẫn trống hoặc đã xuất hiện trong danh sách",
      });
    }
  };


  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginBottom: "auto",
      }}
    >
      <div style={{ width: "90%", marginLeft: laptopUI ? "4%" : "1%" }}>
        <div>
          <Typography
            level="h3"
            component="h1"
            sx={{ alignSelf: "center", textAlign: "center", padding: "2vh" }}
          >
            Thông tin khách hàng
          </Typography>
          <Card
            variant="outlined"
            sx={{
              marginLeft: "auto",
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <form
              onSubmit={onSubmit}
              style={{ width: "100%", maxWidth: "800px" }}
            >
              <Stack
                spacing={2}
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <FormControl sx={{ width: "100%" }} required>
                  <FormLabel>Họ và tên</FormLabel>
                  <Input
                    required
                    className="InputForm"
                    name="full_name"
                    type="text"
                    error={validateName.invalid}
                    sx={{ width: "100%" }}
                    slotProps={{
                      input: {
                        maxLength: 250,
                      },
                    }}
                    onChange={handleChange}
                    value={state.full_name}
                    onBlur={onBlurNameHandler}
                    onInvalid={onValidFullName}
                    onInput={onInputFullName}
                  />
                  {validateName.invalid ? (
                    <FormHelperText sx={{ color: "red" }}>
                      {validateName.errors}
                    </FormHelperText>
                  ) : (
                    <></>
                  )}
                </FormControl>
                <FormControl sx={{ width: "100%" }} required>
                  <FormLabel>Tên dòng họ</FormLabel>
                  <Input
                    required
                    className="InputForm"
                    name="family_name"
                    type="text"
                    error={validateFamilyName.invalid}
                    sx={{ width: "100%" }}
                    slotProps={{
                      input: {
                        maxLength: 250,
                      },
                    }}
                    onChange={handleChange}
                    value={state.family_name}
                    onBlur={onBlurFamilyNameHandler}
                    onInvalid={onValidFamilyName}
                    onInput={onInputFamilyName}
                  />
                  {validateFamilyName.invalid ? (
                    <FormHelperText sx={{ color: "red" }}>
                      {validateFamilyName.errors}
                    </FormHelperText>
                  ) : (
                    <></>
                  )}
                </FormControl>
                <Box
                  sx={{
                    display: { xs: "block", md: "grid" },
                    width: "100%",
                    gridTemplateColumns: "64% 34%",
                    columnGap: "2%",
                  }}
                >
                  <FormControl
                    sx={{
                      width: "100%",
                      marginBottom: { xs: "15px", md: "0px" },
                    }}
                    required
                  >
                    <FormLabel>Email</FormLabel>
                    <Input
                      required
                      className="InputForm"
                      name="email"
                      type="email"
                      sx={{ width: "100%" }}
                      onChange={handleChange}
                      error={validateEmails.invalid}
                      value={state.email}
                      onBlur={onBlurEmail}
                      onInvalid={onValidEmail}
                      onInput={onInputEmail}
                    />
                    {validateEmails.invalid ? (
                      <FormHelperText sx={{ color: "red" }}>
                        {validateEmails.errors}
                      </FormHelperText>
                    ) : (
                      <></>
                    )}
                  </FormControl>
                  <FormControl sx={{ width: "100%" }} required>
                    <FormLabel>Số điện thoại</FormLabel>
                    <Input
                      required
                      className="InputForm"
                      name="phone"
                      type="text"
                      sx={{ width: "100%" }}
                      onChange={handleChange}
                      error={validatePhones.invalid}
                      value={state.phone}
                      onBlur={onBlurPhone}
                      onInvalid={onValidPhone}
                      onInput={onInputPhone}
                    />
                    {validatePhones.invalid ? (
                      <FormHelperText sx={{ color: "red" }}>
                        {validatePhones.errors}
                      </FormHelperText>
                    ) : (
                      <></>
                    )}
                  </FormControl>
                </Box>
                <Box
                  sx={{
                    display: { xs: "block", md: "grid" },
                    width: "100%",
                    gridTemplateColumns: "64% 34%",
                    columnGap: "2%",
                  }}
                >
                  <FormControl
                    sx={{
                      width: "100%",
                      marginBottom: { xs: "15px", md: "0px" },
                    }}
                  >
                    <FormLabel>Ngày sinh</FormLabel>
                    {/* <Input
                    name="birth_day"
                    className="InputForm"
                    type="date"
                    sx={{ width: "100%", pt: "8px" }}
                    onChange={handleChange}
                    value={state.birth_day}
                  /> */}
                    <DatePicker
                      onChange={handleDateChange}
                      selected={birth_day}
                      customInput={
                        <Input
                          className="InputForm"
                          name="birth_day"
                          sx={{ width: "100%" }}
                          endDecorator={
                            <CalendarTodaySharpIcon fontSize="small" />
                          }
                        />
                      }
                      locale={vi}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="dd/mm/yyyy"
                    />
                  </FormControl>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel>Giới tính</FormLabel>
                    <Select
                      name="genders"
                      className="InputForm"
                      sx={{ width: "100%" }}
                      value={state.gender.toString()}
                      onChange={(e: any, newValue) => {
                        setState({ ...state, gender: Number(newValue) });
                      }}
                    >
                      <Option value="1"> Nam</Option>
                      <Option value="2"> Nữ </Option>
                    </Select>
                  </FormControl>
                </Box>
                <FormControl sx={{ width: "100%" }} required>
                  <FormLabel>Gói dịch vụ chính</FormLabel>
                  <Select
                    name="package"
                    disabled
                    className="InputForm"
                    value={state.package_id}
                    // onChange={onChangePackage}
                    sx={{ width: "100%" }}
                  >
                    {list_package.map((x) => {
                      return (
                        <Option key={`${x._id}`} value={`${x._id}`}>
                          {x.package_name}
                        </Option>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl sx={{ width: "100%" }} required>
                  <FormLabel>Domain</FormLabel>
                  <RadioGroup
                    defaultValue="outlined"
                    name="radio-buttons-group"
                    value={pickdomain}
                    onChange={(event) => {
                      setPickDomain(event.target.value);
                      if(event.target.value == '1')
                      {
                        const list_delete = [] as string[];
                        current_domain_list.map((option) => {
                          if (option.endsWith("giaphaso.com")) {
                            const delete_data = option
                              .replace(".giaphaso.com", "")
                              .replace("https://", "");
                            if (delete_data) {
                              list_delete.push(delete_data);
                            }
                          }
                        });
                        setListDeleteDomain(Array.from(new Set(list_delete_domain.concat(list_delete))));
                      }else{
                        setListDeleteDomain([]);
                      }
                    }}
                  >
                    <Radio
                      color="primary"
                      value="1"
                      label={
                        <FormControl
                          sx={{
                            width: "100%",
                            display: "grid",
                            gridTemplateColumns: {
                              xs: "20% 80%",
                              md: "10% 90%",
                            },
                            columnGap: "2%",
                          }}
                        >
                          <FormLabel>Dùng thử:</FormLabel>
                          <Box
                            sx={{
                              marginTop: "-4px",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            {configs.trial_domain.map((x, i) => (
                              <Typography key={i} level="body-md">
                                {x}
                              </Typography>
                            ))}
                          </Box>
                        </FormControl>
                      }
                    />
                    <Radio
                      color="primary"
                      value="2"
                      label={
                        <Stack>
                          <FormControl sx={{ width: "100%", zIndex: 100 }}>
                            <FormLabel>Danh sách Domain:</FormLabel>
                            <Input
                              name="domain"
                              className="InputForm"
                              placeholder={
                                domaintype == "2"
                                  ? "https://domain.com"
                                  : "domain"
                              }
                              type="text"
                              value={currentdomain}
                              sx={{
                                width: "100%",
                                "--Input-decoratorChildHeight": "40px",
                              }}
                              onFocus={() => setPickDomain("2")}
                              onChange={(event: any) => {
                                setPickDomain("2");
                                const value = event.target.value.toLowerCase();
                                setCurrentDomain(value);
                              }}
                              endDecorator={
                                <Box
                                  sx={{
                                    width: "100%",
                                    display: "flex",
                                    gap: 2,
                                  }}
                                >
                                  <ChipDelete
                                    color="neutral"
                                    variant="plain"
                                    disabled={
                                      currentdomain.length > 0 ? false : true
                                    }
                                    onClick={() => {
                                      setCurrentDomain("");
                                      setvalidateDomain({
                                        invalid: false,
                                        errors: "",
                                      });
                                    }}
                                  >
                                    <ClearIcon />
                                  </ChipDelete>
                                  <FormControl sx={{ width: "100%" }}>
                                    <Select
                                      name="gender"
                                      size="sm"
                                      sx={{ zIndex: 100, margin: "auto" }}
                                      value={domaintype}
                                      onChange={(e: any, newValue) => {
                                        setDomainType(newValue);
                                      }}
                                    >
                                      <Option value="1"> giaphaso.com </Option>
                                      <Option value="2"> Khác</Option>
                                    </Select>
                                  </FormControl>
                                  <Button
                                    sx={{
                                      borderTopLeftRadius: 0,
                                      borderBottomLeftRadius: 0,
                                    }}
                                    onClick={onAddDomain}
                                  >
                                    Thêm
                                  </Button>
                                </Box>
                              }
                            />
                            {validateDomain.invalid ? (
                              <FormHelperText sx={{ color: "red" }}>
                                {validateDomain.errors}
                              </FormHelperText>
                            ) : (
                              <></>
                            )}
                          </FormControl>
                          {state.domain.length == 0 ? (
                            <></>
                          ) : (
                            <div
                              className="selectedTags"
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                width: "100%",
                                marginTop: "5px",
                                flexWrap: "wrap",
                                flexDirection: "column",
                              }}
                            >
                              {state.domain.map(
                                (option: any, index: number) => (
                                  <ChipRender
                                    option={option}
                                    index={index}
                                    key={index}
                                  />
                                )
                              )}
                            </div>
                          )}
                        </Stack>
                      }
                    />
                  </RadioGroup>
                </FormControl>
                {/* <FormControl sx={{ width: "100%" }}>
                  <FormLabel>Thêm Domain (https://domain.com)</FormLabel>
                  <Input
                    name="domain"
                    className="InputForm"
                    placeholder="https://domain.com"
                    type="text"
                    value={currentdomain}
                    sx={{
                      width: "100%",
                      "--Input-decoratorChildHeight": "40px",
                    }}
                    onChange={(event: any) => {
                      const value = event.target.value;
                      setCurrentDomain(value);
                    }}
                    endDecorator={
                      <Box sx={{ width : "100%", display: "flex", gap: 2}}>
                        <ChipDelete
                          color="neutral"
                          variant="plain"
                          disabled={currentdomain.length > 0 ? false : true}
                          onClick={() => {
                            setCurrentDomain("");
                            setvalidateDomain({ invalid: false, errors: "" });
                          }}
                        >
                          <ClearIcon />
                        </ChipDelete>
                        <Button
                          sx={{
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                          }}
                          onClick={onAddDomain}
                        >
                          Thêm
                        </Button>
                      </Box>
                    }
                  />
                  {validateDomain.invalid ? (
                    <FormHelperText sx={{ color: "red" }}>
                      {validateDomain.errors}
                    </FormHelperText>
                  ) : (
                    <></>
                  )}
                </FormControl>
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel>
                    Danh sách Domain:
                  </FormLabel>
                </FormControl>
                {state.domain.length == 0 ? (
                  <Typography
                    level="body-lg" 
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    <i>Chưa cài đặt domain.</i>
                  </Typography>
                ) : (
                  <div
                    className="selectedTags"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      width: "100%",
                      flexWrap: "wrap",
                    }}
                  >
                    <ChipRender arrDomain={state.domain} />
                  </div>
                )} */}
                {/* <ChipRender arrDomain={...state.domain} /> */}
                <CommonPopupRender {...initPopup} />
                <Box sx={{ display: "flex" }}>
                  <Button
                    onClick={() => {
                      navigate("/customer");
                    }}
                    variant="outlined"
                    color="primary"
                    sx={{
                      width: "150px",
                      background: "#DDF1FF",
                      marginRight: "15px",
                    }}
                  >
                    Quay lại
                  </Button>
                  <Button sx={{ width: "150px" }} type="submit">
                    Cập nhật
                  </Button>
                </Box>
              </Stack>
            </form>
          </Card>
        </div>
      </div>
      <Backdrop
        sx={{
          background: "rgba(245, 245, 245, 0.6)",
          zIndex: 10000,
          backdropFilter: "blur(5px)",
        }}
        open={loading}
        transitionDuration={{
          appear: 1,
          exit: 1,
        }}
      >
        <CircularProgress thickness={4} size="lg" variant="plain" />
      </Backdrop>
    </Box>
  );
}
